<template>
  <template v-if="uid">
    <nav class="navbar navbar-expand sticky-top navbar-light bg-light">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="planning">
          BREW // <span class="text-uppercase">{{ company.name }}</span>
        </router-link>

        <div class="navbar-nav">
          <router-link class="nav-link" to="/user_detail">User</router-link>
          <div role="button" @click="signOut()" class="nav-link">
            Log out
          </div>
        </div>
      </div>
    </nav>

    <div class="row">
      <nav class="bg-light sidebar" :class="sm ? 'narrow' : 'wide'">
        <div class="position-sticky pt-3">
          <ul class="nav flex-column">
            <SideHeader>actions</SideHeader>
            <SideLink :sm="sm" :link="'Planning'"> <BIconBarChartSteps class="d-inline"/></SideLink>
            <SideLink :sm="sm" :link="'Brewing'"> <IconMugHot class="d-inline"/></SideLink>
            <SideLink :sm="sm" :link="'Fermenting'"> <IconBacteria class="d-inline"/></SideLink>
            <SideLink :sm="sm" :link="'Tapping'"> <IconUntappd class="d-inline"/></SideLink>
            <SideLink :sm="sm" :link="'Selling'"> <IconShopping class="d-inline"/></SideLink>
            <hr />
            <SideHeader>inventory</SideHeader>
            <SideLink :sm="sm" :link="'Brews'"> <IconBeer class="d-inline"/></SideLink>
            <SideLink :sm="sm" :link="'Ingredients'"> <IconMortar class="d-inline"/></SideLink>
            <SideLink :sm="sm" :link="'Containers'"> <IconBox class="d-inline"/></SideLink>
            <hr />
            <SideHeader>setup</SideHeader>
            <SideLink :sm="sm" :link="'Recipes'"> <IconBook class="d-inline"/></SideLink>
            <SideLink :sm="sm" :link="'Styles'"> <IconVest class="d-inline"/></SideLink>
            <SideLink :sm="sm" :link="'Tanks'" text="Hardware">
              <IconFlask class="d-inline"
            /></SideLink>
            <SideLink :sm="sm" :link="'Suppliers'"> <IconAddress class="d-inline"/></SideLink>
            <SideLink :sm="sm" :link="'Buyers'"> <IconAddress class="d-inline"/></SideLink>
            <SideLink :sm="sm" :link="'Profiles'"> <IconThermometer class="d-inline"/></SideLink>
            <!-- <SideCollapse name="Full Lists" :sm="sm">
              <template #links>
              </template>
            </SideCollapse> -->
          </ul>
        </div>
      </nav>
    </div>
  </template>

  <main class="p-4" :style="sm || !uid ? 'margin-left: 50px' : 'margin-left: 155px'">
    <div>
      <slot />
    </div>
  </main>
</template>

<script>
import { signOut } from "@/services/auth"
import { mapGetters, mapState } from "vuex"
import icons from "../../assets/icons"
import SideLink from "./SideLink.vue"
import SideHeader from "./SideHeader.vue"
// import SideCollapse from "./SideCollapse.vue"
// import { mapState } from "vuex";
export default {
  name: "Nav",
  components: {
    ...icons,
    SideLink,
    SideHeader,
    // SideCollapse,
  },
  setup() {
    return { signOut }
  },
  data() {
    return {
      width: window.innerWidth,
      inventory: false,
      setup: false,
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth
    },
  },
  computed: {
    ...mapState(["company"]),
    ...mapGetters(["uid"]),
    rout() {
      return this.$route.name
    },
    sm() {
      return this.width < 576 ? true : false
    },
  },
}
</script>

<style scoped>
body {
  font-size: 0.875rem;
}

/* #content {
  margin-left: 200px;
} */

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.wide {
  width: 155px;
}

.narrow {
  width: 50px;
}

/*
 * Sidebar
 */

#sidebarMenu {
  width: 155px;
}

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 10; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1);
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1); */
}

/* @media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
} */

.sidebar-sticky {
  position: relative;
  top: 0;
  /* height: calc(100vh - 48px); */
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  /* box-shadow: 5px 0px 5px gray; */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

/* .navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25); */
/* } */

/* .navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
} */

.navbar {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}

/* .navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
} */

/* .form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
} */

/* .form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}  */
</style>
