import firebase from "firebase/app"
import "firebase/auth"
import { store } from "@/store/store"
import { subscribeSnapshot, unsubscribeSnapshot } from "./api"
import router from "../router"
import { ActionTypes } from "@/store/actionTypesEnums"

export const login = function(user: string, password: string) {
  if (!user || !password) {
    store.dispatch(ActionTypes.MessageError, "Please fill out required fields.")
  } else {
    firebase
      .app()
      .auth()
      .signInWithEmailAndPassword(user, password)
      .catch(error => store.dispatch(ActionTypes.MessageError, error.message))
      .then(() => router.push({ name: "Planning" }))
  }
}

export const signOut = () => {
  firebase
    .app()
    .auth()
    .signOut()
    .then(() => store.dispatch(ActionTypes.MessageSuccess, "You signed out successfully"))
    .catch(error => {
      store.dispatch(ActionTypes.MessageError, error.message)
    })
    .then(() => router.push({ name: "Home" }))
}

export const authListener = () => {
  return firebase
    .app()
    .auth()
    .onAuthStateChanged(function(user) {
      if (user) {
        //SET USER
        store.dispatch(ActionTypes.SetUid, user.uid)
        subscribeSnapshot()
      } else {
        //CLEAR DATA
        store.dispatch(ActionTypes.SetUid, "")
        unsubscribeSnapshot()
      }
    })
}

export const resetPassword = (email: string) => {
  firebase
    .app()
    .auth()
    .sendPasswordResetEmail(email)
    .then(() =>
      store.dispatch(ActionTypes.MessageSuccess, "Password reset link send to your email")
    )
    .catch(error => store.dispatch(ActionTypes.MessageError, error.message))
}

export const getUser = () => {
  const user = firebase.apps.length > 0 ? firebase.app().auth().currentUser : null
  return user
}

export const getUserUid = () => {
  const user = getUser()
  return user ? user.uid : ""
}

export const changePassword = (password1: string) => {
  const user = firebase.app().auth().currentUser

  if (user)
    user
      .updatePassword(password1)
      .then(() => store.dispatch(ActionTypes.MessageSuccess, "Password updated"))
      .catch(error => store.dispatch(ActionTypes.MessageError, error.message))
  else store.dispatch(ActionTypes.MessageError, "there is no user")
}
