<template>
  <ModelContainer @click.self="closeTank()">
    <ModalContent>
      <ModalHeader>
        Tank Form
        <template #right>
          <Button @click="closeTank()">close</Button>
        </template>
      </ModalHeader>
      <Row>
        <Lable class="col">Name your Tank *</Lable>
        <Lable class="col-3">Short name *</Lable>
      </Row>
      <Row>
        <BCol>
          <FormInput ref="formName" placeholder="Tank Name" v-model="tank.name" />
        </BCol>
        <BCol class="col-3">
          <FormInput
            ref="formShort"
            :validateion="validShortName"
            placeholder="XX"
            v-model="tank.short"
          />
        </BCol>
      </Row>
      <Row>
        <Lable class="col">The size of the tank *</Lable>
      </Row>
      <Row>
        <BCol class="col-4">
          <FormInput
            ref="formTankSize"
            type="number"
            placeholder="tank size"
            :validateion="validNum"
            v-model.number="tank.size"
          />
        </BCol>
        <BCol class="col-4">
          <FormSelect
            ref="formUnit"
            :strCallback="item => item"
            :items="UNIT.TEXT"
            v-model.number="tank.unit"
          />
        </BCol>
        <Button class="mx-1 col" lg="true" @click="addTank()">Save Tank</Button>
      </Row>
    </ModalContent>
  </ModelContainer>
</template>

<script>
import { initTank } from "@/services/objects"
import { apiSetTank } from "@/services/api"
import { UNIT, TANK } from "@/services/enums"
// import { getRandomColorId } from "@/services/helper";
import { mapActions } from "vuex"
import cloneDeep from "clone-deep"
import comp from "../base"
import ModalContent from "./ModalContent.vue"
import ModelContainer from "./ModalContainer.vue"
import ModalHeader from "./ModalHeader.vue"
import { checkValidity, formError, validShortName, validNum } from "../../services/validation"
import { useStore } from "@/store/store"

export default {
  name: "TankForm",
  props: ["id"],
  components: {
    ...comp,
    ModalContent,
    ModelContainer,
    ModalHeader,
  },
  setup() {
    return {
      TANK,
      UNIT,
      validShortName,
      validNum,
    }
  },
  data() {
    return {
      tank: {},
    }
  },
  created() {
    this.tank = this.id
      ? cloneDeep(useStore().state.collections.tanks[this.id])
      : cloneDeep(initTank)
  },
  computed: {
    // ...mapState(["tanks"]),
  },
  methods: {
    ...mapActions(["closeTank"]),
    addTank() {
      if (checkValidity(this.$refs)) {
        apiSetTank(this.tank)
        this.closeTank()
      } else formError()
    },
  },
}
</script>
