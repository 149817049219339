<template>
  <div class="item my-1">
    <div
      class="p-1 small"
      :style="{
        backgroundColor: ingredient.color,
      }"
    >
      {{ ingredient.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BStockInBrewItem",
  props: ["ingredient"],
}
</script>

<style scoped>
.item {
  margin-inline-end: 0.5rem;
  color: rgb(33, 37, 41);
  text-decoration: none;
  /* border: 1px solid white; */
}
</style>
