<template>
  <Container class="my-5">
    <div class="large-text">
      Wellcome to Brew
    </div>
    <div class="lead mb-3">
      <template v-if="company">
        You are about to sign in to <strong>{{ companyName }}.</strong>
      </template>
      <template v-else>
        This company is not regustred. Check the url or cantackt us.
      </template>
    </div>

    <template v-if="company">
      <div v-if="!forgot">
        <Row>
          <BLable class="col-5" lable="Email *">
            <FormInput
              type="email"
              placeholder="email@email.com"
              :valid="valid.email"
              v-model="email"
            />
          </BLable>
          <BLable class="col" lable="Password *">
            <Row>
              <FormInput class="col" type="password" :valid="valid.password" v-model="password" />
              <Button class="mx-1 col-4" lg="true" @click="login()">Login</Button>
            </Row>
          </BLable>
        </Row>
        <div class="my-4">
          <p role="button" @click="forgot = true">Forgot your password?</p>
        </div>
      </div>

      <div v-else>
        <Row>
          <BLable class="col" lable="Email *">
            <Row>
              <FormInput
                class="col"
                type="email"
                placeholder="email@email.com"
                :valid="valid.email"
                v-model="email"
              />
              <Button class="mx-1 col-4" lg="true" @click="reset()">
                Reset Password
              </Button>
            </Row>
          </BLable>
        </Row>
        <div class="my-4">
          <p role="button" @click="forgot = false">Back to Sign in?</p>
        </div>
      </div>
    </template>
  </Container>
</template>

<script>
import { login, resetPassword } from "../services/auth"
import comp from "@/components/base"
import { mapActions } from "vuex"
import { authListener } from "../services/auth"
import { companies } from "../consts/databases"
import firebase from "firebase/app"

export default {
  name: "Login",
  components: {
    ...comp,
  },
  data() {
    return {
      valid: {},
      email: null,
      password: null,
      forgot: false,
      query: this.$route.query.id,
    }
  },
  computed: {
    company() {
      return Object.keys(companies).includes(this.query) ? companies[this.query] : false
    },
    companyName() {
      return this.company ? this.company.name : ""
    },
    firebaseApp() {
      return firebase.apps.length > 0
    },
    user() {
      if (this.firebaseApp) return firebase.auth().currentUser
      return false
    },
  },

  created() {
    if (this.company && !this.firebaseApp) {
      this.setCompany(companies[this.query])
      firebase.initializeApp(companies[this.query].config)
      authListener()
    }
    if (this.user) this.$router.push({ name: "Planning" })
  },
  methods: {
    ...mapActions(["setCompany"]),
    login() {
      login(this.email, this.password)
    },
    reset() {
      resetPassword(this.email)
      this.forgot = false
    },
  },
}
</script>
