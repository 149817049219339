<template>
  <div class="col p-3" style="background-color: rgba(200, 75, 75, 0.15)">
    <div class="d-flex justify-content-between align-items-end">
      <div class="h5 mb-0">{{ name }}s</div>
      <slot name="right" />
    </div>
    <hr class="line my-2" />
    <div class="my-2">
      <slot />
    </div>
    <!-- <Button @click="$emit('update:modelValue', !modelValue)">
      {{ modelValue ? "Hide" : "Show" }}
      inventory
      <Badge bg="blue">
        {{ Object.values(obj).length }}
      </Badge>
    </Button> -->
  </div>
</template>

<script>
export default {
  name: "MinuCard",
  props: ["name"],
}
</script>

<style></style>
