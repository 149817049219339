import { store } from "../../store/store"
import firebase from "firebase/app"
import { v4 as uuid } from "uuid"
import { cloneDeep } from "lodash"
import { apiSetRecipe, apiSetBuyer } from "../api"
import { getUserUid } from "../auth"
import { ActionTypes } from "@/store/actionTypesEnums"
import { Base, Brew, Container, Ingredient, Order, Recipe } from "@/typings/types"
// import firebase from "firebase/app"

type Collection = "brews" | "containers" | "ingredients" | "orders" | "recipes"
// type  = "brews" | "containers" | "ingredients" | "orders" | "recipes"

const getRefIds = (item: any, collection: Collection, field: string, id: string) => {
  const getIds = (localItem: any) => {
    return localItem[field].filter((obj: any) => obj[id]).map((obj: any) => obj[id])
  }

  const allNewContainerIds = getIds(item)
  const allOldContainerIds = store.state.collections[collection][item.id as string]
    ? getIds(store.state.collections[collection][item.id])
    : []

  const idsToAdd = allNewContainerIds.filter((item: any) => !allOldContainerIds.includes(item))
  const idsToRemove = allOldContainerIds.filter((item: any) => !allNewContainerIds.includes(item))

  return [idsToAdd, idsToRemove]
}

const init = (item: Base) => {
  if (!item.id) {
    item.id = uuid()
    item.created = firebase.firestore.Timestamp.fromDate(new Date())
    item.userCreated = getUserUid()
  } else {
    item.updated = firebase.firestore.Timestamp.fromDate(new Date())
    item.userUpdated = getUserUid()
  }
  return item
}

// ADD REF
const addRef = function(collection: Collection, ids: string[], refId: string, field: string) {
  const db = firebase.firestore()
  ids.forEach(id => {
    db.collection(collection)
      .doc(id)
      .set({ [field]: firebase.firestore.FieldValue.arrayUnion(refId) }, { merge: true })
      .catch(error => {
        store.dispatch(ActionTypes.MessageSetError, error.message)
      })
  })
}

//REMOVE REF
const removeRef = function(collection: Collection, ids: string[], refId: string, field: string) {
  const db = firebase.firestore()
  ids.forEach(id => {
    db.collection(collection)
      .doc(id)
      .update({ [field]: firebase.firestore.FieldValue.arrayRemove(refId) })
      .then(() => store.dispatch(ActionTypes.MessageSetSuccess, collection + " updated"))
      .catch(error => {
        store.dispatch(ActionTypes.MessageSetError, error.message)
      })
  })
}

const ingredientBeforeSave = (item: Ingredient) => {
  // let sum = item.inventory.reduce((acc, item) => (acc += item.quantity), 0)
  // item["total"] = sum
  return item
}

const incrementBrew = (item: Brew) => {
  if (!item.id) {
    const recipe = cloneDeep(store.state.collections.recipes[item.recipeCopy.id])
    recipe.increment++
    item.count = recipe.increment
    apiSetRecipe(recipe)
  }
  return item
}

const brewBeforeSave = (item: Brew) => {
  item = incrementBrew(item)
  item = init(item) as Brew

  //SET INGREDIENT REF
  const getIngredientIds = (localItem: Brew) => {
    return [
      ...localItem.recipeCopy.mashing.items,
      ...localItem.recipeCopy.kettle.items,
      ...localItem.recipeCopy.fermentation.items,
    ]
      .filter(ingredient => ingredient.id)
      .map(ingredient => ingredient.id)
  }
  const ingredientIds = getIngredientIds(item)
  const storeIngredientIds = store.state.collections.brews[item.id]
    ? getIngredientIds(store.state.collections.brews[item.id])
    : []

  const ingredientToAddIds = ingredientIds.filter(item => !storeIngredientIds.includes(item))
  const ingredientToRemoveIds = storeIngredientIds.filter(item => !ingredientIds.includes(item))

  // addRef("ingredients", ingredientToAddIds, item.id, "brewIds")
  // removeRef("ingredients", ingredientToRemoveIds, item.id, "brewIds")

  //SET CONTAINER REF
  const [containerToAddIds, containerToRemoveIds] = getRefIds(item, "brews", "containers", "id")

  // addRef("containers", idsToAdd, item.id, "brewIds")
  // removeRef("containers", idsToRemove, item.id, "brewIds")

  return {
    item,
    ingredientToAddIds,
    ingredientToRemoveIds,
    containerToAddIds,
    containerToRemoveIds,
  }
}

const incrementOrder = (item: Order) => {
  if (!item.id) {
    const buyer = cloneDeep(store.state.collections.buyers[item.buyerId])
    buyer.increment++
    item.count = buyer.increment
    apiSetBuyer(buyer)
  }
  return item
}

const orderBeforeSave = (item: Order) => {
  item = incrementOrder(item)

  const [idsToAdd, idsToRemove] = getRefIds(item, "orders", "inventory", "brewId")

  addRef("brews", idsToAdd, item.id, "orderIds")
  removeRef("brews", idsToRemove, item.id, "orderIds")

  return item
}

const containerBeforeSave = (item: Container) => {
  return item
}

const recipeBeforeSave = (item: Recipe) => {
  const sum = item.kettle.items.reduce(
    (acc, item) => (item.time > acc ? (acc = item.time) : acc),
    0
  )
  item.kettle.totalTime = sum
  return item
}

export {
  ingredientBeforeSave,
  recipeBeforeSave,
  containerBeforeSave,
  brewBeforeSave,
  orderBeforeSave,
  addRef,
  removeRef,
  // increment,
}
