<template>
  <router-link
    class="nav-link"
    :class="{ active: rout == link }"
    :to="{ name: link, params: { id: param } }"
  >
    <slot />
    <div class="d-inline ms-2" v-if="!sm">{{ text ? text : link }}</div>
  </router-link>
</template>

<script>
export default {
  props: ["sm", "link", "param", "text"],
  name: "SiteLink",
  computed: {
    rout() {
      return this.$route.name
    },
  },
}
</script>

<style></style>
