<template>
  <div class="toast align-items-center shadow-sm border-1 border-success show">
    <div class="d-flex">
      <div class="toast-body p-2 d-flex align-items-center">
        <div class="text-success">
          <BIconHandThumbsUp />
        </div>
        <small class="ms-3 fw-bold">
          <template v-if="setSuccess"><IconUp class="text-success" />{{ setSuccess }}</template>
          <template v-if="getSuccess"><IconDown class="text-success" />{{ getSuccess }}</template>
        </small>
        <template v-if="updateUser">
          <span class="ms-2"> | </span>
          <small class="ms-2 text-muted">
            {{ updateUser }}
          </small>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import { defineComponent } from "@vue/runtime-core"
import { mapGetters } from "vuex"
import icons from "../../assets/icons"
import { MESSAGE } from "../../services/enums"

export default {
  name: "Message",
  props: { messages: Array },
  components: {
    ...icons,
  },
  computed: {
    ...mapGetters(["updateUser"]),
    setSuccess() {
      let setSuccess = this.messages.filter(message => message.type === MESSAGE.SET_SUCCESS)
      return setSuccess.length > 0 ? setSuccess.length : 0
    },
    getSuccess() {
      let getSuccess = this.messages.filter(message => message.type === MESSAGE.GET_SUCCESS)
      return getSuccess.length > 0 ? getSuccess.length : null
    },
  },
}
</script>
