<template>
  <Container class="my-5">
    <div class="large-text">
      Wellcome to Brew
    </div>
    <div class="lead mb-3">This is the frontpage for non users<br /></div>
    <p>
      to log in to Flying go to
      <a href="flying">øllog.dk/flying</a> or <a href="login?id=show">login?id=show</a>
      <!-- <ButtonLink name="Login" :query="{ id: 'flying' }"> Login To flying</ButtonLink> -->
    </p>
    <p>
      to log in to show go to
      <a href="show">øllog.dk/show</a>
      <!-- <ButtonLink name="Login" :query="{ id: 'show' }"> Login To Show</ButtonLink> -->
    </p>
  </Container>
</template>

<script>
import comp from "../components/base"

export default {
  name: "Login",
  components: {
    ...comp,
  },
}
</script>
