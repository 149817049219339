<template>
  <div class="progress" :style="{ height: hight + 'px' }">
    <div class="progress-bar" role="progressbar" :style="{ width: process + '%' }"></div>
  </div>
</template>

<script>
export default {
  Name: "ProgressBarTime",
  props: ["start", "end", "hight"],
  computed: {
    process() {
      let now = new Date()
      let process = (now - this.start.valueOf()) / (this.end.valueOf() - this.start.valueOf())
      if (process < 0) process = 0
      if (process > 1) process = 1
      return Math.round(process * 100)
    },
  },
}
</script>

<style></style>
