import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, RootState } from "../store"

export interface ValidationState {
  valid: boolean[]
}

const state: ValidationState = {
  valid: [],
}

export type ValidationMutations = {
  [MutationType.PushValid](state: ValidationState, payload: boolean): void
  [MutationType.ClearValid](state: ValidationState): void
}

const mutations: MutationTree<ValidationState> & ValidationMutations = {
  [MutationType.PushValid](state, payload) {
    state.valid.push(payload)
  },
  [MutationType.ClearValid](state) {
    state.valid = []
  },
}

export type ValidationActions = {
  [ActionTypes.PushValid](context: ActionAugments, payload: boolean): void
  [ActionTypes.ClearValid](context: ActionAugments): void
}

const actions: ActionTree<RootState, RootState> & ValidationActions = {
  [ActionTypes.PushValid]({ commit }, payload) {
    commit(MutationType.PushValid, payload)
  },
  [ActionTypes.ClearValid]({ commit }) {
    commit(MutationType.ClearValid)
  },
}

export type ValidationGetters = {
  validForm(state: ValidationState): boolean
}

const getters: GetterTree<ValidationState, RootState> & ValidationGetters = {
  validForm: state => {
    return state.valid.reduce((acc, item) => (item ? acc : (acc = false)), true)
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
