<template>
  <input
    class="form-control"
    :class="{
      'is-invalid': !valid && touched,
      'is-valid': valid && touched,
    }"
    type="date"
    :value="strDate"
    @input="val => sendDate(val.target.value)"
  />
</template>

<script>
import { format } from "date-fns"
import { validDate } from "../../services/validation"
import { store } from "../../store/store"
import { handleDate } from "@/helper/helper"
export default {
  name: "FormInputDateLable",
  props: {
    modelValue: [Object, Array, String, Number],
    validateion: {
      type: Function,
      default: validDate,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    strDate() {
      return this.modelValue && !isNaN(this.modelValue)
        ? format(handleDate(this.modelValue), "yyyy-MM-dd")
        : ""
    },
    valid() {
      return this.validateion(this.modelValue)
    },
  },
  data() {
    return {
      touched: false,
    }
  },
  methods: {
    sendDate(val) {
      this.touched = true
      this.$emit("update:modelValue", new Date(val))
    },
    validate() {
      this.touched = true
      store.dispatch("pushValid", this.validateion(this.modelValue))
    },
    unTouched() {
      this.touched = false
    },
  },
}
</script>
