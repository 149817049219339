<template>
  <canvas :id="chartId" height="300px"></canvas>
</template>

<script>
import comp from "../base"
import Chart from "chart.js/auto"
import { chartDataProfile, chartOptionsProfile } from "../../services/chart"
import cloneDeep from "clone-deep"

export default {
  name: "ProfileChart",
  props: ["items", "chartId", "totalTime"],
  components: {
    ...comp,
  },
  setup() {
    let chart
    return {
      chart,
    }
  },
  mounted() {
    this.chart = new Chart(document.querySelector("#" + this.chartId), {
      type: "scatter",
      data: cloneDeep(chartDataProfile),
      options: cloneDeep(chartOptionsProfile),
    })
    this.setChart()
  },
  unmounted() {
    if (this.$options.chart) this.$options.chart.destroy()
  },
  watch: {
    items() {
      this.setChart()
    },
  },
  computed: {},
  methods: {
    setChart() {
      let sum = 0
      let lastSum = 0
      let local = []

      this.items.forEach(item => {
        sum += item.time
        local.push({
          ...item,
          running: lastSum,
        })
        local.push({
          ...item,
          running: sum,
        })
        lastSum = sum
      })

      this.chart.data.datasets[0].data = local.map(item => ({
        x: item.running,
        y: item.temperature,
      }))

      this.chart.options.scales.x.max = this.totalTime ? this.totalTime : null

      this.chart.update()
    },
  },
}
</script>
