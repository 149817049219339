<template>
  <div class="me-2 p-2 b-tabs fw-bold" role="button" :class="{ active: active, inactiv: !active }">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: ["active", "number"],
}
</script>

<style scoped>
.active {
  background-color: rgba(200, 75, 75, 0.15);
}
.inactiv {
  background-color: rgba(200, 75, 75, 0.05);
}
</style>
