export enum MutationType {
  //LOAD STATE
  SetBrewsLoadState = "SET_BREW_API_STATE",
  SetBreweriesLoadState = "SET_BREWERY_API_STATE",
  SetOrdersLoadState = "SET_ORDER_API_STATE",
  SetRecipesLoadState = "SET_RECIPE_API_STATE",
  SetTanksLoadState = "SET_TANK_API_STATE",
  SetSuppliersLoadState = "SET_SUPPLIER_API_STATE",
  SetStylesLoadState = "SET_STYLE_API_STATE",
  SetBuyersLoadState = "SET_BUYER_API_STATE",
  SetProfilesLoadState = "SET_PROFILE_API_STATE",
  SetIngredientsLoadState = "SET_INGREDIENT_API_STATE",
  SetContainersLoadState = "SET_CONTAINER_API_STATE",
  SetUsersLoadState = "SET_USER_API_STATE",

  //COLLECTIONS
  SetBreweries = "SET_BREWERY",
  SetOrders = "SET_ORDER",
  SetBuyers = "SET_BUYER",
  SetRecipes = "SET_RECIPE",
  SetBrews = "SET_BREW",
  SetTanks = "SET_TANK",
  SetSuppliers = "SET_SUPPLIER",
  SetStyles = "SET_STYLE",
  SetProfiles = "SET_PROFILE",
  SetIngredients = "SET_INGREDIENT",
  SetContainers = "SET_CONTAINER",

  //COMPANY
  SetCompany = "SET_COMPANY",

  //USER
  SetUid = "SET_UID",

  //DELETE MODAL
  SetDeleteId = "SET_DELETE_ID",
  SetDeleteType = "SET_DELETE_TYPE",

  //LOCAL MODALS
  SetFermentable = "SET_FERMENTABLE",
  SetFermentableIndex = "SET_FERMENTABLE_INDEX",
  SetKettle = "SET_KETTLE",
  SetKettleIndex = "SET_KETTLE_INDEX",
  SetFermentation = "SET_FERMENTATION",
  SetFermentationIndex = "SET_FERMENTATION_INDEX",
  SetSelling = "SET_SELLING",
  SetSellingIndex = "SET_SELLING_INDEX",
  SetTap = "SET_TAP",
  SetTapContainerId = "SET_TAP_CONTAINER_ID",

  //MESSAGE
  PushMessage = "PUSH_MESSAGE",
  DeleteMessage = "DELETE_MESSAGE",

  //MODALS
  SetModalStyle = "SET_MODAL_STYLE",
  SetModalStyleId = "SET_MODAL_STYLE_ID",
  SetModalTank = "SET_MODAL_TANK",
  SetModalTankId = "SET_MODAL_TANK_ID",
  SetModalSupplier = "SET_MODAL_SUPPLIER",
  SetModalSupplierId = "SET_MODAL_SUPPLIER_ID",
  SetModalRecipe = "SET_MODAL_RECIPE",
  SetModalRecipeId = "SET_MODAL_RECIPE_ID",
  SetModalBrew = "SET_MODAL_BREW",
  SetModalBrewId = "SET_MODAL_BREW_ID",
  SetModalBuyer = "SET_MODAL_BUYER",
  SetModalBuyerId = "SET_MODAL_BUYER_ID",
  SetModalOrder = "SET_MODAL_ORDER",
  SetModalOrderId = "SET_MODAL_ORDER_ID",
  SetModalProfile = "SET_MODAL_PROFILE",
  SetModalProfileId = "SET_MODAL_PROFILE_ID",
  SetModalBrewery = "SET_MODAL_BREWERY",
  SetModalBreweryId = "SET_MODAL_BREWERY_ID",
  SetModalIngredient = "SET_MODAL_INGREDIENT",
  SetModalIngredientId = "SET_MODAL_INGREDIENT_ID",
  SetModalIngredientInventory = "SET_MODAL_INGREDIENT_INVENTORY",
  SetModalIngredientInventoryId = "SET_MODAL_INGREDIENT_INVENTORY_ID",
  SetModalContainer = "SET_MODAL_CONTAINER",
  SetModalContainerId = "SET_MODAL_CONTAINER_ID",
  SetModalContainerInventory = "SET_MODAL_CONTAINER_INVENTORY",
  SetModalContainerInventoryId = "SET_MODAL_CONTAINER_INVENTORY_ID",
  SetModalUser = "SET_MODAL_USER",

  //UPDATE TRACKER
  SetUpdatedByUid = "SET_UPDATED_BY_UID",
  SetUpdatedItemId = "SET_ITEM_ID",

  //USERS
  SetUsers = "SET_USERS",

  //VALIDATION
  PushValid = "PUSH_VALID",
  ClearValid = "CLEAR_VALID",
}
