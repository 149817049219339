<template>
  <ul class="list-group list-group-flush">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: "UnorderedList",
}
</script>
