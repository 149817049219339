import { ActionContext, ActionTree, MutationTree } from "vuex"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, RootState } from "../store"
import { CompanyState } from "./company"

export interface DeleteModalState {
  type: string
  id: string
}

const state: DeleteModalState = {
  type: "",
  id: "",
}

export type DeleteModalMutations = {
  [MutationType.SetDeleteId](state: DeleteModalState, payload: string): void
  [MutationType.SetDeleteType](state: DeleteModalState, payload: string): void
}

const mutations: MutationTree<DeleteModalState> & DeleteModalMutations = {
  [MutationType.SetDeleteId](state, payload) {
    state.id = payload
  },
  [MutationType.SetDeleteType](state, payload) {
    state.type = payload
  },
}

export type DeleteModalActions = {
  [ActionTypes.OpenDeleteBrewery](context: ActionAugments, payload: string): void
  [ActionTypes.OpenDeleteBrew](context: ActionAugments, payload: string): void
  [ActionTypes.OpenDeleteOrder](context: ActionAugments, payload: string): void
  [ActionTypes.OpenDeleteBuyer](context: ActionAugments, payload: string): void
  [ActionTypes.OpenDeleteRecipe](context: ActionAugments, payload: string): void
  [ActionTypes.OpenDeleteStyle](context: ActionAugments, payload: string): void
  [ActionTypes.OpenDeleteSupplier](context: ActionAugments, payload: string): void
  [ActionTypes.OpenDeleteTank](context: ActionAugments, payload: string): void
  [ActionTypes.OpenDeleteProfile](context: ActionAugments, payload: string): void
  [ActionTypes.OpenDeleteIngredient](context: ActionAugments, payload: string): void
  [ActionTypes.OpenDeleteContainer](context: ActionAugments, payload: string): void
  [ActionTypes.CloseDelete](context: ActionAugments, payload: string): void
}

export type TestDeleteModalActions = typeof actions

const actions: ActionTree<RootState, RootState> & DeleteModalActions = {
  [ActionTypes.OpenDeleteBrewery]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "brewery")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.OpenDeleteBrew]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "brew")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.OpenDeleteOrder]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "order")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.OpenDeleteBuyer]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "buyer")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.OpenDeleteRecipe]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "recipe")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.OpenDeleteStyle]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "style")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.OpenDeleteSupplier]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "supplier")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.OpenDeleteTank]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "tank")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.OpenDeleteProfile]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "profile")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.OpenDeleteIngredient]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "ingredient")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.OpenDeleteContainer]({ commit }, payload) {
    commit(MutationType.SetDeleteType, "container")
    commit(MutationType.SetDeleteId, payload)
  },
  [ActionTypes.CloseDelete]({ commit }) {
    commit(MutationType.SetDeleteType, "")
    commit(MutationType.SetDeleteId, "")
  },
}

const getters = {}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
