import { ActionContext, ActionTree, GetterTree, MutationTree } from "vuex"
import { API } from "../../services/enums"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, RootState } from "../store"

export enum LoadState {
  INIT,
  LOADING,
  ERROR,
  LOADED,
}

export interface LoadStateState {
  apiBrewState: LoadState
  apiTankState: LoadState
  apiSupplierState: LoadState
  apiStyleState: LoadState
  apiRecipeState: LoadState
  apiBuyerState: LoadState
  apiOrderState: LoadState
  apiProfileState: LoadState
  apiIngredientState: LoadState
  apiContainerState: LoadState
  apiBreweryState: LoadState
  apiUserState: LoadState
}

const state: LoadStateState = {
  apiBrewState: LoadState.INIT,
  apiTankState: LoadState.INIT,
  apiSupplierState: LoadState.INIT,
  apiStyleState: LoadState.INIT,
  apiRecipeState: LoadState.INIT,
  apiBuyerState: LoadState.INIT,
  apiOrderState: LoadState.INIT,
  apiProfileState: LoadState.INIT,
  apiIngredientState: LoadState.INIT,
  apiContainerState: LoadState.INIT,
  apiBreweryState: LoadState.INIT,
  apiUserState: LoadState.INIT,
}

export type LoadStateMutations = {
  [MutationType.SetBrewsLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetBreweriesLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetBuyersLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetContainersLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetIngredientsLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetOrdersLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetProfilesLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetRecipesLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetStylesLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetSuppliersLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetUsersLoadState](state: LoadStateState, payload: LoadState): void
  [MutationType.SetTanksLoadState](state: LoadStateState, payload: LoadState): void
}

const mutations: MutationTree<LoadStateState> & LoadStateMutations = {
  [MutationType.SetBrewsLoadState](state, payload) {
    state.apiBrewState = payload
  },

  [MutationType.SetOrdersLoadState](state, payload) {
    state.apiOrderState = payload
  },

  [MutationType.SetBreweriesLoadState](state, payload) {
    state.apiBreweryState = payload
  },

  [MutationType.SetRecipesLoadState](state, payload) {
    state.apiRecipeState = payload
  },

  [MutationType.SetTanksLoadState](state, payload) {
    state.apiTankState = payload
  },

  [MutationType.SetSuppliersLoadState](state, payload) {
    state.apiSupplierState = payload
  },

  [MutationType.SetStylesLoadState](state, payload) {
    state.apiStyleState = payload
  },

  [MutationType.SetBuyersLoadState](state, payload) {
    state.apiBuyerState = payload
  },

  [MutationType.SetProfilesLoadState](state, payload) {
    state.apiProfileState = payload
  },

  [MutationType.SetIngredientsLoadState](state, payload) {
    state.apiIngredientState = payload
  },

  [MutationType.SetContainersLoadState](state, payload) {
    state.apiContainerState = payload
  },

  [MutationType.SetUsersLoadState](state, payload) {
    state.apiUserState = payload
  },
}

export type LoadStateActions = {
  [ActionTypes.ResetLoad](context: ActionAugments): void
}

const actions: ActionTree<RootState, RootState> & LoadStateActions = {
  [ActionTypes.ResetLoad]({ commit }) {
    commit(MutationType.SetBrewsLoadState, LoadState.INIT)
    commit(MutationType.SetOrdersLoadState, LoadState.INIT)
    commit(MutationType.SetBuyersLoadState, LoadState.INIT)
    commit(MutationType.SetRecipesLoadState, LoadState.INIT)
    commit(MutationType.SetTanksLoadState, LoadState.INIT)
    commit(MutationType.SetSuppliersLoadState, LoadState.INIT)
    commit(MutationType.SetStylesLoadState, LoadState.INIT)
    commit(MutationType.SetBreweriesLoadState, LoadState.INIT)
    commit(MutationType.SetProfilesLoadState, LoadState.INIT)
    commit(MutationType.SetIngredientsLoadState, LoadState.INIT)
    commit(MutationType.SetContainersLoadState, LoadState.INIT)
    commit(MutationType.SetUsersLoadState, LoadState.INIT)
  },
}

export type LoadStateGetters = {
  loaded(state: LoadStateState): boolean
}

const getters: GetterTree<LoadStateState, RootState> & LoadStateGetters = {
  loaded: state => {
    const status =
      state.apiBrewState === API.LOADED &&
      state.apiTankState === API.LOADED &&
      state.apiSupplierState === API.LOADED &&
      state.apiStyleState === API.LOADED &&
      state.apiRecipeState === API.LOADED &&
      state.apiBuyerState === API.LOADED &&
      state.apiOrderState === API.LOADED &&
      state.apiProfileState === API.LOADED &&
      state.apiIngredientState === API.LOADED &&
      state.apiContainerState === API.LOADED &&
      state.apiBreweryState === API.LOADED &&
      state.apiUserState === API.LOADED
    return status
  },
}

export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters,
}
