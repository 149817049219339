<template>
  <ModalBase :close="closeOrder">
    <template #header>Order Form </template>

    <Row>
      <BCol class="col-5">
        <Lable
          >Select Buyer *
          <template #right>
            <BadgeBtn @click="openBuyer()"> + Buyer</BadgeBtn>
          </template>
        </Lable>
        <FormSelect
          ref="formCategory"
          :strCallback="item => item.name"
          :items="buyers"
          v-model="order.buyerId"
          :valueCallback="item => item.id"
        />
      </BCol>
      <BCol>
        <Lable> Target Shipping Date *</Lable>
        <FormDate ref="formDate" v-model="order.shipping" />
      </BCol>
      <BCol>
        <Lable>State * </Lable>
        <FormSelect
          ref="formState"
          :strCallback="item => item"
          :items="ORDER.TEXT"
          v-model="order.state"
        />
      </BCol>
    </Row>

    <Row class="mt-4">
      <Button lg="true" @click="save()">Save Order</Button>
    </Row>
  </ModalBase>
</template>

<script>
import { UNIT, ORDER } from "@/services/enums"
import { mapActions } from "vuex"
import { apiSetOrder } from "../../services/api"
import comp from "@/components/base"
import { initOrder } from "../../services/objects"
import cloneDeep from "clone-deep"
import ModalBase from "./ModalBase.vue"
import { checkValidity, formError } from "../../services/validation"
import { computed } from "@vue/runtime-core"
import { useStore } from "@/store/store"

export default {
  name: "OrderForm",
  props: ["id"],
  components: {
    ModalBase,
    ...comp,
  },
  setup() {
    return {
      UNIT,
      ORDER,
      buyers: computed(() => useStore().getters.buyers),
    }
  },
  data() {
    return {
      order: {
        buyerId: null,
        shipping: null,
        state: null,
      },
    }
  },
  created() {
    this.order = this.id
      ? cloneDeep(useStore().state.collections.orders[this.id])
      : cloneDeep(initOrder)
  },
  // computed: {
  //   ...mapState(["buyers", "orders"]),
  // },
  methods: {
    ...mapActions(["closeOrder", "openBuyer"]),

    save() {
      if (checkValidity(this.$refs)) {
        apiSetOrder(this.order)
        this.closeOrder()
      } else formError()
    },
  },
}
</script>
