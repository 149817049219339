<template>
  <span class="input-group-text">
    <slot />
  </span>
</template>

<script>
export default {
  name: "FormSpan",
}
</script>
