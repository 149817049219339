<template>
  <div class="">
    <div class="h5">
      <slot name="header"></slot>
    </div>
    <hr class="line my-1" />
    <div
      class="h6 d-flex 
        justify-content-between 
        align-items-center"
    >
      <div>
        <slot name="subheader"></slot>
      </div>
      <div class="text-thin">
        <slot name="subheaderright"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BHeader",
}
</script>
