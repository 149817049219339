<template>
  <div class="text-muted text-uppercase ms-3">
    <slot />
  </div>
</template>

<script>
export default {
  name: "SideHeader",
}
</script>
