<template>
  <div class="row">
    <slot />
  </div>
</template>

<script>
export default {
  name: "RowTop",
}
</script>

<style></style>
