<template>
  <div class="input-group input-group-sm">
    <input
      class="form-control"
      :class="{
        'is-invalid': valid === false && enable && touched,
        'is-valid': valid === true && enable && touched,
      }"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      @input="val => emit(val.target.value)"
      :disabled="!enable"
    />
    <span v-if="span" class="input-group-text"> {{ span }} </span>
    <button
      @click="togge()"
      class="btn "
      :class="enable ? 'btn-outline-secondary' : 'btn-secondary'"
      type="button"
    >
      <!-- @input="$emit('update:modelValue', $event.target.value)" -->
      <IconSave v-if="enable" />
      <IconEdit v-else />
      <!-- {{ enable ? "lock" : "set" }} -->
    </button>
  </div>
</template>

<script>
import icon from "../../assets/icons"
import { validStr } from "../../services/validation"
import { store } from "../../store/store"
export default {
  name: "FormInputEable",
  props: {
    modelValue: [Object, Array, String, Number],
    type: String,
    placeholder: String,
    span: String,
    validateion: {
      type: Function,
      default: validStr,
    },
  },
  emits: ["update:modelValue", "lock"],
  components: {
    ...icon,
  },
  data() {
    return {
      enable: false,
      touched: false,
    }
  },
  computed: {
    valid() {
      return this.validateion(this.modelValue)
    },
  },
  methods: {
    emit(value) {
      this.touched = true
      this.$emit("update:modelValue", value)
    },
    togge() {
      if (this.valid != false) {
        this.enable = !this.enable
        if (!this.enable) this.$emit("lock")
      }
    },
    validate() {
      this.touched = true
      store.dispatch("pushValid", this.validateion(this.modelValue))
    },
    unTouched() {
      this.touched = false
    },
  },
  mounted() {
    this.enable = this.modelValue ? false : true
  },
}
</script>
