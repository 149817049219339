<template>
  <ModalBase :close="closeContainer">
    <template #header>Container Form</template>

    <Row>
      <Lable class="col">Container name / type *</Lable>
      <Lable class="col">Size *</Lable>
      <Lable class="col">Unit *</Lable>
    </Row>
    <Row>
      <BCol>
        <FormInput ref="formName" placeholder="type" v-model="container.name" />
      </BCol>
      <BCol class="col">
        <FormGroup>
          <FormInput
            ref="formSize"
            :validateion="validNum"
            type="number"
            placeholder="size"
            v-model.number="container.size"
          />
          <FormSpan>{{ UNIT.TEXT[container.unit] }}</FormSpan>
        </FormGroup>
      </BCol>
      <BCol class="col-4">
        <FormSelect
          ref="formUnit"
          :strCallback="item => item"
          :items="UNIT.TEXT"
          v-model="container.unit"
        />
      </BCol>
    </Row>
    <Row>
      <Lable class="col">
        Select Supplier *
        <template #right>
          <BadgeBtn @click="openSupplier()"> + Supplier</BadgeBtn>
        </template>
      </Lable>
      <Lable class="col-8"> Status * </Lable>
    </Row>
    <Row>
      <BCol class="col-4">
        <FormSelect
          ref="formSupplier"
          class="col-4"
          :items="suppliers"
          :valueCallback="item => item.id"
          :strCallback="item => item.name"
          v-model="container.supplierId"
        />
      </BCol>
      <BCol class="col-4">
        <FormSelect
          ref="formState"
          :strCallback="item => item"
          :items="CONTAINER.TEXT"
          v-model.number="container.state"
        />
      </BCol>
      <Button class="mx-1 col" lg="true" @click="save()"> save </Button>
    </Row>
  </ModalBase>
</template>

<script>
import { initContainer } from "../../services/objects"
import { CONTAINER, UNIT } from "../../services/enums"
import { mapActions, mapGetters } from "vuex"
import ModalBase from "./ModalBase.vue"
import cloneDeep from "clone-deep"
import comp from "../base"
import { checkValidity, formError, validNum } from "../../services/validation"
import { reactive } from "vue"
import { apiSetContainer } from "../../services/api"
import { useStore } from "@/store/store"

export default {
  name: "ContainerForm",
  props: ["id"],
  components: {
    ...comp,
    ModalBase,
  },
  setup(props) {
    return {
      validNum,
      container: props.id
        ? reactive(cloneDeep(useStore().state.collections.containers[props.id]))
        : reactive(cloneDeep(initContainer)),
      CONTAINER,
      UNIT,
    }
  },

  computed: {
    ...mapGetters(["suppliers"]),
  },
  methods: {
    ...mapActions(["closeContainer", "openSupplier"]),
    save() {
      if (checkValidity(this.$refs)) {
        apiSetContainer(this.container)
        this.closeContainer()
      } else formError()
    },
  },
}
</script>
