<template>
  <div>
    <label class="form-label mt-3 px-0">
      {{ lable }}
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Lable",
  props: ["lable"],
}
</script>

<style></style>
