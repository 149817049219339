<template>
  <ModelContainer @click.self="closeBuyer()">
    <ModalContent>
      <ModalHeader>
        Buyer Form
        <template #right>
          <Button @click="closeBuyer()">close</Button>
        </template>
      </ModalHeader>
      <Row>
        <Lable>Buyer Name *</Lable>
        <Lable>Phone</Lable>
      </Row>
      <Row>
        <BCol>
          <FormInput ref="formName" placeholder="Et Navn" v-model="buyer.name" />
        </BCol>
        <BCol>
          <FormInput placeholder="phone" v-model="buyer.phone" />
        </BCol>
      </Row>
      <Row>
        <Lable>Address</Lable>
        <Lable>City</Lable>
      </Row>
      <Row>
        <BCol>
          <FormInput placeholder="Street + number" v-model="buyer.address1" />
        </BCol>
        <BCol>
          <FormInput placeholder="City" v-model="buyer.city" />
        </BCol>
      </Row>
      <Row>
        <Lable class="col-4">Zip code</Lable>
        <Lable class="col-4">Country</Lable>
      </Row>
      <Row>
        <BCol class="col-4">
          <FormInput placeholder="Zip-code" v-model="buyer.zip" />
        </BCol>
        <BCol class="col-4">
          <FormInput placeholder="Country" v-model="buyer.country" />
        </BCol>
        <Button class="mx-1 col" lg="true" @click="add()">Save Buyer</Button>
      </Row>
    </ModalContent>
  </ModelContainer>
</template>

<script>
import comp from "@/components/base"
import { apiSetBuyer } from "@/services/api"
import { mapActions } from "vuex"
import { initBuyer } from "@/services/objects"
import cloneDeep from "clone-deep"
import ModalContent from "./ModalContent.vue"
import ModelContainer from "./ModalContainer.vue"
import ModalHeader from "./ModalHeader.vue"
import { checkValidity, formError } from "../../services/validation"
import { computed } from "@vue/runtime-core"
import { useStore } from "@/store/store"

export default {
  name: "BuyerForm",
  props: ["id"],
  components: {
    ...comp,
    ModalContent,
    ModelContainer,
    ModalHeader,
  },
  setup() {
    return {
      buyers: computed(() => useStore().state.collections.buyers),
    }
  },
  data() {
    return {
      buyer: {},
    }
  },
  created() {
    this.buyer = this.id ? cloneDeep(this.buyers[this.id]) : cloneDeep(initBuyer)
  },
  // computed: {
  //   ...mapState(["buyers"]),
  // },
  methods: {
    ...mapActions(["closeBuyer"]),
    add: function() {
      if (checkValidity(this.$refs)) {
        apiSetBuyer(this.buyer)
        this.closeBuyer()
      } else formError()
    },
  },
}
</script>
