<template>
  <ModalBase :close="closeIngredientInventory">
    <template #header
      >Inventory Form | {{ popIngredient.categoryName }} |
      {{ popIngredient.supplierName }}</template
    >

    <BTable :header="['', 'quantity', 'created']">
      <tr v-for="(item, index) in fullList" :key="index">
        <IngredientInventoryItem
          :item="item"
          :index="index"
          :unitText="popIngredient.unitText"
          :id="id"
        />
      </tr>
      <template #footer>
        <td
          class="fw-bold"
          v-for="(foot, index) in ['sum', popIngredient.total + ' ' + popIngredient.unitText, ' ']"
          :key="index"
        >
          {{ foot }}
        </td>
      </template>
    </BTable>

    <Row class="mt-4">
      <BCol>
        <FormGroup class="">
          <FormInput
            ref="formTapQuantity"
            type="number"
            :validateion="validNum"
            v-model.number="quantity"
          />
          <FormSpan>{{ popIngredient.unitText }}</FormSpan>
        </FormGroup>
      </BCol>
      <Button class="col-2 mx-1" :lg="true" @click="add()">add</Button>
    </Row>
  </ModalBase>
</template>

<script>
import { mapActions } from "vuex"
import ModalBase from "./ModalBase.vue"
import comp from "../base"
import { checkValidity, formError, untouch, validNum } from "../../services/validation"
import cloneDeep from "clone-deep"
import { apiSetIngredient } from "../../services/api"
import IngredientInventoryItem from "../ingredients/IngredientInventoryItem.vue"
import { computed, ref } from "vue"
import { useStore } from "@/store/store"

export default {
  name: "IngredientsInventoryForm",
  props: ["id"],
  components: { ModalBase, ...comp, IngredientInventoryItem },
  setup(props) {
    const quantity = ref()
    return {
      validNum,
      popIngredient: computed(() => useStore().getters.popIngredient(props.id)),
      quantity,
    }
  },
  computed: {
    usedInBrew() {
      let ingredients = this.popIngredient.brewIds
        .map(brewId => useStore().getters.popBrew(brewId))
        .map(brew => {
          brew.ingredients.map(ingredient => {
            ingredient["recipeName"] = brew.recipeName
            ingredient.quantity = -ingredient.quantity
          })
          return brew.ingredients
        })
        .flat()
        .filter(item => item.id == this.id)

      return ingredients
    },
    fullList() {
      return [...this.popIngredient.inventory, ...this.usedInBrew]
      // .sort((a, b) => {
      //   a.created = a.created instanceof Date ? a.created.valueOf() : a.created.toDate().valueOf()
      //   return a.created - b.created
      // })
    },
  },
  methods: {
    ...mapActions(["closeIngredientInventory"]),
    add() {
      if (checkValidity(this.$refs)) {
        let local = cloneDeep(useStore().state.collections.ingredients[this.id])
        local.inventory.push({
          quantity: this.quantity,
          created: new Date(),
        })
        apiSetIngredient(local)
        this.quantity = null
        untouch(this.$refs)
      } else formError()
    },
  },
}
</script>
