//MY ICONS
import IconBeer from "./Beer.vue"
import IconBottle from "./Bottle.vue"
import IconMortar from "./Mortar.vue"
import IconBox from "./Box.vue"
import IconTasks from "./Tasks.vue"
import IconMugHot from "./MugHot.vue"
import IconFlask from "./Flask.vue"
import IconMoneyCard from "./MoneyCard.vue"
import IconUntappd from "./Untappd.vue"
import IconShopping from "./Shopping.vue"
import IconWarehouse from "./Warehouse.vue"
import IconDolly from "./Dolly.vue"
import IconTruck from "./Truck.vue"
import IconBook from "./Book.vue"
import IconAddress from "./Address.vue"
import IconSave from "./Save.vue"
import IconEdit from "./Edit.vue"
import IconThermometer from "./Thermometer.vue"
import IconBacteria from "./Bacteria.vue"
import IconVest from "./Vest.vue"
import IconCloudDown from "./CloudDown.vue"
import IconCloudUp from "./CloudUp.vue"
import IconDown from "./Down.vue"
import IconUp from "./Up.vue"

//BOOTSTRAP
import {
  BIconArrowLeftShort,
  BIconArrowRightShort,
  BIconTrash,
  BIconPencilSquare,
  BIconEye,
  BIconHandThumbsDown,
  BIconHandThumbsUp,
  BIconFileMinus,
  BIconFilePlus,
  BIconSearch,
  BIconCaretUp,
  BIconCaretDown,
  BIconCaretUpFill,
  BIconCaretRightFill,
  BIconCaretLeftFill,
  BIconCaretDownFill,
  BIconCheck,
  BIconCircle,
  BIconCheckCircle,
  BIconBarChartSteps,
  BIconLockFill,
  BIconUnlockFill,
} from "bootstrap-icons-vue"

export default {
  IconBeer,
  IconBottle,
  IconMortar,
  IconBox,
  IconTasks,
  IconMugHot,
  IconFlask,
  IconMoneyCard,
  IconAddress,
  IconBook,
  IconTruck,
  IconDolly,
  IconWarehouse,
  IconShopping,
  IconUntappd,
  IconSave,
  IconEdit,
  IconThermometer,
  IconBacteria,
  IconVest,
  IconCloudDown,
  IconCloudUp,
  IconDown,
  IconUp,

  BIconArrowLeftShort,
  BIconArrowRightShort,
  BIconTrash,
  BIconPencilSquare,
  BIconEye,
  BIconHandThumbsDown,
  BIconHandThumbsUp,
  BIconFileMinus,
  BIconFilePlus,
  BIconSearch,
  BIconCaretUp,
  BIconCaretDown,
  BIconCaretUpFill,
  BIconCaretDownFill,
  BIconCaretRightFill,
  BIconCaretLeftFill,
  BIconCheck,
  BIconCircle,
  BIconCheckCircle,
  BIconBarChartSteps,
  BIconLockFill,
  BIconUnlockFill,
}
