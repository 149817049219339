<template>
  <div class="col">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Col",
}
</script>
