import { Company } from "@/typings/types"
import { ActionTree, MutationTree } from "vuex"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, RootState } from "../store"

export interface CompanyState {
  company?: Company
}

const state: CompanyState = {
  company: undefined,
}

export type CompanyMutations = {
  [MutationType.SetCompany](state: CompanyState, payload: Company): void
}

const mutations: MutationTree<CompanyState> & CompanyMutations = {
  [MutationType.SetCompany](state, payload) {
    state.company = payload
  },
}

export type CompanyActions = {
  [ActionTypes.SetCompany](context: ActionAugments, payload: Company): void
}

const actions: ActionTree<RootState, RootState> & CompanyActions = {
  [ActionTypes.SetCompany]({ commit }, payload) {
    commit(MutationType.SetCompany, payload)
  },
}

const getters = {}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
