import { handleDate } from "@/helper/helper"
import { useStore } from "@/store/store"
import { Brew } from "@/typings/types"
import regression from "regression"

type DatePoint = regression.DataPoint

const colors = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
}

function fromStart(date: Date, brew: Brew) {
  const start = handleDate(brew.start).valueOf() ? handleDate(brew.start).valueOf() : 0
  const deltaTime = date.valueOf() - start
  const days = deltaTime / (1000 * 60 * 60 * 24)
  return Math.round(days)
  // return date.valueOf()
}

function formatData(brew: Brew) {
  return brew.fermentationLog
    .map(item => {
      const date = item.time instanceof Date ? item.time : item.time.toDate()
      return { x: fromStart(date, brew), y: Number(item.sg) }
    })
    .sort((a, b) => a.x - b.x)
}

const reg = function(brew: Brew) {
  const d = formatData(brew).map(item => [item.x, item.y] as DatePoint)
  return regression.exponential(d, { precision: 10 })
}

export function dataLog(brew: Brew) {
  return formatData(brew)
}

export function r2(brew: Brew) {
  return Math.round(reg(brew).r2 * 100) / 100
}

export function regressionData(brew: Brew) {
  return reg(brew).points.map(item => {
    return { x: Number(item[0]), y: Number(item[1]) }
  })
}

export function estimatedEnd(brew: Brew) {
  const regResult = reg(brew)
  const a = regResult.equation[0]
  const b = regResult.equation[1]

  const tfg = useStore().getters.popBrew(brew.id).popRecipe.tfg

  const result = Math.log(tfg / a) / b
  if (result == Infinity) return null
  if (result == -Infinity) return null
  return result ? new Date(result) : null
}

export const chartOptions = {
  scales: {
    y: [{}],
    x: [
      {
        type: "time",
        // time: {
        //   min: 1000000000,
        //   max: new Date().valueOf() + 100000000000,
        // },
      },
    ],
  },
}

export const chartData = {
  datasets: [
    {
      label: "Specific Gravity [kg/l]",
      backgroundColor: colors.blue,
      borderColor: colors.blue,
    },
    {
      label: "Exponential Regeression",
      type: "line",
      fill: false,
      pointRadius: 0,
      borderColor: colors.red,
    },
  ],
}

////////////

export const chartOptionsProfile = {
  // elements: {
  //   line: {
  //     tension: 1,
  //   },
  // },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
  },
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 100,
      // display: true,
      title: {
        // display: true,
        // text: "C°",
      },
    },
    x: {
      // display: true,
      title: {
        // display: true,
        // text: "min",
      },
    },
  },
  // plugins: {
  //   legend: {
  //     position: "top",
  //   },
  //   title: {
  //     display: true,
  //     text: "Chart.js Scatter Chart",
  //   },
  // },
}

export const chartDataProfile = {
  datasets: [
    {
      data: [],
      label: "Temperature steps",
      type: "scatter",
      fill: true,
      showLine: true,
      borderColor: colors.red,
    },
  ],
}
