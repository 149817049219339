import { base } from "@/helper/helper"
import { PopUser, User, Users } from "@/typings/types"
import { cloneDeep } from "lodash"
import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { API, ROLE } from "../../services/enums"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, Getters, RootState, store, useStore } from "../store"
import { LoadState } from "./loadState"
import { UserGetters } from "./user"

export interface UsersState {
  users: Users
}

const state: UsersState = {
  users: {},
}

export type UsersMutations = {
  [MutationType.SetUsers](state: UsersState, payload: Users): void
}

const mutations: MutationTree<UsersState> & UsersMutations = {
  [MutationType.SetUsers](state, payload) {
    state.users = payload
  },
}

export type UsersActions = {
  [ActionTypes.LoadUsers](context: ActionAugments, payload: Users): void
}

const actions: ActionTree<RootState, RootState> & UsersActions = {
  [ActionTypes.LoadUsers]({ commit }, payload) {
    if (payload) commit(MutationType.SetUsers, payload)
    commit(MutationType.SetUsersLoadState, LoadState.LOADED) // ,{ root: true })
  },
}

export type UsersGetters = {
  User(state: UsersState): User
  PopUser(state: UsersState): PopUser
}

const getters: GetterTree<UsersState, RootState> & UsersGetters = {
  User: state => {
    let user = state.users[store.getters["uid"]]
    user = base(user)
    return user
  },
  PopUser: state => {
    const user = useStore().getters.User as PopUser

    if (user) {
      user["roleText"] = ROLE.TEXT[user.role]
    }

    return user
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
