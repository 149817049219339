<template>
  <ModalBase :close="closeBrewery">
    <template #header>Brewery Form </template>

    <Row>
      <Lable class="col">Brewery name *</Lable>
      <Lable class="col">Size *</Lable>
    </Row>
    <Row>
      <BCol>
        <FormInput ref="formName" placeholder="Brewery Name" v-model="brewery.name" />
      </BCol>

      <BCol>
        <FormGroup>
          <FormInput
            ref="formSize"
            type="number"
            placeholder="Brewery size"
            :validateion="validNum"
            v-model.number="brewery.size"
          />
          <FormSpan>L</FormSpan>
        </FormGroup>
      </BCol>
    </Row>
    <Row>
      <Lable class="col">Efficiency in % *</Lable>
      <Lable class="col">Evaporation rate*</Lable>
    </Row>
    <Row>
      <BCol>
        <FormGroup>
          <FormInput
            ref="formEfficiency"
            type="number"
            placeholder="Brewery efficiency %"
            :validateion="validNum"
            v-model.number="brewery.efficiency"
          />
          <FormSpan>%</FormSpan>
        </FormGroup>
      </BCol>
      <BCol>
        <FormGroup>
          <FormInput
            ref="formEvaporation"
            type="number"
            placeholder="Brewery evaporation rate"
            :validateion="validNum"
            v-model.number="brewery.evaporation"
          />
          <FormSpan>l/h</FormSpan>
        </FormGroup>
      </BCol>
    </Row>

    <Row class="mt-4">
      <Button lg="true" @click="save()">Save Brewery</Button>
    </Row>
  </ModalBase>
</template>

<script>
import { mapActions } from "vuex"
import { apiSetBrewery } from "../../services/api"
import comp from "@/components/base"
import { initBrewery } from "../../services/objects"
import cloneDeep from "clone-deep"
import ModalBase from "./ModalBase.vue"

import { checkValidity, formError, validNum } from "../../services/validation"
import { computed } from "@vue/runtime-core"
import { useStore } from "@/store/store"

export default {
  name: "OrderForm",
  props: ["id"],
  components: {
    ModalBase,
    ...comp,
  },
  setup() {
    return {
      validNum,
      breweries: computed(() => useStore().state.collections.breweries),
    }
  },
  data() {
    return {
      brewery: {},
    }
  },
  created() {
    this.brewery = this.id ? cloneDeep(this.breweries[this.id]) : cloneDeep(initBrewery)
  },
  methods: {
    ...mapActions(["closeBrewery"]),
    save() {
      if (checkValidity(this.$refs)) {
        apiSetBrewery(this.brewery)
        this.closeBrewery()
      } else formError()
    },
  },
}
</script>
