export enum ActionTypes {
  //COLLECTIONS
  LoadBreweries = "loadBreweries",
  LoadBrews = "loadBrews",
  LoadOrders = "loadOrders",
  LoadBuyers = "loadBuyers",
  LoadRecipes = "loadRecipes",
  LoadTanks = "loadTanks",
  LoadSuppliers = "loadSuppliers",
  LoadStyles = "loadStyles",
  LoadProfiles = "loadProfiles",
  LoadIngredients = "loadIngredients",
  LoadContainers = "loadContainers",

  //LOAD STATE
  ResetLoad = "RESET_ALL_LOAD_STATE",

  //COMPANY
  SetCompany = "setCompany",

  //USER
  SetUid = "setUid",

  //DELETE MODALS
  OpenDeleteBrew = "openDeleteBrew",
  OpenDeleteBrewery = "openDeleteBrewery",
  OpenDeleteBuyer = "openDeleteBuyer",
  OpenDeleteRecipe = "openDeleteRecipe",
  OpenDeleteOrder = "openDeleteOrder",
  OpenDeleteStyle = "openDeleteStyle",
  OpenDeleteSupplier = "openDeleteSupplier",
  OpenDeleteTank = "openDeleteTank",
  OpenDeleteProfile = "openDeleteProfile",
  OpenDeleteIngredient = "openDeleteIngredient",
  OpenDeleteContainer = "openDeleteContainer",
  CloseDelete = "closeDelete",

  //LOCAL MODALS
  OpenLocalFermentable = "openLocalFermentable",
  OpenLocalKettle = "openLocalKettle",
  OpenLocalFermentation = "openLocalFermentation",
  OpenLocalSelling = "openLocalSelling",
  OpenLocalTap = "openLocalTap",
  CloseLocal = "closeLocal",

  //MESSAGE
  MessageSuccess = "messageSuccess",
  MessageSetSuccess = "messageSetSuccess",
  MessageGetSuccess = "messageGetSuccess",
  MessageFormSuccess = "messageFormSuccess",
  MessageError = "messageError",
  MessageSetError = "messageSetError",
  MessageGetError = "messageGetError",
  MessageFormError = "messageFormError",
  MessageRemove = "messageRemove",

  //MODALS
  OpenStyle = "openStyle",
  CloseStyle = "closeStyle",
  OpenTank = "openTank",
  CloseTank = "closeTank",
  OpenSupplier = "openSupplier",
  CloseSupplier = "closeSupplier",
  OpenRecipe = "openRecipe",
  CloseRecipe = "closeRecipe",
  OpenBrew = "openBrew",
  CloseBrew = "closeBrew",
  OpenBuyer = "openBuyer",
  CloseBuyer = "closeBuyer",
  OpenOrder = "openOrder",
  CloseOrder = "closeOrder",
  OpenProfile = "openProfile",
  CloseProfile = "closeProfile",
  OpenBrewery = "openBrewery",
  CloseBrewery = "closeBrewery",
  OpenIngredient = "openIngredient",
  CloseIngredient = "closeIngredient",
  OpenIngredientInventory = "openIngredientInventory",
  CloseIngredientInventory = "closeIngredientInventory",
  OpenContainer = "openContainer",
  CloseContainer = "closeContainer",
  OpenContainerInventory = "openContainerInventory",
  CloseContainerInventory = "closeContainerInventory",
  OpenUser = "openUser",
  CloseUser = "closeUser",

  //UPDATE TRACKER
  SetUpdateTrackerUid = "setUpdateTrackerUid",
  SetUpdateTrackerItemId = "setUpdateTrackerItemId",

  //USERS
  LoadUsers = "loadUsers",

  //VALIDATION
  PushValid = "pushValid",
  ClearValid = "clearValid",
}
