<template>
  <ModelContainer @click.self="close">
    <ModalContent>
      <ModalHeader>
        <slot name="header" />
        <template #right>
          <ButtonGroup>
            <Button v-if="openModal" @click="openModal()">{{ modalName }}</Button>
            <Button @click="close">close</Button>
          </ButtonGroup>
        </template>
      </ModalHeader>
      <slot />
    </ModalContent>
  </ModelContainer>
</template>

<script>
import ModalContent from "./ModalContent.vue"
import ModelContainer from "./ModalContainer.vue"
import ModalHeader from "./ModalHeader.vue"
import Button from "../base/Button"
import ButtonGroup from "../base/ButtonGroup.vue"

export default {
  name: "ModalBase",
  props: ["close", "openModal", "modalName"],
  components: {
    ModalContent,
    ModelContainer,
    ModalHeader,
    Button,
    ButtonGroup,
  },
}
</script>

<style></style>
