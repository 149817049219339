<template>
  <th scope="col">
    <BIconCaretUpFill v-if="sort" />
    <BIconCaretDownFill v-else />
    <slot />
  </th>
</template>

<script>
import icons from "../../assets/icons"
export default {
  name: "TH",
  props: ["sort"],
  components: {
    ...icons,
  },
}
</script>
