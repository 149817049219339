<template>
  <button class="btn btn-sm btn-outline-secondary" type="button">
    <router-link
      class="text-dark align-self-center"
      :to="{ name: name, params: { id: params }, query: query }"
    >
      <slot />
    </router-link>
  </button>
</template>

<script>
export default {
  name: "ButtonLink",
  props: ["name", "params", "query"],
}
</script>
