import { ActionTree, MutationTree } from "vuex"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, RootState } from "../store"

export interface ModalsState {
  style: boolean
  styleId: string | null
  buyer: boolean
  buyerId: string | null
  tank: boolean
  tankId: string | null
  supplier: boolean
  supplierId: string | null
  type: boolean
  typeId: string | null
  recipe: boolean
  recipeId: string | null
  brew: boolean
  brewId: string | null
  order: boolean
  orderId: string | null
  profile: boolean
  profileId: string | null
  brewery: boolean
  breweryId: string | null
  ingredient: boolean
  ingredientId: string | null
  ingredientInventory: boolean
  ingredientInventoryId: string | null
  container: boolean
  containerId: string | null
  containerInventory: boolean
  containerInventoryId: string | null
  user: boolean
}

const state: ModalsState = {
  style: false,
  styleId: null,
  buyer: false,
  buyerId: null,
  tank: false,
  tankId: null,
  supplier: false,
  supplierId: null,
  type: false,
  typeId: null,
  recipe: false,
  recipeId: null,
  brew: false,
  brewId: null,
  order: false,
  orderId: null,
  profile: false,
  profileId: null,
  brewery: false,
  breweryId: null,
  ingredient: false,
  ingredientId: null,
  ingredientInventory: false,
  ingredientInventoryId: null,
  container: false,
  containerId: null,
  containerInventory: false,
  containerInventoryId: null,
  user: false,
}

export type ModalsMutations = {
  [MutationType.SetModalStyle](state: ModalsState, payload: boolean): void
  [MutationType.SetModalStyleId](state: ModalsState, payload: string): void
  [MutationType.SetModalTank](state: ModalsState, payload: boolean): void
  [MutationType.SetModalTankId](state: ModalsState, payload: string): void
  [MutationType.SetModalSupplier](state: ModalsState, payload: boolean): void
  [MutationType.SetModalSupplierId](state: ModalsState, payload: string): void
  [MutationType.SetModalRecipe](state: ModalsState, payload: boolean): void
  [MutationType.SetModalRecipeId](state: ModalsState, payload: string): void
  [MutationType.SetModalBrew](state: ModalsState, payload: boolean): void
  [MutationType.SetModalBrewId](state: ModalsState, payload: string): void
  [MutationType.SetModalBuyer](state: ModalsState, payload: boolean): void
  [MutationType.SetModalBuyerId](state: ModalsState, payload: string): void
  [MutationType.SetModalOrder](state: ModalsState, payload: boolean): void
  [MutationType.SetModalOrderId](state: ModalsState, payload: string): void
  [MutationType.SetModalProfile](state: ModalsState, payload: boolean): void
  [MutationType.SetModalProfileId](state: ModalsState, payload: string): void
  [MutationType.SetModalBrewery](state: ModalsState, payload: boolean): void
  [MutationType.SetModalBreweryId](state: ModalsState, payload: string): void
  [MutationType.SetModalIngredient](state: ModalsState, payload: boolean): void
  [MutationType.SetModalIngredientId](state: ModalsState, payload: string): void
  [MutationType.SetModalIngredientInventory](state: ModalsState, payload: boolean): void
  [MutationType.SetModalIngredientInventoryId](state: ModalsState, payload: string): void
  [MutationType.SetModalContainer](state: ModalsState, payload: boolean): void
  [MutationType.SetModalContainerId](state: ModalsState, payload: string): void
  [MutationType.SetModalContainerInventory](state: ModalsState, payload: boolean): void
  [MutationType.SetModalContainerInventoryId](state: ModalsState, payload: string): void
  [MutationType.SetModalUser](state: ModalsState, payload: boolean): void
}

const mutations: MutationTree<ModalsState> & ModalsMutations = {
  [MutationType.SetModalStyle](state, payload) {
    state.style = payload
  },
  [MutationType.SetModalStyleId](state, payload) {
    state.styleId = payload
  },
  [MutationType.SetModalTank](state, payload) {
    state.tank = payload
  },
  [MutationType.SetModalTankId](state, payload) {
    state.tankId = payload
  },
  [MutationType.SetModalSupplier](state, payload) {
    state.supplier = payload
  },
  [MutationType.SetModalSupplierId](state, payload) {
    state.supplierId = payload
  },
  [MutationType.SetModalRecipe](state, payload) {
    state.recipe = payload
  },
  [MutationType.SetModalRecipeId](state, payload) {
    state.recipeId = payload
  },
  [MutationType.SetModalBrew](state, payload) {
    state.brew = payload
  },
  [MutationType.SetModalBrewId](state, payload) {
    state.brewId = payload
  },
  [MutationType.SetModalBuyer](state, payload) {
    state.buyer = payload
  },
  [MutationType.SetModalBuyerId](state, payload) {
    state.buyerId = payload
  },
  [MutationType.SetModalOrder](state, payload) {
    state.order = payload
  },
  [MutationType.SetModalOrderId](state, payload) {
    state.orderId = payload
  },
  [MutationType.SetModalProfile](state, payload) {
    state.profile = payload
  },
  [MutationType.SetModalProfileId](state, payload) {
    state.profileId = payload
  },
  [MutationType.SetModalBrewery](state, payload) {
    state.brewery = payload
  },
  [MutationType.SetModalBreweryId](state, payload) {
    state.breweryId = payload
  },
  [MutationType.SetModalIngredient](state, payload) {
    state.ingredient = payload
  },
  [MutationType.SetModalIngredientId](state, payload) {
    state.ingredientId = payload
  },
  [MutationType.SetModalIngredientInventory](state, payload) {
    state.ingredientInventory = payload
  },
  [MutationType.SetModalIngredientInventoryId](state, payload) {
    state.ingredientInventoryId = payload
  },
  [MutationType.SetModalContainer](state, payload) {
    state.container = payload
  },
  [MutationType.SetModalContainerId](state, payload) {
    state.containerId = payload
  },
  [MutationType.SetModalContainerInventory](state, payload) {
    state.containerInventory = payload
  },
  [MutationType.SetModalContainerInventoryId](state, payload) {
    state.containerInventoryId = payload
  },
  [MutationType.SetModalUser](state, payload) {
    state.user = payload
  },
}

export type ModalsActions = {
  [ActionTypes.OpenStyle](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseStyle](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenTank](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseTank](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenSupplier](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseSupplier](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenRecipe](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseRecipe](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenBrew](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseBrew](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenBuyer](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseBuyer](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenOrder](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseOrder](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenProfile](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseProfile](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenBrewery](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseBrewery](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenIngredient](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseIngredient](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenIngredientInventory](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseIngredientInventory](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenContainer](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseContainer](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenContainerInventory](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseContainerInventory](context: ActionAugments, payloac: string): void
  [ActionTypes.OpenUser](context: ActionAugments, payloac: string): void
  [ActionTypes.CloseUser](context: ActionAugments, payloac: string): void
}

const actions: ActionTree<RootState, RootState> & ModalsActions = {
  [ActionTypes.OpenStyle]({ commit }, id) {
    commit(MutationType.SetModalStyle, true)
    commit(MutationType.SetModalStyleId, id)
  },
  [ActionTypes.CloseStyle]({ commit }) {
    commit(MutationType.SetModalStyle, false)
  },
  [ActionTypes.OpenTank]({ commit }, id) {
    commit(MutationType.SetModalTank, true)
    commit(MutationType.SetModalTankId, id)
  },
  [ActionTypes.CloseTank]({ commit }) {
    commit(MutationType.SetModalTank, false)
  },
  [ActionTypes.OpenSupplier]({ commit }, id) {
    commit(MutationType.SetModalSupplier, true)
    commit(MutationType.SetModalSupplierId, id)
  },
  [ActionTypes.CloseSupplier]({ commit }) {
    commit(MutationType.SetModalSupplier, false)
  },
  [ActionTypes.OpenRecipe]({ commit }, id) {
    commit(MutationType.SetModalRecipe, true)
    commit(MutationType.SetModalRecipeId, id)
  },
  [ActionTypes.CloseRecipe]({ commit }) {
    commit(MutationType.SetModalRecipe, false)
  },
  [ActionTypes.OpenBrew]({ commit }, id) {
    commit(MutationType.SetModalBrew, true)
    commit(MutationType.SetModalBrewId, id)
  },
  [ActionTypes.CloseBrew]({ commit }) {
    commit(MutationType.SetModalBrew, false)
  },
  [ActionTypes.OpenBuyer]({ commit }, id) {
    commit(MutationType.SetModalBuyer, true)
    commit(MutationType.SetModalBuyerId, id)
  },
  [ActionTypes.CloseBuyer]({ commit }) {
    commit(MutationType.SetModalBuyer, false)
  },
  [ActionTypes.OpenOrder]({ commit }, id) {
    commit(MutationType.SetModalOrder, true)
    commit(MutationType.SetModalOrderId, id)
  },
  [ActionTypes.CloseOrder]({ commit }) {
    commit(MutationType.SetModalOrder, false)
  },
  [ActionTypes.OpenProfile]({ commit }, id) {
    commit(MutationType.SetModalProfile, true)
    commit(MutationType.SetModalProfileId, id)
  },
  [ActionTypes.CloseProfile]({ commit }) {
    commit(MutationType.SetModalProfile, false)
  },
  [ActionTypes.OpenBrewery]({ commit }, id) {
    commit(MutationType.SetModalBrewery, true)
    commit(MutationType.SetModalBreweryId, id)
  },
  [ActionTypes.CloseBrewery]({ commit }) {
    commit(MutationType.SetModalBrewery, false)
  },
  [ActionTypes.OpenIngredient]({ commit }, id) {
    commit(MutationType.SetModalIngredient, true)
    commit(MutationType.SetModalIngredientId, id)
  },
  [ActionTypes.CloseIngredient]({ commit }) {
    commit(MutationType.SetModalIngredient, false)
  },
  [ActionTypes.OpenIngredientInventory]({ commit }, id) {
    commit(MutationType.SetModalIngredientInventory, true)
    commit(MutationType.SetModalIngredientInventoryId, id)
  },
  [ActionTypes.CloseIngredientInventory]({ commit }) {
    commit(MutationType.SetModalIngredientInventory, false)
  },
  [ActionTypes.OpenContainer]({ commit }, id) {
    commit(MutationType.SetModalContainer, true)
    commit(MutationType.SetModalContainerId, id)
  },
  [ActionTypes.CloseContainer]({ commit }) {
    commit(MutationType.SetModalContainer, false)
  },
  [ActionTypes.OpenContainerInventory]({ commit }, id) {
    commit(MutationType.SetModalContainerInventory, true)
    commit(MutationType.SetModalContainerInventoryId, id)
  },
  [ActionTypes.CloseContainerInventory]({ commit }) {
    commit(MutationType.SetModalContainerInventory, false)
  },
  [ActionTypes.OpenUser]({ commit }, id) {
    commit(MutationType.SetModalUser, true)
  },
  [ActionTypes.CloseUser]({ commit }) {
    commit(MutationType.SetModalUser, false)
  },
}

const getters = {}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
