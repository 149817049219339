import { base } from "@/helper/helper"
import { CONTAINER, UNIT } from "@/services/enums"
import { Container, PopContainer } from "@/typings/types"
import { cloneDeep } from "lodash"
import { GetterTree } from "vuex"
import { RootState, useStore } from "../store"

export type ContainerGetters = {
  container(state: RootState): (id: string) => Container
  popContainer(state: RootState): (id: string) => PopContainer
  popContainers(state: RootState): PopContainer[]
}

const getTotal = (container: PopContainer) => {
  const sum = container.inventory.reduce((acc, { quantity }) => (acc += quantity), 0)

  const containers = container.brewIds
    .map(brewId => useStore().state.collections.brews[brewId].containers)
    .flat()

  const used = containers
    .filter(({ id }) => id == container.id)
    .reduce((acc, { quantity }) => (acc += quantity), 0)

  return sum - used
}

export const containersGetters: GetterTree<RootState, RootState> & ContainerGetters = {
  container: state => id => {
    let container = cloneDeep(state.collections.containers[id])
    container = base(container)
    return container
  },

  popContainer: state => id => {
    const container = useStore().getters.container(id) as PopContainer

    container["stateText"] = CONTAINER.TEXT[container.state]
    container["unitText"] = UNIT.TEXT[container.unit]
    container["supplierName"] = state.collections.suppliers[container.supplierId].name

    // container.inventory.map(
    //   item => (item["createdDate"] = format(item.created.toDate(), TIME_FORMAT.TIME))
    // )
    container["total"] = getTotal(container)

    return container
  },

  popContainers: state => {
    let containers = Object.values(cloneDeep(state.collections.containers)) as PopContainer[]
    containers = containers.map(item => useStore().getters.popContainer(item.id))
    return containers
  },
}
