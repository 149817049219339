<template>
  <div class="b-fooder">
    <hr class="line my-2" />
    <div class=" top d-flex justify-content-between align-items-center">
      <slot name="top"></slot>
    </div>
    <hr class="line my-2" />
    <div class="bottom d-flex justify-content-center">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BFooder",
}
</script>

<style scoped></style>
