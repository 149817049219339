<template>
  <div class="badge text-dark" role="button">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BadgeBtn",
}
</script>
<style scoped>
.badge {
  background-color: rgb(220, 220, 220);
}
</style>
