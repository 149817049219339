<template>
  <div class="p-4" :style="{ backgroundColor: bg }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BCard",
  props: ["bg"],
}
</script>
