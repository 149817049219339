<template>
  <ModelContainer @click.self="closeDelete()">
    <ModalContent>
      <ModalHeader v-if="dependents">
        Are can't delete <strong>{{ popItem.title }}</strong> due to the follwing dependents :
      </ModalHeader>
      <ModalHeader v-else> Are you sure you want to delete {{ popItem.title }} ? </ModalHeader>

      <div v-if="dependents" class="my-3">
        <span v-for="(item, index) in popItem.dependents" :key="index" class="mx-1">
          <strong>{{ item }}</strong>
        </span>
      </div>
      <Row>
        <Button class="mx-1 col" lg="true" @click="closeDelete()">Back</Button>
        <Button v-if="!dependents" class="mx-1 col btn-outline-danger" lg="true" @click="del()"
          >Yes, delete {{ popItem.title }}</Button
        >
      </Row>
    </ModalContent>
  </ModelContainer>
</template>

<script>
import comp from "@/components/base"
// import { apiSetBuyer } from "@/services/api"
import { mapActions } from "vuex"
import ModalContent from "./ModalContent.vue"
import ModelContainer from "./ModalContainer.vue"
import ModalHeader from "./ModalHeader.vue"
import cloneDeep from "clone-deep"
import {
  apiDelBrew,
  apiDelBuyer,
  apiDelOrder,
  apiDelRecipe,
  apiDelStyle,
  apiDelSupplier,
  apiDelTank,
  apiDelProfile,
  apiDelBrewery,
  apiDelContainer,
  apiDelIngredient,
} from "../../services/api"
import { computed } from "@vue/runtime-core"
import { useStore } from "@/store/store"

export default {
  name: "DeleteForm",
  props: ["id", "type"],
  components: {
    ...comp,
    ModalContent,
    ModelContainer,
    ModalHeader,
  },
  setup() {
    return {
      brews: computed(() => useStore().state.collections.brews),
      recipes: computed(() => useStore().state.collections.recipes),
      orders: computed(() => useStore().state.collections.orders),
      buyers: computed(() => useStore().state.collections.buyers),
      styles: computed(() => useStore().state.collections.styles),
      tanks: computed(() => useStore().state.collections.tanks),
      suppliers: computed(() => useStore().state.collections.suppliers),
      profiles: computed(() => useStore().state.collections.profiles),
      containers: computed(() => useStore().state.collections.containers),
      ingredients: computed(() => useStore().state.collections.ingredients),
      breweries: computed(() => useStore().state.collections.breweries),

      brew: computed(() => useStore().getters.popBrew),
      recipe: computed(() => useStore().getters.popRecipe),
      order: computed(() => useStore().getters.popOrder),
      ingredient: computed(() => useStore().getters.popIngredient),
      container: computed(() => useStore().getters.popContainer),

      buyer: computed(() => useStore().getters.buyer),
      style: computed(() => useStore().getters.style),
      tank: computed(() => useStore().getters.tank),
      supplier: computed(() => useStore().getters.supplier),
      profile: computed(() => useStore().getters.profile),
      brewery: computed(() => useStore().getters.brewery),
    }
  },
  data() {
    return {
      item: null,
    }
  },
  created() {
    if (this.id) {
      if (this.type == "brew") this.item = cloneDeep(this.brew(this.id))
      if (this.type == "order") this.item = cloneDeep(this.orders[this.id])
      if (this.type == "style") this.item = cloneDeep(this.styles[this.id])
      if (this.type == "tank") this.item = cloneDeep(this.tanks[this.id])
      if (this.type == "supplier") this.item = cloneDeep(this.suppliers[this.id])
      if (this.type == "recipe") this.item = cloneDeep(this.recipes[this.id])
      if (this.type == "buyer") this.item = cloneDeep(this.buyers[this.id])
      if (this.type == "profile") this.item = cloneDeep(this.profiles[this.id])
      if (this.type == "brewery") this.item = cloneDeep(this.breweries[this.id])
      if (this.type == "container") this.item = cloneDeep(this.containers[this.id])
      if (this.type == "ingredient") this.item = cloneDeep(this.ingredient(this.id))
    }
  },
  computed: {
    popItem() {
      let item = cloneDeep(this.item)
      if (this.type == "brew") {
        item["title"] = item.formatCount + " " + item.recipeName
        item["dependents"] = item.orderIds
          .map(orderId => this.order(orderId))
          .map(order => order.formatCount + " " + order.buyerName + " " + order.shippingText)
      }
      if (this.type == "order") {
        item["title"] = item.count + this.buyers[item.buyerId].name
        item["dependents"] = []
      }
      if (this.type == "style") {
        item["title"] = item.name
        item["dependents"] = Object.values(this.recipes)
          .filter(recipe => recipe.styleId == item.id)
          .map(recipe => recipe.name)
      }
      if (this.type == "tank") {
        item["title"] = item.name
        item["dependents"] = useStore()
          .getters.popBrews.filter(brew => brew.tankId == item.id)
          .map(brew => brew.formatCount + brew.recipeName)
      }
      if (this.type == "supplier") {
        item["title"] = item.name
        let containers = Object.values(this.containers)
          .filter(container => container.supplierId == item.id)
          .map(container => container.name)
        let ingredients = Object.values(this.ingredients)
          .filter(ingredient => ingredient.supplierId == item.id)
          .map(ingredient => ingredient.name)
        item["dependents"] = [...containers, ...ingredients]
      }
      if (this.type == "recipe") {
        item["title"] = item.name
        item["dependents"] = useStore()
          .getters.popBrews.filter(brew => brew.recipeCopy.id == item.id)
          .map(brew => brew.formatCount + item.name)
      }
      if (this.type == "buyer") {
        item["title"] = item.name
        item["dependents"] = useStore()
          .getters.popOrders.filter(order => order.buyerId == item.id)
          .map(order => order.formatCount + " " + order.buyerName + " " + order.shippingText)
      }
      if (this.type == "profile") {
        item["title"] = item.name
        item["dependents"] = Object.values(this.recipes)
          .filter(
            recipe =>
              (recipe.mashing.profileId == item.id) | (recipe.fermentation.profileId == item.id)
          )
          .map(recipe => recipe.name)
      }
      if (this.type == "ingredient") {
        item["title"] = item.name
        item["dependents"] = item.brewIds
          .map(brewId => this.brew(brewId))
          .map(brew => brew.formatCount + " " + brew.recipeName)
      }

      if (this.type == "container") {
        item["title"] = item.name
        item["dependents"] = item.brewIds
          .map(brewId => this.brew(brewId))
          .map(brew => brew.formatCount + " " + brew.recipeName)
      }

      if (this.type == "brewery") {
        item["title"] = item.name
        item["dependents"] = Object.values(this.recipes)
          .filter(recipe => recipe.breweryId == item.id)
          .map(recipe => recipe.name)
      }

      return item
    },
    dependents() {
      return this.popItem.dependents.length > 0 ? true : false
    },
  },
  methods: {
    ...mapActions(["closeDelete"]),
    del() {
      if (this.type == "brew") apiDelBrew(this.id)
      if (this.type == "order") apiDelOrder(this.id)
      if (this.type == "buyer") apiDelBuyer(this.id)
      if (this.type == "recipe") apiDelRecipe(this.id)
      if (this.type == "style") apiDelStyle(this.id)
      if (this.type == "supplier") apiDelSupplier(this.id)
      if (this.type == "tank") apiDelTank(this.id)
      if (this.type == "profile") apiDelProfile(this.id)
      if (this.type == "container") apiDelContainer(this.id)
      if (this.type == "ingredient") apiDelIngredient(this.id)
      if (this.type == "brewery") apiDelBrewery(this.id)
      this.closeDelete()
    },
  },
}
</script>
