<template>
  <ModelContainer @click.self="closeIngredient()">
    <ModalContent>
      <ModalHeader>
        Ingredient Form
        <template #right>
          <Button @click="closeIngredient()">close</Button>
        </template>
      </ModalHeader>
      <Row>
        <BCol>
          <Lable>Ingredien type *</Lable>
          <FormSelect
            ref="formCategory"
            :strCallback="item => item"
            :items="CATEGORY.TEXT"
            v-model.number="ingredient.category"
          />
        </BCol>
        <BCol>
          <Lable>Name *</Lable>
          <FormInput ref="formName" placeholder="Ingredient name" v-model="ingredient.name" />
        </BCol>
        <!-- IF MALT -->
        <BCol v-if="ingredient.category == CATEGORY.MALT">
          <Lable>EBC *</Lable>
          <FormInput
            ref="formEBC"
            type="number"
            :validateion="validNum"
            v-model.number="ingredient.ebc"
          />
        </BCol>
        <BCol v-if="ingredient.category == CATEGORY.MALT">
          <Lable>Grain Potential (PPG) *</Lable>
          <FormGroup>
            <FormInput
              ref="formPPG"
              type="number"
              :validateion="validNum"
              v-model.number="ingredient.ppg"
            />
            <FormSpan>points</FormSpan>
          </FormGroup>
        </BCol>
        <!-- IF HOPS -->
        <BCol v-if="ingredient.category == CATEGORY.HOPS">
          <Lable>AA *</Lable>
          <FormGroup>
            <FormInput
              type="number"
              ref="formAA"
              :validateion="validNum"
              v-model.number="ingredient.aa"
            />
            <FormSpan>%</FormSpan>
          </FormGroup>
        </BCol>
        <!-- IF YEAST -->
        <BCol v-if="ingredient.category == CATEGORY.YEAST">
          <Lable>Yeast attenuation *</Lable>
          <FormGroup>
            <FormInput
              type="number"
              placeholder="Attenuation [%]"
              ref="formAttenuation"
              :validateion="validNum"
              v-model.number="ingredient.attenuation"
            />
            <FormSpan>%</FormSpan>
          </FormGroup>
        </BCol>
      </Row>
      <Row>
        <Lable class="col-4">
          Select Supplier *
          <template #right>
            <BadgeBtn @click="openSupplier()"> + Supplier</BadgeBtn>
          </template>
        </Lable>
        <Lable class="col">Unit *</Lable>
      </Row>
      <Row>
        <BCol class="col-4">
          <FormSelect
            ref="formSupplier"
            :items="suppliers"
            v-model="ingredient.supplierId"
            :strCallback="item => item.name"
            :valueCallback="item => item.id"
          />
        </BCol>
        <BCol class="col-4">
          <FormSelect
            ref="formUnit"
            :items="UNIT.TEXT"
            :strCallback="item => item"
            v-model.number="ingredient.unit"
          />
        </BCol>

        <Button class="mx-1 col" lg="true" @click="save()">
          save
        </Button>
      </Row>
    </ModalContent>
  </ModelContainer>
</template>

<script>
import { initIngredient } from "../../services/objects"
import { UNIT, CATEGORY } from "../../services/enums"
import { mapActions, mapGetters } from "vuex"
import { apiSetIngredient } from "../../services/api"
import comp from "../base"
import ModalContent from "./ModalContent.vue"
import ModelContainer from "./ModalContainer.vue"
import ModalHeader from "./ModalHeader.vue"
import cloneDeep from "clone-deep"
import { checkValidity, formError, validNum } from "../../services/validation"
import { useStore } from "@/store/store"

export default {
  name: "IngredientForm",
  props: ["id"],
  components: {
    ...comp,
    ModalContent,
    ModelContainer,
    ModalHeader,
  },
  setup() {
    return { CATEGORY, UNIT, validNum }
  },
  data() {
    return {
      ingredient: {},
    }
  },
  created() {
    this.ingredient = this.id
      ? cloneDeep(useStore().state.collections.ingredients[this.id])
      : cloneDeep(initIngredient)
  },
  computed: {
    ...mapGetters(["suppliers"]),
  },
  methods: {
    ...mapActions(["openSupplier", "closeIngredient"]),

    save: function() {
      if (checkValidity(this.$refs)) {
        apiSetIngredient(this.ingredient)
        this.closeIngredient()
      } else formError()
    },
  },
}
</script>
