<template>
  <td>
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "TD",
}
</script>
