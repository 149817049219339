<template>
  <div class="row g-2 align-items-center">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Row",
}
</script>

<style></style>
