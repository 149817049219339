export const ROLE = {
  PLANNER: 0,
  BREWER: 1,
  ADMIN: 2,
  TEXT: ["Planner", "Brewer", "Administrator"],
}

export const TIME_FORMAT = {
  TIME: "kk:mm | dd/MM/yyyy",
  DATE: "dd/MM/yyyy",
}

export const MESSAGE = {
  ERROR: 0,
  SUCCESS: 1,
  FORM_ERROR: 2,
  FORM_SUCCESS: 3,
  SET_ERROR: 4,
  SET_SUCCESS: 5,
  GET_ERROR: 6,
  GET_SUCCESS: 7,
  TEXT: [
    "Error",
    "Success",
    "Form not valid",
    "Valid form",
    "Set error",
    "Set success",
    "Get error",
    "Get success",
  ],
  COLOR: ["#dc3545", "#188754", "#dc3545", "#188754", "#dc3545", "#188754", "#dc3545", "#188754"],
}

export const CATEGORY = {
  MALT: 0,
  HOPS: 1,
  YEAST: 2,
  SUGAR: 3,
  MISC: 4,
  TEXT: ["Malt", "Hops", "Yeast", "Sugar", "Misc"],
  COLOR: ["#919966", "#6f7e9f", "#bcad9a", "#82aba5", "#b4c2a3"],
}

export const PROFILE = {
  MASHING: 0,
  FERMENTATION: 1,
  TEXT: ["Mashing", "Fermentation"],
}

export const ACTION = {
  ADD: 0,
  BOIL: 1,
  WATER: 2,
  TEXT: ["Ingredients", "Boil", "Water"],
  COLOR: ["#374026", "#C2FF99", "#5C9985", "#99F8FF"],
}

export const TANK = {
  FERMENTATION: 0,
  BUFFER: 1,
  TEXT: ["Fermentation tank", "BBT tank"],
}

export const ORDER = {
  PLANING: 0,
  SHIPPED: 1,
  TEXT: ["Planing", "Shipped"],
}

export const BUYER = {
  INACTIVE: 0,
  ACTIVE: 1,
  TEXT: ["Inactive", "Active"],
}
// export const PACK_TYPE = {
//   KEG: 0,
//   KeyKeg: 1,
//   Dolium: 2,
//   Can: 3,
//   Bottle: 4,
//   MISC: 5,
//   TEXT: ["Keg", "KeyKeg", "Dolium", "Can", "Bottle", "Misc"],
//   COLOR: ["#919966", "#6f7e9f", "#bcad9a", "#82aba5", "#b4c2a3"]
// };

export const API = {
  INIT: 0,
  LOADING: 1,
  ERROR: 2,
  LOADED: 3,
  TEXT: ["Initializing...", "Loading...", "Error...", "Loaded!"],
}

export const BREW = {
  PLANNING: 0,
  FERMENTING: 1,
  DONE: 2,
  BOTTLED: 3,
  IN_STOCK: 4,
  OUT_OF_STOCK: 5,
  TEXT: ["Planning", "Fermenting", "Done", "Bottled", "In stock", "Out of stock"],
}

export const SUPPLIER = {
  INACTIVE: 0,
  ACTIVE: 1,
  TEXT: ["Inactive", "Active"],
}

export const STOCK = {
  PLANING: 0,
  ORDERED: 1,
  IN_STOCK: 2,
  OUT_OF_STOCK: 3,
  EXPIRED: 4,
  TEXT: ["Pending", "Ordered", "In stock", "Out of stock", "Expired"],
}

export const CONTAINER = {
  PLANING: 0,
  ORDERED: 1,
  IN_STOCK: 2,
  OUT_OF_STOCK: 3,
  EXPIRED: 4,
  TEXT: ["Pending", "Ordered", "In stock", "Out of stock", "Expired"],
}

export const UNIT = {
  G: 0,
  KG: 1,
  L: 2,
  HL: 3,
  TEXT: ["GR", "KG", "L", "HL"],
}

export const TIME = {
  MINUTE: 0,
  DAY: 1,
  TEXT: ["Minutes", "Days"],
}

export const STYLE_COLORS = [
  "#FEC5BB",
  "#FCD5CE",
  "#FAE1DD",
  "#F8EDEB",
  "#E8E8E4",
  "#D8E2DC",
  "#ECE4DB",
  "#FFE5D9",
  "#FFD7BA",
  "#FEC89A",
]

export const TYPE_COLORS = [
  "#4D2E2E",
  "#CC857A",
  "#FFC299",
  "#997C5C",
  "#E5D38A",
  "#637345",
  "#374026",
  "#C2FF99",
  "#5C9985",
  "#99F8FF",
  "#263E40",
  "#73A6BF",
  "#99C2FF",
  "#364459",
  "#9E91F2",
  "#2C1F33",
  "#955C99",
  "#FF99F8",
  "#F291B8",
  "#804D5A",
]

export const colorName = {
  aliceblue: [240, 248, 255],
  antiquewhite: [250, 235, 215],
  aqua: [0, 255, 255],
  aquamarine: [127, 255, 212],
  azure: [240, 255, 255],
  beige: [245, 245, 220],
  bisque: [255, 228, 196],
  black: [0, 0, 0],
  blanchedalmond: [255, 235, 205],
  blue: [0, 0, 255],
  blueviolet: [138, 43, 226],
  brown: [165, 42, 42],
  burlywood: [222, 184, 135],
  cadetblue: [95, 158, 160],
  chartreuse: [127, 255, 0],
  chocolate: [210, 105, 30],
  coral: [255, 127, 80],
  cornflowerblue: [100, 149, 237],
  cornsilk: [255, 248, 220],
  crimson: [220, 20, 60],
  cyan: [0, 255, 255],
  darkblue: [0, 0, 139],
  darkcyan: [0, 139, 139],
  darkgoldenrod: [184, 134, 11],
  darkgray: [169, 169, 169],
  darkgreen: [0, 100, 0],
  darkgrey: [169, 169, 169],
  darkkhaki: [189, 183, 107],
  darkmagenta: [139, 0, 139],
  darkolivegreen: [85, 107, 47],
  darkorange: [255, 140, 0],
  darkorchid: [153, 50, 204],
  darkred: [139, 0, 0],
  darksalmon: [233, 150, 122],
  darkseagreen: [143, 188, 143],
  darkslateblue: [72, 61, 139],
  darkslategray: [47, 79, 79],
  darkslategrey: [47, 79, 79],
  darkturquoise: [0, 206, 209],
  darkviolet: [148, 0, 211],
  deeppink: [255, 20, 147],
  deepskyblue: [0, 191, 255],
  dimgray: [105, 105, 105],
  dimgrey: [105, 105, 105],
  dodgerblue: [30, 144, 255],
  firebrick: [178, 34, 34],
  floralwhite: [255, 250, 240],
  forestgreen: [34, 139, 34],
  fuchsia: [255, 0, 255],
  gainsboro: [220, 220, 220],
  ghostwhite: [248, 248, 255],
  gold: [255, 215, 0],
  goldenrod: [218, 165, 32],
  gray: [128, 128, 128],
  green: [0, 128, 0],
  greenyellow: [173, 255, 47],
  grey: [128, 128, 128],
  honeydew: [240, 255, 240],
  hotpink: [255, 105, 180],
  indianred: [205, 92, 92],
  indigo: [75, 0, 130],
  ivory: [255, 255, 240],
  khaki: [240, 230, 140],
  lavender: [230, 230, 250],
  lavenderblush: [255, 240, 245],
  lawngreen: [124, 252, 0],
  lemonchiffon: [255, 250, 205],
  lightblue: [173, 216, 230],
  lightcoral: [240, 128, 128],
  lightcyan: [224, 255, 255],
  lightgoldenrodyellow: [250, 250, 210],
  lightgray: [211, 211, 211],
  lightgreen: [144, 238, 144],
  lightgrey: [211, 211, 211],
  lightpink: [255, 182, 193],
  lightsalmon: [255, 160, 122],
  lightseagreen: [32, 178, 170],
  lightskyblue: [135, 206, 250],
  lightslategray: [119, 136, 153],
  lightslategrey: [119, 136, 153],
  lightsteelblue: [176, 196, 222],
  lightyellow: [255, 255, 224],
  lime: [0, 255, 0],
  limegreen: [50, 205, 50],
  linen: [250, 240, 230],
  magenta: [255, 0, 255],
  maroon: [128, 0, 0],
  mediumaquamarine: [102, 205, 170],
  mediumblue: [0, 0, 205],
  mediumorchid: [186, 85, 211],
  mediumpurple: [147, 112, 219],
  mediumseagreen: [60, 179, 113],
  mediumslateblue: [123, 104, 238],
  mediumspringgreen: [0, 250, 154],
  mediumturquoise: [72, 209, 204],
  mediumvioletred: [199, 21, 133],
  midnightblue: [25, 25, 112],
  mintcream: [245, 255, 250],
  mistyrose: [255, 228, 225],
  moccasin: [255, 228, 181],
  navajowhite: [255, 222, 173],
  navy: [0, 0, 128],
  oldlace: [253, 245, 230],
  olive: [128, 128, 0],
  olivedrab: [107, 142, 35],
  orange: [255, 165, 0],
  orangered: [255, 69, 0],
  orchid: [218, 112, 214],
  palegoldenrod: [238, 232, 170],
  palegreen: [152, 251, 152],
  paleturquoise: [175, 238, 238],
  palevioletred: [219, 112, 147],
  papayawhip: [255, 239, 213],
  peachpuff: [255, 218, 185],
  peru: [205, 133, 63],
  pink: [255, 192, 203],
  plum: [221, 160, 221],
  powderblue: [176, 224, 230],
  purple: [128, 0, 128],
  rebeccapurple: [102, 51, 153],
  red: [255, 0, 0],
  rosybrown: [188, 143, 143],
  royalblue: [65, 105, 225],
  saddlebrown: [139, 69, 19],
  salmon: [250, 128, 114],
  sandybrown: [244, 164, 96],
  seagreen: [46, 139, 87],
  seashell: [255, 245, 238],
  sienna: [160, 82, 45],
  silver: [192, 192, 192],
  skyblue: [135, 206, 235],
  slateblue: [106, 90, 205],
  slategray: [112, 128, 144],
  slategrey: [112, 128, 144],
  snow: [255, 250, 250],
  springgreen: [0, 255, 127],
  steelblue: [70, 130, 180],
  tan: [210, 180, 140],
  teal: [0, 128, 128],
  thistle: [216, 191, 216],
  tomato: [255, 99, 71],
  turquoise: [64, 224, 208],
  violet: [238, 130, 238],
  wheat: [245, 222, 179],
  white: [255, 255, 255],
  whitesmoke: [245, 245, 245],
  yellow: [255, 255, 0],
  yellowgreen: [154, 205, 50],
}
