import { ActionTypes } from "@/store/actionTypesEnums"
import { store } from "../store/store"

const untouch = (ref: object) => {
  Object.entries(ref).forEach(([key, val]) => {
    if (key.includes("form")) val.unTouched()
  })
}

const checkValidity = (ref: object) => {
  store.dispatch(ActionTypes.ClearValid)
  Object.entries(ref).forEach(([key, val]) => {
    if (key.includes("form") && val) val.validate()
  })
  return store.getters["validForm"]
}

const formError = function() {
  store.dispatch(ActionTypes.MessageFormError, "Form not valid")
}

const validStr = (value: string) => {
  let valid = true
  if (!value) valid = false
  if (value == "") valid = false
  return valid
}

const validPass = (value: string) => {
  if (!value) return false
  if (value == "") return false
  if (value.length < 6) return false
  return true
}

const validShortName = (value: string) => {
  if (!value) return false
  if (value == "") return false
  if (value.length != 3) {
    store.dispatch(ActionTypes.MessageFormError, "Short name should be 3 characters")
    return false
  }
  return true
}

const validSel = (value: string | number) => {
  if (value === 0) return true
  if (value == "") return false
  if (value == null) return false
  if (value == undefined) return false
  return true
}

const validNum = (value: number) => {
  if (!value) return false
  if (value < 0) return false
  return true
}

const validDate = (value: Date) => {
  let valid = true
  if (!value) valid = false
  return valid
}

export {
  validStr,
  validSel,
  validNum,
  validShortName,
  validDate,
  validPass,
  checkValidity,
  untouch,
  formError,
}
