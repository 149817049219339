<template>
  <span class="badge" :style="{ backgroundColor: bg }">
    <slot />
  </span>
</template>
<script>
export default {
  name: "Badge",
  props: ["bg"],
}
</script>
