import { ActionTree, MutationTree } from "vuex"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, RootState } from "../store"

export interface LocalModalsState {
  fermentable: boolean
  fermentableIndex: number | null
  kettle: boolean
  kettleIndex: number | null
  fermentation: boolean
  fermentationIndex: number | null
  tap: boolean
  tapContainerId: string | null
  selling: boolean
  sellingIndex: number | null
}

const state: LocalModalsState = {
  fermentable: false,
  fermentableIndex: null,
  kettle: false,
  kettleIndex: null,

  fermentation: false,
  fermentationIndex: null,

  tap: false,
  tapContainerId: null,

  selling: false,
  sellingIndex: null,
}

export type LocalModalMutations = {
  [MutationType.SetFermentable](state: LocalModalsState, payload: boolean): void
  [MutationType.SetFermentableIndex](state: LocalModalsState, payload: number | null): void
  [MutationType.SetKettle](state: LocalModalsState, payload: boolean): void
  [MutationType.SetKettleIndex](state: LocalModalsState, payload: number | null): void
  [MutationType.SetFermentation](state: LocalModalsState, payload: boolean): void
  [MutationType.SetFermentationIndex](state: LocalModalsState, payload: number | null): void
  [MutationType.SetSelling](state: LocalModalsState, payload: boolean): void
  [MutationType.SetSellingIndex](state: LocalModalsState, payload: number | null): void
  [MutationType.SetTap](state: LocalModalsState, payload: boolean): void
  [MutationType.SetTapContainerId](state: LocalModalsState, payload: string | null): void
}
const mutations: MutationTree<LocalModalsState> & LocalModalMutations = {
  [MutationType.SetFermentable](state, payload) {
    state.fermentable = payload
  },
  [MutationType.SetFermentableIndex](state, payload) {
    state.fermentableIndex = payload
  },
  [MutationType.SetKettle](state, payload) {
    state.kettle = payload
  },
  [MutationType.SetKettleIndex](state, payload) {
    state.kettleIndex = payload
  },
  [MutationType.SetFermentation](state, payload) {
    state.fermentation = payload
  },
  [MutationType.SetFermentationIndex](state, payload) {
    state.fermentationIndex = payload
  },
  [MutationType.SetSelling](state, payload) {
    state.selling = payload
  },
  [MutationType.SetSellingIndex](state, payload) {
    state.sellingIndex = payload
  },
  [MutationType.SetTap](state, payload) {
    state.tap = payload
  },
  [MutationType.SetTapContainerId](state, payload) {
    state.tapContainerId = payload
  },
}

export type LocalModalActions = {
  [ActionTypes.OpenLocalFermentable](context: ActionAugments, payload: number): void
  [ActionTypes.OpenLocalKettle](context: ActionAugments, payload: number): void
  [ActionTypes.OpenLocalFermentation](context: ActionAugments, payload: number): void
  [ActionTypes.OpenLocalSelling](context: ActionAugments, payload: number): void
  [ActionTypes.OpenLocalTap](context: ActionAugments, payload: string): void
  [ActionTypes.CloseLocal](context: ActionAugments): void
}

const actions: ActionTree<RootState, RootState> & LocalModalActions = {
  [ActionTypes.OpenLocalFermentable]({ commit }, payload) {
    commit(MutationType.SetFermentable, true)
    commit(MutationType.SetFermentableIndex, payload)
  },
  [ActionTypes.OpenLocalKettle]({ commit }, payload) {
    commit(MutationType.SetKettle, true)
    commit(MutationType.SetKettleIndex, payload)
  },
  [ActionTypes.OpenLocalFermentation]({ commit }, payload) {
    commit(MutationType.SetFermentation, true)
    commit(MutationType.SetFermentationIndex, payload)
  },
  [ActionTypes.OpenLocalSelling]({ commit }, payload) {
    commit(MutationType.SetSelling, true)
    commit(MutationType.SetSellingIndex, payload)
  },
  [ActionTypes.OpenLocalTap]({ commit }, payload) {
    commit(MutationType.SetTap, true)
    commit(MutationType.SetTapContainerId, payload)
  },
  [ActionTypes.CloseLocal]({ commit }) {
    commit(MutationType.SetKettle, false)
    commit(MutationType.SetKettleIndex, null)
    commit(MutationType.SetKettle, false)
    commit(MutationType.SetKettleIndex, null)
    commit(MutationType.SetFermentable, false)
    commit(MutationType.SetFermentableIndex, null)
    commit(MutationType.SetSelling, false)
    commit(MutationType.SetSellingIndex, null)
    commit(MutationType.SetTap, false)
    commit(MutationType.SetTapContainerId, null)
    commit(MutationType.SetFermentation, false)
    commit(MutationType.SetFermentationIndex, null)
  },
}
const getters = {
  // getFermentable: (state: LocalModalsState) => {
  //   return state.fermentable
  // },
  // getFermentableIndex: (state: LocalModalsState) => {
  //   return state.fermentableIndex
  // },
  // getKettle: (state: LocalModalsState) => {
  //   return state.kettle
  // },
  // getKettleIndex: (state: LocalModalsState) => {
  //   return state.kettleIndex
  // },
  // getFermentation: (state: LocalModalsState) => {
  //   return state.fermentation
  // },
  // getFermentationIndex: (state: LocalModalsState) => {
  //   return state.fermentationIndex
  // },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
