<template>
  <small class="text-muted">
    <slot></slot>
  </small>
</template>

<script>
export default {
  name: "Small",
}
</script>
