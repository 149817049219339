import { base, formatCount } from "@/helper/helper"
import { BREW, TIME_FORMAT, UNIT } from "@/services/enums"
import { Brew, Ingredient, PopBrew, PopIngredient, RecipeItem } from "@/typings/types"
import { addDays, format } from "date-fns"
import { cloneDeep } from "lodash"
import { GetterTree } from "vuex"
import { RootState, useStore } from "../store"
import { popRecipeObj } from "./recipes"

//Getter type
export type BrewGetters = {
  brew(state: RootState): (id: string) => Brew
  popBrew(state: RootState): (id: string) => PopBrew
  popBrews(state: RootState): PopBrew[]
}

//Local Functions
const getTotal = (container: any, orderIds: string[]) => {
  const inventories = orderIds
    .map(orderId => useStore().state.collections.orders[orderId].inventory)
    .flat()
  const used = inventories
    .filter(item => item.containerId == container.id)
    .reduce((acc, { quantity }) => (acc += quantity), 0)

  return container.quantity - used
}

const getBrewIngredients = (id: string) => {
  const recipe = cloneDeep(useStore().state.collections.brews[id].recipeCopy)

  let ingredients = recipe
    ? ([...recipe.mashing.items, ...recipe.kettle.items, ...recipe.fermentation.items] as any)
    : []

  ingredients = ingredients.map((item: RecipeItem) => {
    return {
      ...useStore().getters.popIngredient(item.id),
      ...item,
    }
  })

  return ingredients as PopIngredient[]
}

//Getter
export const brewsGetters: GetterTree<RootState, RootState> & BrewGetters = {
  brew: state => id => {
    let brew = cloneDeep(state.collections.brews[id])
    brew = base(brew)
    return brew
  },

  popBrew: state => id => {
    const brew = useStore().getters.brew(id) as PopBrew

    brew["popRecipe"] = popRecipeObj(brew.recipeCopy)

    // brew["createdBy"] = rootState.users.users[brew.userCreated]
    //   ? state.users.users[brew.userCreated].name
    //   : "-"

    // brew["editedBy"] = state.users.users[brew.userUpdated]
    //   ? state.users.users[brew.userUpdated].name
    //   : "-"
    brew["mashingProfile"] = brew.recipeCopy.mashing.profileId
      ? state.collections.profiles[brew.recipeCopy.mashing.profileId]
      : undefined
    brew["fermentationProfile"] = brew.recipeCopy.fermentation.profileId
      ? state.collections.profiles[brew.recipeCopy.fermentation.profileId]
      : undefined
    brew["recipeName"] = brew.recipeCopy.name
    brew["stateName"] = BREW.TEXT[brew.state]
    brew["styleColor"] = state.collections.styles[brew.recipeCopy.styleId].color
    brew["styleName"] = state.collections.styles[brew.recipeCopy.styleId].name
    brew["formatCount"] = formatCount(brew.count)
    brew["tankUnit"] = UNIT.TEXT[state.collections.tanks[brew.tankId].unit]
    brew["tankSize"] = state.collections.tanks[brew.tankId].size
    brew["tankNameShort"] = state.collections.tanks[brew.tankId].short
    brew["tankName"] = state.collections.tanks[brew.tankId].name
    brew["ingredients"] = getBrewIngredients(id)
    brew["containers"] = brew.containers.map(item => ({
      brewTotal: getTotal(item, brew.orderIds),
      ...item,
      ...useStore().getters.popContainer(item.id),
    })) as any
    brew["fermentationTime"] = brew.recipeCopy.fermentation.profileId
      ? state.collections.profiles[brew.recipeCopy.fermentation.profileId].totalTime
      : 0
    brew["end"] = brew.recipeCopy.fermentation.profileId
      ? addDays(
          brew.start.toDate(),
          state.collections.profiles[brew.recipeCopy.fermentation.profileId].totalTime
        )
      : undefined
    brew["startText"] = brew.start ? format(brew.start.toDate(), TIME_FORMAT.DATE) : ""
    brew["endText"] = brew.end ? format(brew.end, TIME_FORMAT.DATE) : ""

    return brew
  },

  popBrews: state => {
    let brews = Object.values(cloneDeep(state.collections.brews)) as PopBrew[]
    brews = brews.map(item => useStore().getters.popBrew(item.id))
    return brews
  },
}
