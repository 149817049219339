<template>
  <ModelContainer @click.self="closeProfile()">
    <ModalContent>
      <ModalHeader>
        Profile Form
        <template #right>
          <Button @click="closeProfile()">close</Button>
        </template>
      </ModalHeader>
      <ProfileGraph :chartId="'profileChart'" :items="profile.items" />
      <ProfileItems v-model:items="profile.items" :unit="unit" />
      <Row>
        <Lable class="col-6">Name tempeture profile *</Lable>
      </Row>
      <Row>
        <BCol>
          <FormInput ref="formName" type="text" placeholder="Name" v-model="profile.name" />
        </BCol>
        <BCol>
          <FormSelect
            ref="formType"
            :items="PROFILE.TEXT"
            :strCallback="item => item"
            v-model="profile.type"
          />
        </BCol>
        <Button class="col-2 mx-1" lg="true" @click="save()">Save</Button>
      </Row>
    </ModalContent>
  </ModelContainer>
</template>

<script>
import { initProfile } from "../../services/objects"
import { apiSetProfile } from "../../services/api"
import { mapActions } from "vuex"
import cloneDeep from "clone-deep"
import { checkValidity, formError } from "../../services/validation"
import { PROFILE, TIME } from "../../services/enums"

import comp from "../base"
import ModalContent from "./ModalContent.vue"
import ModelContainer from "./ModalContainer.vue"
import ModalHeader from "./ModalHeader.vue"
import ProfileItems from "../recipe/ProfileItems.vue"
import ProfileGraph from "../recipe/ProfileGraph.vue"
import { useStore } from "@/store/store"

export default {
  name: "ProfileForm",
  props: ["id"],
  components: {
    ...comp,
    ModalContent,
    ModelContainer,
    ModalHeader,
    ProfileItems,
    ProfileGraph,
  },
  setup() {
    return {
      PROFILE,
    }
  },
  data() {
    return {
      profile: null,
      name: null,
    }
  },
  created() {
    this.profile = this.id
      ? cloneDeep(useStore().state.collections.profiles[this.id])
      : cloneDeep(initProfile)
  },
  computed: {
    unit() {
      if (this.profile.type == PROFILE.MASHING) return TIME.TEXT[TIME.MINUTE]
      else return TIME.TEXT[TIME.DAY]
    },
    totalTime() {
      return this.profile.items.reduce((acc, item) => (acc += item.time), 0)
    },
  },
  methods: {
    ...mapActions(["closeProfile"]),
    save() {
      if (checkValidity(this.$refs)) {
        this.profile.totalTime = this.totalTime
        apiSetProfile(this.profile)
        // this.profile = cloneDeep(initProfile)
        this.closeProfile()
      } else formError()
    },
  },
}
</script>
