<template>
  <span v-if="time">
    <div class="small text-muted">
      <i>
        {{ text }}
      </i>
    </div>
    <div class="small">
      {{ humanTime }}
    </div>
  </span>
</template>

<script>
import firebase from "firebase/app"

import { format } from "date-fns"
export default {
  name: "BTime",
  props: ["time", "text"],
  data: function() {
    return {}
  },
  computed: {
    date() {
      let time
      if (this.time instanceof Date) time = this.time
      else if (this.time instanceof firebase.firestore.Timestamp) time = this.time.toDate()
      return time
    },
    humanTime: function() {
      return this.date instanceof Date && !isNaN(this.date) ? format(this.date, "dd/MM/yyyy") : ""
    },
  },
}
</script>
