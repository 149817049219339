<template>
  <th scope="col">
    <slot />
  </th>
</template>

<script>
export default {
  name: "TH",
}
</script>
