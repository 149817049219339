<template>
  <ModelContainer @click.self="closeBrew()">
    <ModalContent>
      <ModalHeader>
        Brew Form
        <template #right>
          <Button @click="closeBrew()">close</Button>
        </template>
      </ModalHeader>

      <TimeChart :form="brew" />

      <Row>
        <BCol class="col-8">
          <Lable>
            recipe * - Once saved, the recipe is copyd to the brew. Updating the recipe in the
            future will not affect this brew!
            <template #right>
              <BadgeBtn @click="openRecipe()"> + Recipe</BadgeBtn>
            </template>
          </Lable>

          <FormSelect
            v-if="!brew.created"
            ref="formRecipeName"
            :items="recipes"
            :modelValue="brew.recipeCopy ? brew.recipeCopy.id : null"
            :valueCallback="item => item.id"
            :strCallback="item => item.name"
            @update:modelValue="val => setRecipe(val)"
          />
          <input
            v-else
            class="form-control"
            type="text"
            :placeholder="brew.recipeCopy.name"
            disabled
          />
        </BCol>
        <BCol>
          <Lable class="col">Brew State *</Lable>
          <FormSelect
            ref="formState"
            :strCallback="item => item"
            :items="BREW.TEXT"
            v-model.number="brew.state"
          />
        </BCol>
      </Row>

      <Row>
        <BCol>
          <Lable class="col">
            Tank *
            <template #right>
              <BadgeBtn @click="openTank()"> + Tank</BadgeBtn>
            </template>
          </Lable>

          <FormSelect
            ref="formFermentTank"
            :items="fermentTanks"
            v-model="brew.tankId"
            :valueCallback="item => item.id"
          />
        </BCol>
        <BCol>
          <Lable class="col">Brewing Start Date *</Lable>
          <FormGroup>
            <FormDate ref="formDate" v-model="brew.start" />
            <FormSpan v-if="brew.recipeCopy">{{ fermentationDays }} days fermentaion</FormSpan>
            <FormSpan v-if="endDate"> end on : {{ format(endDate, "dd/MM/yyyy") }}</FormSpan>
          </FormGroup>
        </BCol>
      </Row>

      <Row class="mt-4">
        <Button lg="true" class="col" @click="saveBrew()">Save Brew</Button>
      </Row>
    </ModalContent>
  </ModelContainer>
</template>

<script>
import TimeChart from "../planning/TimeChart.vue"
// import BrewRecipe from "../components/brew/BrewRecipe.vue"
import ModalContent from "./ModalContent.vue"
import ModelContainer from "./ModalContainer.vue"
import ModalHeader from "./ModalHeader.vue"
import comp from "../base"
import { initBrew } from "@/services/objects"
import { apiSetBrew } from "../../services/api"
import { mapActions } from "vuex"
import { BREW, TANK } from "@/services/enums"
import cloneDeep from "clone-deep"
import { format, addDays } from "date-fns"
import { checkValidity, formError, validNum } from "../../services/validation"
import { computed } from "@vue/runtime-core"
import { useStore } from "@/store/store"
import { handleDate } from "@/helper/helper"
// import { useStore } from "@/store/store"
// import { computed } from '@vue/runtime-core'

export default {
  name: "BrewForm",
  props: ["id"],
  components: {
    ...comp,
    TimeChart,
    // BrewRecipe,
    ModalContent,
    ModelContainer,
    ModalHeader,
  },
  setup() {
    return {
      BREW,
      TANK,
      format,
      validNum,
      tanks: computed(() => useStore().state.collections.tanks),
      recipes: computed(() => useStore().state.collections.recipes),
      profiles: computed(() => useStore().state.collections.profiles),
    }
  },
  data() {
    return {
      brew: {},
    }
  },
  created() {
    this.brew = this.id
      ? cloneDeep(useStore().state.collections.brews[this.id])
      : cloneDeep(initBrew)
  },
  computed: {
    endDate() {
      return this.brew.recipeCopy && this.brew.start && !isNaN(this.brew.start)
        ? addDays(handleDate(this.brew.start), this.fermentationDays)
        : null
    },
    fermentTanks() {
      return this.tanks
        ? Object.values(this.tanks).filter(item => item.type == TANK.FERMENTATION)
        : []
    },
    fermentationDays() {
      let profileId = this.brew.recipeCopy.fermentation.profileId
      return profileId ? this.profiles[profileId].totalTime : ""
    },
  },
  methods: {
    ...mapActions(["openRecipe", "openTank", "closeBrew"]),

    setRecipe(recipeId) {
      this.brew.recipeCopy = cloneDeep(this.recipes[recipeId])
    },
    saveBrew: function() {
      if (checkValidity(this.$refs)) {
        apiSetBrew(this.brew)
        this.closeBrew()
      } else formError()
    },
  },
}
</script>
