<script>
export default {
  name: "SubHeader",
}
</script>

<template>
  <div class=" d-flex justify-content-between align-items-end">
    <div class="fw-bold fs-5">
      <slot />
    </div>
    <div class="text-muted fs-6">
      <slot name="right" />
    </div>
  </div>
</template>
<style></style>
