<script>
export default {
  name: "List",
}
</script>

<template>
  <div class="d-flex justify-content-between my-1">
    <div class="stor text-muted text-uppercase">
      <slot />
    </div>
    <div class="">
      <slot name="right" />
    </div>
  </div>
</template>

<style>
.stor {
  font-weight: 500;
}
</style>
