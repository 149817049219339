<template>
  <ModelContainer @click.self="closeStyle()">
    <ModalContent>
      <ModalHeader>
        Style Form
        <template #right>
          <Button @click="closeStyle()">close</Button>
        </template>
      </ModalHeader>
      <Row>
        <Lable class="col-6">"Name your Brew Style*</Lable>
      </Row>
      <Row>
        <BCol class="col-6">
          <FormInput ref="formName" type="text" placeholder="Style" v-model="style.name" />
        </BCol>
        <Button
          class="col mx-1"
          :style="{ backgroundColor: style.color }"
          lg="true"
          @click="getColor()"
          >New Color</Button
        >
        <Button class="col mx-1" lg="true" @click="addStyle()">Save Style</Button>
      </Row>
    </ModalContent>
  </ModelContainer>
</template>

<script>
import { initStyle } from "@/services/objects"
import { apiSetStyle } from "@/services/api"
// import uniqolor from "uniqolor"

import { mapActions } from "vuex"
import cloneDeep from "clone-deep"
import { checkValidity, formError } from "../../services/validation"

import comp from "@/components/base"
import ModalContent from "./ModalContent.vue"
import ModelContainer from "./ModalContainer.vue"
import ModalHeader from "./ModalHeader.vue"
import { generateStyleColor } from "../../helper/helper"
import { useStore } from "@/store/store"

export default {
  name: "StyleForm",
  props: ["id"],
  components: {
    ...comp,
    ModalContent,
    ModelContainer,
    ModalHeader,
  },
  data() {
    return {
      // id: this.$route.params.id,
      valid: null,
      style: null,
    }
  },
  created() {
    this.style = this.id
      ? cloneDeep(useStore().state.collections.styles[this.id])
      : cloneDeep(initStyle)
  },
  // computed: {
  //   ...mapState(["styles"]),
  // },
  methods: {
    ...mapActions(["closeStyle"]),
    addStyle() {
      if (checkValidity(this.$refs)) {
        apiSetStyle(this.style)
        this.style = cloneDeep(initStyle)
        this.closeStyle()
      } else formError()
    },
    getColor() {
      this.style.color = generateStyleColor()
    },
  },
}
</script>
