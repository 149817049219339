<template>
  <div class="input-group input-group-sm">
    <select
      class="form-select "
      :class="{
        'is-invalid': valid === false && enable && touched,
        'is-valid': valid === true && enable && touched,
      }"
      :value="modelValue"
      @input="val => emit(val.target.value)"
      :disabled="!enable"
    >
      <option value="" selected disabled hidden>Choose</option>
      <option :value="valueCallback(item, index)" v-for="(item, index) in items" :key="index">
        {{ strCallback(item) }}
      </option>
    </select>
    <button
      @click="togge()"
      class="btn "
      :class="enable ? 'btn-outline-secondary' : 'btn-secondary'"
      type="button"
    >
      <IconSave v-if="enable" />
      <IconEdit v-else />
      <!-- {{ enable ? "lock" : "set" }} -->
    </button>
  </div>
</template>

<script>
import icon from "../../assets/icons"
import { validSel } from "../../services/validation"
import { store } from "../../store/store"

const valueCallback = function(item, index) {
  return index
}

const strCallback = function(item) {
  return item.name
}

export default {
  name: "FormSelectEnableable",
  props: {
    modelValue: [Object, Array, String, Number],
    items: [Object, Array],
    validateion: {
      type: Function,
      default: validSel,
    },
    valueCallback: {
      type: Function,
      default: valueCallback,
    },
    strCallback: {
      type: Function,
      default: strCallback,
    },
  },
  emits: ["update:modelValue", "lock"],
  components: {
    ...icon,
  },
  data() {
    return {
      enable: false,
      touched: false,
    }
  },
  computed: {
    valid() {
      return this.validateion(this.modelValue)
    },
  },
  methods: {
    emit(value) {
      this.touched = true
      this.$emit("update:modelValue", value)
    },
    togge() {
      if (this.valid != false) {
        this.enable = !this.enable
        if (!this.enable) this.$emit("lock")
      }
    },
    validate() {
      this.touched = true
      store.dispatch("pushValid", this.validateion(this.modelValue))
    },
    unTouched() {
      this.touched = false
    },
  },
  mounted() {
    this.enable = this.modelValue ? false : true
  },
}
</script>
