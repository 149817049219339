import { User } from "@/typings/types"
import { ActionContext, ActionTree, Getter, GetterTree, Module, MutationTree } from "vuex"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, RootState, store } from "../store"

export type UpdateTrackerState = {
  updatedByUid: string
  updatedItemId: string
}

const state: UpdateTrackerState = {
  updatedByUid: "",
  updatedItemId: "",
}

export type UpdateTrackerMutations = {
  [MutationType.SetUpdatedByUid](state: UpdateTrackerState, payload: string): void
  [MutationType.SetUpdatedItemId](state: UpdateTrackerState, payload: string): void
}

const mutations: MutationTree<UpdateTrackerState> & UpdateTrackerMutations = {
  [MutationType.SetUpdatedByUid](state, payload) {
    state.updatedByUid = payload
  },
  [MutationType.SetUpdatedItemId](state, payload) {
    state.updatedItemId = payload
  },
}

export type UpdateTrackerActions = {
  [ActionTypes.SetUpdateTrackerUid](context: ActionAugments, payload: string): void
  [ActionTypes.SetUpdateTrackerItemId](context: ActionAugments, payload: string): void
}

const actions: ActionTree<RootState, RootState> & UpdateTrackerActions = {
  [ActionTypes.SetUpdateTrackerUid]({ commit }, payload) {
    commit(MutationType.SetUpdatedByUid, payload)
  },
  [ActionTypes.SetUpdateTrackerItemId]({ commit }, payload) {
    commit(MutationType.SetUpdatedItemId, payload)
  },
}

export type UpdateTrackerGetters = {
  updateUser(state: UpdateTrackerState, getters: any, rootState: RootState): string
}

const getters: GetterTree<UpdateTrackerState, RootState> & UpdateTrackerGetters = {
  updateUser: (state, getters, rootState) => {
    const user = rootState.users.users[state.updatedByUid] as User
    return user ? user.name : ""
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
