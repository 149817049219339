<template>
  <select
    class="form-select "
    :value="modelValue"
    :class="{
      'is-valid': valid && touched,
      'is-invalid': !valid && touched,
    }"
    @input="emitInput($event.target.value)"
  >
    <option value="" selected disabled hidden>Choose</option>
    <option :value="valueCallback(item, index)" v-for="(item, index) in items" :key="index"
      >{{ strCallback(item) }}
    </option>
  </select>
</template>

<script>
import { validSel } from "../../services/validation"
import { store } from "../../store/store"

const valueCallback = function(item, index) {
  return index
}

const strCallback = function(item) {
  return item.name
}

export default {
  name: "FormSelectLable",
  props: {
    modelValue: [Object, Array, String, Number],
    items: [Object, Array],
    validateion: {
      type: Function,
      default: validSel,
    },
    valueCallback: {
      type: Function,
      default: valueCallback,
    },
    strCallback: {
      type: Function,
      default: strCallback,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      touched: false,
    }
  },
  computed: {
    valid() {
      return this.validateion(this.modelValue)
    },
  },
  methods: {
    emitInput(value) {
      this.touched = true
      this.$emit("update:modelValue", value)
    },
    validate() {
      this.touched = true
      store.dispatch("pushValid", this.validateion(this.modelValue))
    },
    unTouched() {
      this.touched = false
      this.$emit("update:modelValue", null)
    },
  },
}
</script>
