<template>
  <!-- <div> -->
  <div class="input-group">
    <slot />
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "FormGroup",
}
</script>
