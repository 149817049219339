import { base } from "@/helper/helper"
import { CATEGORY, UNIT } from "@/services/enums"
import { Ingredient, PopIngredient } from "@/typings/types"
import { cloneDeep } from "lodash"
import { GetterTree } from "vuex"
import { RootState, useStore } from "../store"

//Types
export type IngredientGetters = {
  ingredient(state: RootState): (id: string) => Ingredient
  popIngredient(state: RootState): (id: string) => PopIngredient
  popIngredients(state: RootState): PopIngredient[]
}

//Local Functions
const getTotal = (ingredient: PopIngredient) => {
  const sum = ingredient.inventory.reduce((acc, { quantity }) => (acc += quantity), 0)

  const brews = useStore().state.collections.brews
  const ingredients = ingredient.brewIds
    .map(brewId => [
      ...brews[brewId].recipeCopy.mashing.items,
      ...brews[brewId].recipeCopy.kettle.items,
      ...brews[brewId].recipeCopy.fermentation.items,
    ])
    .flat()

  const used = ingredients
    .filter(({ id }) => id == ingredient.id)
    .reduce((acc, { quantity }) => (acc += quantity), 0)

  return sum - used
}

//Getters
export const ingredientsGetters: GetterTree<RootState, RootState> & IngredientGetters = {
  ingredient: state => id => {
    let ingredient = cloneDeep(state.collections.ingredients[id])
    ingredient = base(ingredient)
    return ingredient
  },

  popIngredient: state => id => {
    const ingredient = useStore().getters.ingredient(id) as PopIngredient

    ingredient["unitText"] = UNIT.TEXT[ingredient.unit]
    ingredient["color"] = CATEGORY.COLOR[ingredient.category]
    ingredient["categoryName"] = CATEGORY.TEXT[ingredient.category]
    ingredient["supplierName"] = state.collections.suppliers[ingredient.supplierId].name
    ingredient["total"] = getTotal(ingredient)
    // ingredient["createdDate"] = format(ingredient.created.toDate(), TIME_FORMAT.TIME)
    // ingredient.inventory.map(
    //   item => (item["createdDate"] = format(item.created.toDate(), TIME_FORMAT.TIME))
    // )
    return ingredient
  },

  // },
  popIngredients: state => {
    let ingredients = Object.values(cloneDeep(state.collections.ingredients))
    ingredients = ingredients.map(item => useStore().getters.popIngredient(item.id))
    return ingredients
  },
}
