<template>
  <div>
    <div class="row g-0 text-center">
      <div class="col-2 ">
        <BIconArrowLeftShort role="button" @click="previousBrewStateById(brew.id)" class="icon" />
      </div>
      <div class="col">
        {{ brew.stateName }}
      </div>
      <div class="col-2">
        <BIconArrowRightShort role="button" @click="nextBrewStateById(brew.id)" class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from "clone-deep"
import icons from "../../assets/icons"
import { apiSetBrew } from "../../services/api"
import { BREW } from "../../services/enums"
import { useStore } from "@/store/store"

export default {
  name: "BState",
  props: ["brew"],
  components: {
    ...icons,
  },
  methods: {
    nextBrewStateById(id) {
      if (id) {
        let brew = cloneDeep(useStore().state.collections.brews[id])
        brew.state++
        if (brew.state >= BREW.TEXT.length) brew.state = BREW.TEXT.length - 1
        apiSetBrew(brew)
      }
    },
    previousBrewStateById(id) {
      if (id) {
        let brew = cloneDeep(useStore().state.collections.brews[id])
        brew.state--
        if (brew.state < 0) brew.state = 0
        apiSetBrew(brew)
      }
    },
  },
}
</script>
