<template>
  <div class="b-body">
    <div class="m-5" />
    <div class="d-flex small text-muted p-0">
      <i>
        <slot name="lable" />
      </i>
    </div>
    <div class="large-text">
      <slot name="large" />
    </div>
    <hr class="line my-1" />
    <div class="my-2 d-flex flex-wrap">
      <slot name="items" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BBody",
}
</script>
