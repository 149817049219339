<template>
  <hr class="m-0 p-0" />
  <div class="row">
    <div class="col-2 h4 my-1">
      {{ tanks[tankId].short }}
    </div>
    <div class="col tankContainer d-flex align-items-center">
      <slot />
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core"
import { useStore } from "@/store/store"
export default {
  name: "TankRow",
  props: ["tankId"],
  setup() {
    return {
      tanks: computed(() => useStore().state.collections.tanks),
    }
  },
}
</script>

<style scoped>
.tankContainer {
  position: relative;
  overflow: hidden;
}
</style>
