<template>
  <div class="boxCardSide p-3 mb-3">
    <slot />
  </div>
</template>

<script>
export default {
  name: "CardContainer",
}
</script>

<style scoped>
.boxCardSide {
  border-top: 5px solid black;
  background-color: rgb(245, 245, 245);
}
</style>
