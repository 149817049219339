import { PopKettleItem, PopMashingItem } from "@/typings/types"
import { srmColors } from "../consts/srmColors"

//  ((1,05*(StartSG-slutSG))/slutSG]/(0,79*100)
export const abv = (og: number, fg: number) => {
  // if (og && fg) {
  const abv = ((1.05 * (og - fg)) / fg / 0.79) * 100
  const abvRound = Math.round(abv * 100) / 100
  return abvRound
  // }
}

export const evaporation = (boilTime: number | undefined, evaporation: number | undefined) => {
  if (boilTime && evaporation) {
    const evaporated = (boilTime / 60) * evaporation
    const roundEvaporated = Math.round(evaporated * 10) / 10
    return roundEvaporated
  }
}

// Example: Your beer has an OG of 1.050 and the expected attenuation of your yeast is 75%.
//The yeast will ferment 75% of the gravity sample
//(75% of 50 gravity points is roughly 38 gravity points)
//so your final gravity should be around 1.012 (50 -38 = 12).
//https://www.northernbrewer.com/blogs/new-to-brewing-start-here/attenuation

export const tfg = (og: number, attenuation: number) => {
  //units: kg / m3
  const wortPart = og - 1000
  const tfg = wortPart - wortPart * (attenuation / 100) + 1000

  const roundTfg = Math.round(tfg * 10) / 10
  return roundTfg
}

//http://beersmith.com/blog/2015/01/30/calculating-original-gravity-for-beer-recipe-design/
export const tog = (ingredient: PopMashingItem[], batchSize: number, efficiency: number) => {
  // 1 litter = 2.20462262 lb
  // 1 gallon = 3.78541178 liter
  const ImperialToMetric = 2.20462262 * 3.78541178

  //get point from all malt
  const points = ingredient.reduce((acc, item) => (acc += item.ppg * item.quantity), 0)

  //compensating for brewery
  const effectivePoint = points * (efficiency / 100)

  //points lb / gallons => points kg / l
  const tog = (effectivePoint / batchSize) * ImperialToMetric + 1000

  const roundTog = Math.round(tog * 10) / 10

  return roundTog
}

export const ebc = (ingredient: PopMashingItem[], efficiency: number, batchSize: number) => {
  //Σ quantity [kg] * ebc
  const totalEbc = ingredient.reduce((acc, item) => (acc += item.quantity * item.ebc), 0)
  //Mash efficiency [%] * totalEbc * 10
  const efficientEbc = totalEbc * (efficiency / 100) * 10
  // efficientEbc / size [l]
  const finalEbc = efficientEbc / batchSize

  const roundEbs = Math.round(finalEbc * 10) / 10

  return roundEbs
}

export const srm = (ebc: number) => {
  const srm = ebc * 0.508
  const roundSrm = Math.round(srm * 10) / 10
  return roundSrm
}

export const srmColor = (srm: number) => {
  let roundSrm = Math.round(srm * 10) / 10
  roundSrm = roundSrm < 0.1 ? 0.1 : roundSrm
  roundSrm = roundSrm > 40 ? 40 : roundSrm

  const item = srmColors.find(item => item.SRM == roundSrm)
  const color = item ? item.RGB : ""

  return color
}

export const plato = (sg: number) => {
  // https://www.brewersfriend.com/plato-to-sg-conversion-chart/
  const plato = -616.868 + 1111.14 * sg - 630.272 * sg ** 2 + 135.997 * sg ** 3

  const roundPlato = Math.round(plato * 10) / 10

  return roundPlato
}

/////////////
// IBU //////
/////////////

// Tinseth
//http://realbeer.com/hops/research.html
export const ibuTinseth = (og: number, batchSize: number, ingredients: PopKettleItem[]) => {
  const utilization = (boilTime: number) => {
    const bignessFactor = 1.65 * 0.000125 ** (og - 1)
    const boilTimeFactor = (1 - Math.E ** (-0.04 * boilTime)) / 4.15
    return bignessFactor * boilTimeFactor
  }

  const ibu = ingredients.reduce((acc, item) => {
    const quantityMg = item.quantity * 10 ** 6
    const aaDecimal = item.aa / 100
    const aaMgPrL = (quantityMg * aaDecimal) / batchSize
    return (acc += aaMgPrL * utilization(item.time))
  }, 0)

  const roundIbu = Math.round(ibu * 10) / 10
  return roundIbu
}

////////////////////////////
// WATER DENSITY ///////////
////////////////////////////

// The Kell formulation
// valid from 0 to 150 °C
// 5°C => 0.999961
// 20°C =>	0.998201

export const waterDensityKell = (t: number) => {
  const first = 999.83952 + 16.945176 * t
  const secund = -7.9870401 * 10 ** -3 * t ** 2
  const third = -46.170461 * 10 ** -6 * t ** 3
  const fourth = 105.56302 * 10 ** -9 * t ** 4
  const fifth = -280.54253 * 10 ** -12 * t ** 5
  const divider = 1 + 16.89785 * 10 ** -3 * t
  const ρ = (first + secund + third + fourth + fifth) / divider
  return ρ / 1000
}

// http://www.axeleratio.com/calc/water_density/form/Kell_equation.htm

// 5°C => 999.964
// 20°C => 998.204
// 50°C => 988.036
// 80°C => 971.798
// 100°C => 958.364

export const waterDensity = (t: number) => {
  const a = -2.8054253 * 10 ** -10
  const b = 1.0556302 * 10 ** -7
  const c = -4.6170461 * 10 ** -5
  const d = 0.0079870401
  const e = 16.945176
  const f = 999.83952
  const g = 0.01687985

  const ρ = (((((a * t + b) * t + c) * t + d) * t + e) * t + f) / (1 + g * t)
  return 1 / (ρ / 1000)
}

////////////////////////////
// TODO ////////////////////
////////////////////////////

// https://homebrewacademy.com/ibu-calculator/
// https://help.grainfather.com/hc/en-us/articles/360014527537-Calculation-IBU

// https://alchemyoverlord.wordpress.com/2015/05/12/a-modified-ibu-measurement-especially-for-late-hopping/

// the following code assumes we know the boil gravity (BG), post-boil volume
// (volume_gallons, in gallons), the weight of hops added (hopsWeight_oz,
// in ounces), the AA value of the hops (AA, in percent, with values from
// 0 to 100), the time of the hops in the boil (boilTime_min, in minutes),
// when (relative to flameout) there is forced cooling (coolTime_min, in
// minutes), the kettle diameter (kettleDiameter_inches, in inches), and
// the kettle opening diameter (openingDiameter_inches, in inches).
// The forced cooling is assumed here to be instantaneous.

// volume_liters = volume_gallons * 3.78541;
// hopsWeight_grams = hopsWeight_oz * 28.3495;
// kettleDiameter_cm = kettleDiameter_inches * 2.54;
// openingDiameter_cm = openingDiameter_inches * 2.54;
// AA_maxOne = AA / 100.0;
// boilUtilization = computeBoilUtilization(boilTime_min, BG);
// postBoilUtilization = computePostBoilUtilization(boilTime_min, BG,
//            coolTime_min, volume_liters, kettleDiameter_cm,
//            openingDiameter_cm);
// totalUtilization = boilUtilization + postBoilUtilization;
// IBU = (totalUtilization * AA_maxOne * hopsWeight_grams * 1000.0) /
//        volume_liters;
// print("total IBUs, including post-flameout, is %f\n", IBU);

// procedure computeBoilUtilization(boilTime_min, BG) {
//   bignessFactor = 1.65 * pow(0.000125, (BG-1.0));
//   boilTimeFactor = (1.0 - exp(-0.04 * boilTime_min)) / 4.15;
//   decimalAArating = bignessFactor * boilTimeFactor;
//   return(decimalAArating);
// }

// procedure computePostBoilUtilization(boilTime_min, BG, coolTime_min,
//                volume_liters, kettleDiameter_cm, openingDiameter_cm) {
//   integrationTime = 0.001;
//   decimalAArating = 0.0;
//   for (t = boilTime_min; t < boilTime_min + coolTime_min; t = t + integrationTime) {
//     dU = -1.65 * pow(0.000125, (BG-1.0)) * -0.04 * exp(-0.04*t) / 4.15;
//     surfaceArea_cm2 = 3.14159 * (kettleDiameter_cm/2.0) * (kettleDiameter_cm/2.0);
//     openingArea_cm2 = 3.14159 * (openingDiameter_cm/2.0) * (openingDiameter_cm/2.0);
//     effectiveArea_cm2 = sqrt(surfaceArea_cm2 * openingArea_cm2);
//     b = (0.0002925 * effectiveArea_cm2 / volume_liters) + 0.00538;
//     temp_degK = 53.70 * exp(-1.0 * b * (t - boilTime_min)) + 319.55;
//     degreeOfUtilization = 2.39*pow(10.0,11.0)*exp(-9773.0/temp_degK);
//     if (t < 5.0) degreeOfUtilization = 1.0;  // account for nonIAA components
//     combinedValue = dU * degreeOfUtilization;
//     decimalAArating = decimalAArating + (combinedValue * integrationTime);
//   }
//   return(decimalAArating);
// }
