import {
  abv,
  ebc,
  evaporation,
  ibuTinseth,
  plato,
  srm,
  srmColor,
  tfg,
  tog,
} from "@/helper/calculate"
import { base } from "@/helper/helper"
import {
  PopFermentationItem,
  PopKettleItem,
  PopMashingItem,
  PopRecipe,
  Recipe,
  RecipeItem,
} from "@/typings/types"
import { cloneDeep } from "lodash"
import { GetterTree } from "vuex"
import { RootState, useStore } from "../store"

//Types
export type RecipesGetters = {
  recipe(state: RootState): (id: string) => Recipe
  popRecipe(state: RootState): (id: string) => PopRecipe
  popRecipes(state: RootState): PopRecipe[]
}

//Local Functions
const recipeItems = (item: RecipeItem) => {
  return { ...item, ...useStore().getters.popIngredient(item.id) }
}

export const popRecipeObj = (recipe: Recipe) => {
  const popRecipe = recipe as PopRecipe
  const state = useStore().state
  if (popRecipe) {
    popRecipe["fermentationDays"] = popRecipe.fermentation.profileId
      ? state.collections.profiles[popRecipe.fermentation.profileId].totalTime
      : 0
    popRecipe["createdBy"] = state.users.users[popRecipe.userCreated]
      ? state.users.users[popRecipe.userCreated].name
      : "-"
    popRecipe["editedBy"] = state.users.users[popRecipe.userUpdated]
      ? state.users.users[popRecipe.userUpdated].name
      : "-"
    popRecipe["styleName"] = popRecipe.styleId
      ? state.collections.styles[popRecipe.styleId].name
      : ""
    popRecipe.mashing["items"] = popRecipe.mashing.items.map(item =>
      recipeItems(item)
    ) as PopMashingItem[]
    popRecipe.kettle["items"] = popRecipe.kettle.items.map(item =>
      recipeItems(item)
    ) as PopKettleItem[]
    popRecipe.fermentation["items"] = popRecipe.fermentation.items.map(item =>
      recipeItems(item)
    ) as PopFermentationItem[]

    popRecipe["efficiency"] = popRecipe.breweryId
      ? state.collections.breweries[popRecipe.breweryId].efficiency
      : 0

    popRecipe["attenuation"] = popRecipe.fermentation.items.reduce(
      (acc, item) => (acc < item.attenuation ? item.attenuation : acc),
      0
    )

    popRecipe["tog"] = tog(popRecipe.mashing.items, popRecipe.batchSize, popRecipe.efficiency)

    popRecipe["tfg"] = tfg(popRecipe.tog, popRecipe.attenuation)

    popRecipe["evaporated"] = evaporation(
      popRecipe.kettle.totalTime,
      popRecipe.breweryId ? state.collections.breweries[popRecipe.breweryId].evaporation : 0
    )

    popRecipe["targetAbv"] = abv(popRecipe.tog, popRecipe.tfg)
    popRecipe["targetEbc"] = ebc(popRecipe.mashing.items, popRecipe.efficiency, popRecipe.batchSize)
    popRecipe["targetSrm"] = srm(popRecipe.targetEbc)
    popRecipe["targetColor"] = srmColor(popRecipe.targetSrm)
    popRecipe["targetFinalPlato"] = plato(popRecipe.tfg / 1000)
    popRecipe["ibuTinseth"] = ibuTinseth(
      popRecipe.tog / 1000,
      popRecipe.batchSize,
      popRecipe.kettle.items
    )
  }
  return popRecipe
}

//Getters
export const recipesGetters: GetterTree<RootState, RootState> & RecipesGetters = {
  recipe: state => id => {
    let recipe = cloneDeep(useStore().state.collections.recipes[id])
    recipe = base(recipe)
    return recipe
  },
  popRecipe: state => id => {
    return popRecipeObj(useStore().getters.recipe(id))
  },

  popRecipes: state => {
    let recipes = Object.values(cloneDeep(state.collections.recipes))
    recipes = recipes.map(item => useStore().getters.popRecipe(item.id))
    return recipes as PopRecipe[]
  },
}
