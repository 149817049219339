
import Navigation from "./components/nav/Navigation.vue"
import MessageContainer from "./components/message/MessageContainer.vue"
import Modals from "./components/modals/Modals.vue"
import { useStore } from "./store/store"
import { computed, defineComponent } from "vue"

export default defineComponent({
  name: "app",
  components: {
    Navigation,
    MessageContainer,
    Modals,
  },
  setup() {
    const store = useStore()
    return {
      loaded: computed(() => store.getters["loaded"]),
      uid: computed(() => store.getters["uid"]),
    }
  },
})
