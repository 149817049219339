import { store } from "../../store/store"
import firebase from "firebase/app"
import "firebase/firestore"
import { ActionTypes } from "@/store/actionTypesEnums"
import { Brew } from "@/typings/types"

// ADD REF
const addRef = function(collection: string, ids: string[], refId: string, field: string) {
  const db = firebase.firestore()
  ids.forEach(id => {
    db.collection(collection)
      .doc(id)
      .set({ [field]: firebase.firestore.FieldValue.arrayUnion(refId) }, { merge: true })
      .catch(error => {
        store.dispatch(ActionTypes.MessageSetError, error.message)
      })
  })
}

//REMOVE REF
const removeRef = function(collection: string, ids: string[], refId: string, field: string) {
  const db = firebase.firestore()
  ids.forEach(id => {
    db.collection(collection)
      .doc(id)
      .update({ [field]: firebase.firestore.FieldValue.arrayRemove(refId) })
      .then(() => store.dispatch(ActionTypes.MessageSetSuccess, collection + " updated"))
      .catch(error => {
        store.dispatch(ActionTypes.MessageSetError, error.message)
      })
  })
}

const afterBrewSave = (obj: Brew & any) => {
  //SET INGREDIENT REF
  addRef("ingredients", obj.ingredientToAddIds, obj.item.id, "brewIds")
  removeRef("ingredients", obj.ingredientToRemoveIds, obj.item.id, "brewIds")

  //SET CONTAINER REF
  addRef("containers", obj.containerToAddIds, obj.item.id, "brewIds")
  removeRef("containers", obj.containerToRemoveIds, obj.item.id, "brewIds")
}

export { afterBrewSave }
