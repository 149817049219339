<template>
  <Row class="my-2">
    <BCol>
      <FormGroup>
        <FormInput ref="formTime" type="number" placeholder="Time" v-model.number="input.time" />
        <FormSpan>{{ unit }}</FormSpan>
      </FormGroup>
    </BCol>
    <BCol>
      <FormGroup>
        <FormInput
          ref="formTemperature"
          type="number"
          placeholder="Temperature"
          v-model.number="input.temperature"
        />
        <FormSpan>c°</FormSpan>
      </FormGroup>
    </BCol>
    <Button class="col-2 mx-1" lg="true" @click="add()">add</Button>
  </Row>
  <Row class="my-1" v-for="(item, index) in items" :key="index">
    <BCol>
      <FormGroup class="input-group-sm">
        <input class="form-control" :placeholder="item.time" disabled />
        <FormSpan>{{ unit }}</FormSpan>
      </FormGroup>
    </BCol>
    <BCol>
      <FormGroup class="input-group-sm">
        <input class="form-control" :placeholder="item.temperature" disabled />
        <FormSpan>c°</FormSpan>
      </FormGroup>
    </BCol>
    <ButtonGroup class="col-2 mx-1">
      <Button @click="up(index)">up</Button>
      <Button @click="down(index)">down</Button>
      <Button @click="remove(index)">trash</Button>
    </ButtonGroup>
  </Row>
</template>

<script>
import comp from "../../components/base"
import { mapActions } from "vuex"
import { checkValidity, formError, untouch } from "../../services/validation"
import cloneDeep from "clone-deep"
import arrayMove from "array-move"

export default {
  name: "ProfileItems",
  props: ["items", "unit"],
  emits: ["update:items"],
  components: {
    ...comp,
  },
  data() {
    return {
      input: {},
    }
  },
  computed: {},
  methods: {
    ...mapActions(["closeProfile"]),
    add() {
      if (checkValidity(this.$refs)) {
        let local = cloneDeep(this.items)
        local.push(this.input)
        this.$emit("update:items", local)
        this.input = {}
        untouch(this.$refs)
      } else formError()
    },
    remove(index) {
      let local = cloneDeep(this.items)
      local.splice(index, 1)
      this.$emit("update:items", local)
    },
    up(index) {
      let local = arrayMove(this.items, index, index - 1)
      this.$emit("update:items", local)
    },
    down(index) {
      let local = arrayMove(this.items, index, index + 1)
      this.$emit("update:items", local)
    },
  },
}
</script>

<style></style>
