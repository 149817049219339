import {
  ActionContext,
  CommitOptions,
  createLogger,
  createStore,
  DispatchOptions,
  Store as VuexStore,
} from "vuex"
import modals, { ModalsActions, ModalsMutations, ModalsState } from "./modules/modals"
import localModals, {
  LocalModalActions,
  LocalModalMutations,
  LocalModalsState,
} from "./modules/localModals"
import company, { CompanyActions, CompanyMutations, CompanyState } from "./modules/company"
import deleteModal, {
  DeleteModalActions,
  DeleteModalMutations,
  DeleteModalState,
} from "./modules/deleteModal"
import validation, {
  ValidationActions,
  ValidationGetters,
  ValidationMutations,
  ValidationState,
} from "./modules/validation"
import loadState, {
  LoadStateMutations,
  LoadStateState,
  LoadStateGetters,
  LoadStateActions,
} from "./modules/loadState"
import message, { MessageActions, MessageMutations, MessageState } from "./modules/message"
import user, { UserState, UserActions, UserMutations, UserGetters } from "./modules/user"
import users, { UsersActions, UsersGetters, UsersMutations, UsersState } from "./modules/users"
import updateTracker, {
  UpdateTrackerActions,
  UpdateTrackerGetters,
  UpdateTrackerMutations,
  UpdateTrackerState,
} from "./modules/updateTracker"
import collections, {
  CollectionActions,
  CollectionMutations,
  CollectionState,
} from "./modules/collections"
import { ordersGetters, OrdersGetters } from "./getters/orders"
import { ContainerGetters, containersGetters } from "./getters/containers"
import { BrewGetters, brewsGetters } from "./getters/brews"
import { IngredientGetters, ingredientsGetters } from "./getters/ingredients"
import { recipesGetters, RecipesGetters } from "./getters/recipes"
import { utilitiesGetters, UtilitiesGetters } from "./getters/utilities"

export interface RootState {
  company: CompanyState
  deleteModal: DeleteModalState
  loadState: LoadStateState
  localModals: LocalModalsState
  message: MessageState
  modals: ModalsState
  user: UserState
  users: UsersState
  validation: ValidationState
  collections: CollectionState
  updateTracker: UpdateTrackerState
}

export type Mutations = LoadStateMutations &
  CompanyMutations &
  UserMutations &
  DeleteModalMutations &
  LocalModalMutations &
  MessageMutations &
  ModalsMutations &
  UpdateTrackerMutations &
  UsersMutations &
  ValidationMutations &
  CollectionMutations

export type Actions = CompanyActions &
  UserActions &
  DeleteModalActions &
  LocalModalActions &
  MessageActions &
  LoadStateActions &
  ModalsActions &
  UpdateTrackerActions &
  UsersActions &
  ValidationActions &
  CollectionActions

export interface Getters
  extends LoadStateGetters,
    UserGetters,
    UpdateTrackerGetters,
    UsersGetters,
    ValidationGetters,
    OrdersGetters,
    ContainerGetters,
    BrewGetters,
    IngredientGetters,
    RecipesGetters,
    UtilitiesGetters {}

export const store = createStore<RootState>({
  strict: process.env.NODE_ENV !== "production",
  plugins: process.env.NODE_ENV !== "production" ? [createLogger({})] : [],

  modules: {
    collections,
    company,
    deleteModal,
    loadState,
    localModals,
    message,
    modals,
    updateTracker,
    user,
    users,
    validation,
  },

  getters: {
    ...brewsGetters,
    ...containersGetters,
    ...ingredientsGetters,
    ...ordersGetters,
    ...recipesGetters,
    ...utilitiesGetters,
  },
}) as Store // TODO Når store er konverteret til TS

export function useStore() {
  return store as Store
}

export type Store = Omit<VuexStore<RootState>, "getters" | "commit" | "dispatch"> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  }
}

export type ActionAugments = Omit<ActionContext<RootState, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
    root?: boolean
  ): ReturnType<Mutations[K]>
}
