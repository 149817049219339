<template>
  <ModalBase :close="closeContainerInventory">
    <template #header
      >Container Inventory Form | {{ container.name }} | {{ container.supplierName }}</template
    >
    <BTable :header="['', 'quantity', 'created']">
      <tr v-for="(item, index) in fullList" :key="index">
        <ContainerInventoryItem :item="item" :index="index" :container="container" :id="id" />
      </tr>
      <template #footer>
        <td
          class="fw-bold"
          v-for="(foot, index) in ['sum', container.total + ' ' + container.unitText, ' ']"
          :key="index"
        >
          {{ foot }}
        </td>
      </template>
    </BTable>

    <Row class="mt-4">
      <BCol>
        <FormInput
          ref="formQuantity"
          type="number"
          :validateion="validNum"
          v-model.number="quantity"
        />
      </BCol>
      <Button class="col-2 mx-1" :lg="true" @click="add()">add</Button>
    </Row>
  </ModalBase>
</template>

<script>
import { mapActions } from "vuex"
import ModalBase from "./ModalBase.vue"
import comp from "../base"
import { checkValidity, formError, untouch, validNum } from "../../services/validation"
import cloneDeep from "clone-deep"
import { apiSetContainer } from "../../services/api"
import ContainerInventoryItem from "../containers/ContainerInventoryItem.vue"
import { computed, ref } from "vue"
import { useStore } from "@/store/store"

export default {
  name: "ContainersInventoryForm",
  props: ["id"],
  components: {
    ...comp,
    ModalBase,
    ContainerInventoryItem,
  },
  setup(props) {
    return {
      validNum,
      quantity: ref(),
      container: computed(() => useStore().getters.popContainer(props.id)),
    }
  },
  computed: {
    fullList() {
      let brewContainers = this.container.brewIds
        .map(brewId => useStore().getters.popBrew(brewId))
        .map(brew =>
          brew.containers.map(container => {
            container["recipeName"] = brew.recipeName
            container.quantity = -container.quantity
            return container
          })
        )
        .flat()
        .filter(container => container.id == this.id)

      return [...brewContainers, ...this.container.inventory]
    },
  },
  methods: {
    ...mapActions(["closeContainerInventory"]),
    add() {
      if (checkValidity(this.$refs)) {
        let local = cloneDeep(useStore().state.collections.containers[this.id])
        local.inventory.push({
          quantity: this.quantity,
          created: new Date(),
        })
        apiSetContainer(local)
        this.quantity = null
        untouch(this.$refs)
      } else formError()
    },
  },
}
</script>
