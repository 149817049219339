<template>
  <div
    class=" 
        d-flex 
        justify-content-between 
        align-items-center"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Between",
}
</script>
