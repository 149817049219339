import { base, formatCount } from "@/helper/helper"
import { ORDER, TIME_FORMAT, UNIT } from "@/services/enums"
import { Order, PopOrder } from "@/typings/types"
import { format } from "date-fns"
import { cloneDeep } from "lodash"
import { GetterTree } from "vuex"
import { RootState, useStore } from "../store"

export type OrdersGetters = {
  order(state: RootState): (id: string) => Order
  popOrder(state: RootState): (id: string) => PopOrder
  orders(state: RootState): Order[]
  popOrders(state: RootState): PopOrder[]
}

export const ordersGetters: GetterTree<RootState, RootState> & OrdersGetters = {
  order: state => id => {
    let order = cloneDeep(state.collections.orders[id])
    order = base(order)
    return order
  },

  popOrder: state => id => {
    const order = useStore().getters.order(id) as PopOrder

    order["buyerName"] = state.collections.buyers[order.buyerId].name
    order["formatCount"] = formatCount(order.count)
    order["shippingText"] = order.shipping ? format(order.shipping.toDate(), TIME_FORMAT.TIME) : "-"
    order["stateText"] = ORDER.TEXT[order.state]
    order["inventory"] = order.inventory.map(item => ({
      ...item,
      brewName: state.collections.brews[item.brewId].recipeCopy.name,
      containerName: state.collections.containers[item.containerId].name,
      containerSize: state.collections.containers[item.containerId].size,
      containerUnitText: UNIT.TEXT[state.collections.containers[item.containerId].unit],
      // packName: store.state.packTypes[store.state.packs[item.packId].typeId].name,
      // packSize: store.state.packTypes[store.state.packs[item.packId].typeId].size,
      // packUnit: UNIT.TEXT[store.state.packTypes[store.state.packs[item.packId].typeId].unit],
      // packFormatCount: formatCount(store.state.packs[item.packId].count),
    }))

    return order
  },

  orders: state => {
    let orders = Object.values(cloneDeep(state.collections.orders))
    orders = orders.map(item => useStore().getters.order(item.id))
    return orders as Order[]
  },
  popOrders: state => {
    let orders = Object.values(cloneDeep(state.collections.orders))
    orders = orders.map(item => useStore().getters.popOrder(item.id))
    return orders as PopOrder[]
  },
}
