<template>
  <div class="position-relative">
    <div
      v-show="showAll"
      class="toast-container position-fixed bottom-0 end-0 p-3"
      style="z-index: 2000000000"
    >
      <Message v-for="message in messages" :key="message.id" :message="message" />
    </div>

    <div
      v-show="!showAll"
      class="toast-container position-fixed bottom-0 end-0 p-3"
      style="z-index: 2000000000"
    >
      <Message v-for="message in baseMessage" :key="message.id" :message="message" />
      <Message v-for="message in formMessage" :key="message.id" :message="message" />
      <Message v-for="message in apiError" :key="message.id" :message="message" />
      <ApiMessage :messages="apiSuccess" v-if="apiSuccess.length > 0" />
    </div>
  </div>
</template>

<script lant="ts">
// import { defineComponent } from "@vue/runtime-core"
import Message from "./Message.vue"
import ApiMessage from "./ApiMessage.vue"
// import { mapGetters } from "vuex"
import { MESSAGE } from "../../services/enums"
import { useStore } from "@/store/store"
import { computed, defineComponent } from "@vue/runtime-core"
// import { useStore } from "vuex"

export default defineComponent({
  name: "MessageConatiner",
  components: {
    Message,
    ApiMessage,
  },
  setup() {
    return {
      messages: computed(() => useStore().state.message.messages),
    }
  },
  computed: {
    baseMessage() {
      return this.messages.filter(
        message => message.type === MESSAGE.ERROR || message.type === MESSAGE.SUCCESS
      )
    },
    formMessage() {
      return this.messages.filter(
        message => message.type === MESSAGE.FORM_ERROR || message.type === MESSAGE.FORM_SUCCESS
      )
    },
    apiSuccess() {
      return this.messages.filter(
        message => message.type === MESSAGE.SET_SUCCESS || message.type === MESSAGE.GET_SUCCESS
      )
    },
    apiError() {
      return this.messages.filter(
        message => message.type === MESSAGE.SET_ERROR || message.type === MESSAGE.GET_ERROR
      )
    },
    showAll() {
      return false
    },
  },
})
</script>
