<template>
  <ModelContainer @click.self="closeSupplier()">
    <ModalContent>
      <ModalHeader>
        Supplier Form
        <template #right>
          <Button @click="closeSupplier()">close</Button>
        </template>
      </ModalHeader>
      <Row>
        <Lable>Supplier Name *</Lable>
        <Lable>Phone</Lable>
      </Row>
      <Row>
        <BCol>
          <FormInput ref="formName" placeholder="Name" v-model="supplier.name" />
        </BCol>
        <BCol>
          <FormInput placeholder="88888888" v-model="supplier.phone" />
        </BCol>
      </Row>
      <Row>
        <Lable>Address</Lable>
        <Lable>City</Lable>
      </Row>
      <Row>
        <BCol>
          <FormInput placeholder="Street + number" v-model="supplier.address1" />
        </BCol>
        <BCol>
          <FormInput placeholder="City" v-model="supplier.city" />
        </BCol>
      </Row>
      <Row>
        <Lable class="col-4">Zip code</Lable>
        <Lable class="col-4">Country</Lable>
      </Row>
      <Row>
        <BCol class="col-4">
          <FormInput type="number" placeholder="zip-code" v-model.number="supplier.zip" />
        </BCol>
        <BCol class="col-4">
          <FormInput placeholder="Country" v-model="supplier.country" />
        </BCol>
        <Button class="mx-1 col" lg="true" @click="add()">Save Supplier</Button>
      </Row>
    </ModalContent>
  </ModelContainer>
</template>

<script>
import comp from "@/components/base"
import { apiSetSupplier } from "@/services/api"
import { mapActions } from "vuex"
import { initSupplier } from "@/services/objects"
import cloneDeep from "clone-deep"
import ModalContent from "./ModalContent.vue"
import ModelContainer from "./ModalContainer.vue"
import ModalHeader from "./ModalHeader.vue"
import { checkValidity, formError } from "../../services/validation"
import { useStore } from "@/store/store"

export default {
  name: "SupplierForm",
  props: ["id"],
  components: {
    ...comp,
    ModalContent,
    ModelContainer,
    ModalHeader,
  },
  data() {
    return {
      supplier: {},
    }
  },
  created() {
    this.supplier = this.id
      ? cloneDeep(useStore().state.collections.suppliers[this.id])
      : cloneDeep(initSupplier)
  },
  computed: {
    // ...mapState(["suppliers"]),
  },
  methods: {
    ...mapActions(["closeSupplier"]),
    add: function() {
      if (checkValidity(this.$refs)) {
        apiSetSupplier(this.supplier)
        this.closeSupplier()
      } else formError()
    },
  },
}
</script>
