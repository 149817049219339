<template>
  <tr>
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "TR",
}
</script>
