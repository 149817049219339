export const srmColors = [
  {
    SRM: 0,
    RGB: "255,255,255",
  },
  {
    SRM: 0.1,
    RGB: "248,248,230",
  },
  {
    SRM: 0.2,
    RGB: "248,248,220",
  },
  {
    SRM: 0.3,
    RGB: "247,247,199",
  },
  {
    SRM: 0.4,
    RGB: "244,249,185",
  },
  {
    SRM: 0.5,
    RGB: "247,249,180",
  },
  {
    SRM: 0.6,
    RGB: "248,249,178",
  },
  {
    SRM: 0.7,
    RGB: "244,246,169",
  },
  {
    SRM: 0.8,
    RGB: "245,247,166",
  },
  {
    SRM: 0.9,
    RGB: "246,247,156",
  },
  {
    SRM: 1,
    RGB: "243,249,147",
  },
  {
    SRM: 1.1,
    RGB: "246,248,141",
  },
  {
    SRM: 1.2,
    RGB: "246,249,136",
  },
  {
    SRM: 1.3,
    RGB: "245,250,128",
  },
  {
    SRM: 1.4,
    RGB: "246,249,121",
  },
  {
    SRM: 1.5,
    RGB: "248,249,114",
  },
  {
    SRM: 1.6,
    RGB: "243,249,104",
  },
  {
    SRM: 1.7,
    RGB: "246,248,107",
  },
  {
    SRM: 1.8,
    RGB: "248,247,99",
  },
  {
    SRM: 1.9,
    RGB: "245,247,92",
  },
  {
    SRM: 2,
    RGB: "248,247,83",
  },
  {
    SRM: 2.1,
    RGB: "244,248,72",
  },
  {
    SRM: 2.2,
    RGB: "248,247,73",
  },
  {
    SRM: 2.3,
    RGB: "246,247,62",
  },
  {
    SRM: 2.4,
    RGB: "241,248,53",
  },
  {
    SRM: 2.5,
    RGB: "244,247,48",
  },
  {
    SRM: 2.6,
    RGB: "246,249,40",
  },
  {
    SRM: 2.7,
    RGB: "243,249,34",
  },
  {
    SRM: 2.8,
    RGB: "245,247,30",
  },
  {
    SRM: 2.9,
    RGB: "248,245,22",
  },
  {
    SRM: 3,
    RGB: "246,245,19",
  },
  {
    SRM: 3.1,
    RGB: "244,242,22",
  },
  {
    SRM: 3.2,
    RGB: "244,240,21",
  },
  {
    SRM: 3.3,
    RGB: "243,242,19",
  },
  {
    SRM: 3.4,
    RGB: "244,238,24",
  },
  {
    SRM: 3.5,
    RGB: "244,237,29",
  },
  {
    SRM: 3.6,
    RGB: "238,233,22",
  },
  {
    SRM: 3.7,
    RGB: "240,233,23",
  },
  {
    SRM: 3.8,
    RGB: "238,231,25",
  },
  {
    SRM: 3.9,
    RGB: "234,230,21",
  },
  {
    SRM: 4,
    RGB: "236,230,26",
  },
  {
    SRM: 4.1,
    RGB: "230,225,24",
  },
  {
    SRM: 4.2,
    RGB: "232,225,25",
  },
  {
    SRM: 4.3,
    RGB: "230,221,27",
  },
  {
    SRM: 4.4,
    RGB: "224,218,23",
  },
  {
    SRM: 4.5,
    RGB: "229,216,31",
  },
  {
    SRM: 4.6,
    RGB: "229,214,30",
  },
  {
    SRM: 4.7,
    RGB: "223,213,26",
  },
  {
    SRM: 4.8,
    RGB: "226,213,28",
  },
  {
    SRM: 4.9,
    RGB: "223,209,29",
  },
  {
    SRM: 5,
    RGB: "224,208,27",
  },
  {
    SRM: 5.1,
    RGB: "224,204,32",
  },
  {
    SRM: 5.2,
    RGB: "221,204,33",
  },
  {
    SRM: 5.3,
    RGB: "220,203,29",
  },
  {
    SRM: 5.4,
    RGB: "218,200,32",
  },
  {
    SRM: 5.5,
    RGB: "220,197,34",
  },
  {
    SRM: 5.6,
    RGB: "218,196,41",
  },
  {
    SRM: 5.7,
    RGB: "217,194,43",
  },
  {
    SRM: 5.8,
    RGB: "216,192,39",
  },
  {
    SRM: 5.9,
    RGB: "213,190,37",
  },
  {
    SRM: 6,
    RGB: "213,188,38",
  },
  {
    SRM: 6.1,
    RGB: "212,184,39",
  },
  {
    SRM: 6.2,
    RGB: "214,183,43",
  },
  {
    SRM: 6.3,
    RGB: "213,180,45",
  },
  {
    SRM: 6.4,
    RGB: "210,179,41",
  },
  {
    SRM: 6.5,
    RGB: "208,178,42",
  },
  {
    SRM: 6.6,
    RGB: "208,176,46",
  },
  {
    SRM: 6.7,
    RGB: "204,172,48",
  },
  {
    SRM: 6.8,
    RGB: "204,172,52",
  },
  {
    SRM: 6.9,
    RGB: "205,170,55",
  },
  {
    SRM: 7,
    RGB: "201,167,50",
  },
  {
    SRM: 7.1,
    RGB: "202,167,52",
  },
  {
    SRM: 7.2,
    RGB: "201,166,51",
  },
  {
    SRM: 7.3,
    RGB: "199,162,54",
  },
  {
    SRM: 7.4,
    RGB: "198,160,56",
  },
  {
    SRM: 7.5,
    RGB: "200,158,60",
  },
  {
    SRM: 7.6,
    RGB: "194,156,54",
  },
  {
    SRM: 7.7,
    RGB: "196,155,54",
  },
  {
    SRM: 7.8,
    RGB: "198,151,60",
  },
  {
    SRM: 7.9,
    RGB: "193,150,60",
  },
  {
    SRM: 8,
    RGB: "191,146,59",
  },
  {
    SRM: 8.1,
    RGB: "190,147,57",
  },
  {
    SRM: 8.2,
    RGB: "190,147,59",
  },
  {
    SRM: 8.3,
    RGB: "190,145,60",
  },
  {
    SRM: 8.4,
    RGB: "186,148,56",
  },
  {
    SRM: 8.5,
    RGB: "190,145,58",
  },
  {
    SRM: 8.6,
    RGB: "193,145,59",
  },
  {
    SRM: 8.7,
    RGB: "190,145,58",
  },
  {
    SRM: 8.8,
    RGB: "191,143,59",
  },
  {
    SRM: 8.9,
    RGB: "191,141,61",
  },
  {
    SRM: 9,
    RGB: "190,140,58",
  },
  {
    SRM: 9.1,
    RGB: "192,140,61",
  },
  {
    SRM: 9.2,
    RGB: "193,138,62",
  },
  {
    SRM: 9.3,
    RGB: "192,137,59",
  },
  {
    SRM: 9.4,
    RGB: "193,136,59",
  },
  {
    SRM: 9.5,
    RGB: "195,135,63",
  },
  {
    SRM: 9.6,
    RGB: "191,136,58",
  },
  {
    SRM: 9.7,
    RGB: "191,134,67",
  },
  {
    SRM: 9.8,
    RGB: "193,131,67",
  },
  {
    SRM: 9.9,
    RGB: "190,130,58",
  },
  {
    SRM: 10,
    RGB: "191,129,58",
  },
  {
    SRM: 10.1,
    RGB: "191,131,57",
  },
  {
    SRM: 10.2,
    RGB: "191,129,58",
  },
  {
    SRM: 10.3,
    RGB: "191,129,58",
  },
  {
    SRM: 10.4,
    RGB: "190,129,55",
  },
  {
    SRM: 10.5,
    RGB: "191,127,59",
  },
  {
    SRM: 10.6,
    RGB: "194,126,59",
  },
  {
    SRM: 10.7,
    RGB: "188,128,54",
  },
  {
    SRM: 10.8,
    RGB: "190,124,55",
  },
  {
    SRM: 10.9,
    RGB: "193,122,55",
  },
  {
    SRM: 11,
    RGB: "190,124,55",
  },
  {
    SRM: 11.1,
    RGB: "194,121,59",
  },
  {
    SRM: 11.2,
    RGB: "193,120,56",
  },
  {
    SRM: 11.3,
    RGB: "190,119,52",
  },
  {
    SRM: 11.4,
    RGB: "182,117,54",
  },
  {
    SRM: 11.5,
    RGB: "196,116,59",
  },
  {
    SRM: 11.6,
    RGB: "191,118,56",
  },
  {
    SRM: 11.7,
    RGB: "190,116,57",
  },
  {
    SRM: 11.8,
    RGB: "191,115,58",
  },
  {
    SRM: 11.9,
    RGB: "189,115,56",
  },
  {
    SRM: 12,
    RGB: "191,113,56",
  },
  {
    SRM: 12.1,
    RGB: "191,113,53",
  },
  {
    SRM: 12.2,
    RGB: "188,112,57",
  },
  {
    SRM: 12.3,
    RGB: "190,112,55",
  },
  {
    SRM: 12.4,
    RGB: "184,110,52",
  },
  {
    SRM: 12.5,
    RGB: "188,109,55",
  },
  {
    SRM: 12.6,
    RGB: "189,109,55",
  },
  {
    SRM: 12.7,
    RGB: "186,106,50",
  },
  {
    SRM: 12.8,
    RGB: "190,103,52",
  },
  {
    SRM: 12.9,
    RGB: "189,104,54",
  },
  {
    SRM: 13,
    RGB: "188,103,51",
  },
  {
    SRM: 13.1,
    RGB: "188,103,51",
  },
  {
    SRM: 13.2,
    RGB: "186,101,51",
  },
  {
    SRM: 13.3,
    RGB: "186,102,56",
  },
  {
    SRM: 13.4,
    RGB: "185,100,56",
  },
  {
    SRM: 13.5,
    RGB: "185,98,59",
  },
  {
    SRM: 13.6,
    RGB: "183,98,54",
  },
  {
    SRM: 13.7,
    RGB: "181,100,53",
  },
  {
    SRM: 13.8,
    RGB: "182,97,55",
  },
  {
    SRM: 13.9,
    RGB: "177,97,51",
  },
  {
    SRM: 14,
    RGB: "178,96,51",
  },
  {
    SRM: 14.1,
    RGB: "176,96,49",
  },
  {
    SRM: 14.2,
    RGB: "177,96,55",
  },
  {
    SRM: 14.3,
    RGB: "178,95,55",
  },
  {
    SRM: 14.4,
    RGB: "171,94,55",
  },
  {
    SRM: 14.5,
    RGB: "171,92,56",
  },
  {
    SRM: 14.6,
    RGB: "172,93,59",
  },
  {
    SRM: 14.7,
    RGB: "168,92,55",
  },
  {
    SRM: 14.8,
    RGB: "169,90,54",
  },
  {
    SRM: 14.9,
    RGB: "168,88,57",
  },
  {
    SRM: 15,
    RGB: "165,89,54",
  },
  {
    SRM: 15.1,
    RGB: "166,88,54",
  },
  {
    SRM: 15.2,
    RGB: "165,88,58",
  },
  {
    SRM: 15.3,
    RGB: "161,88,52",
  },
  {
    SRM: 15.4,
    RGB: "163,85,55",
  },
  {
    SRM: 15.5,
    RGB: "160,86,56",
  },
  {
    SRM: 15.6,
    RGB: "158,85,57",
  },
  {
    SRM: 15.7,
    RGB: "158,86,54",
  },
  {
    SRM: 15.8,
    RGB: "159,84,57",
  },
  {
    SRM: 15.9,
    RGB: "156,83,53",
  },
  {
    SRM: 16,
    RGB: "152,83,54",
  },
  {
    SRM: 16.1,
    RGB: "150,83,55",
  },
  {
    SRM: 16.2,
    RGB: "150,81,56",
  },
  {
    SRM: 16.3,
    RGB: "146,81,56",
  },
  {
    SRM: 16.4,
    RGB: "147,79,54",
  },
  {
    SRM: 16.5,
    RGB: "147,79,55",
  },
  {
    SRM: 16.6,
    RGB: "146,78,54",
  },
  {
    SRM: 16.7,
    RGB: "142,77,51",
  },
  {
    SRM: 16.8,
    RGB: "143,79,53",
  },
  {
    SRM: 16.9,
    RGB: "142,77,54",
  },
  {
    SRM: 17,
    RGB: "141,76,50",
  },
  {
    SRM: 17.1,
    RGB: "140,75,50",
  },
  {
    SRM: 17.2,
    RGB: "138,73,49",
  },
  {
    SRM: 17.3,
    RGB: "135,70,45",
  },
  {
    SRM: 17.4,
    RGB: "136,71,49",
  },
  {
    SRM: 17.5,
    RGB: "140,72,49",
  },
  {
    SRM: 17.6,
    RGB: "128,70,45",
  },
  {
    SRM: 17.7,
    RGB: "129,71,46",
  },
  {
    SRM: 17.8,
    RGB: "130,69,47",
  },
  {
    SRM: 17.9,
    RGB: "123,69,45",
  },
  {
    SRM: 18,
    RGB: "124,69,45",
  },
  {
    SRM: 18.1,
    RGB: "121,66,40",
  },
  {
    SRM: 18.2,
    RGB: "120,67,40",
  },
  {
    SRM: 18.3,
    RGB: "119,64,38",
  },
  {
    SRM: 18.4,
    RGB: "116,63,34",
  },
  {
    SRM: 18.5,
    RGB: "120,63,35",
  },
  {
    SRM: 18.6,
    RGB: "120,62,37",
  },
  {
    SRM: 18.7,
    RGB: "112,63,35",
  },
  {
    SRM: 18.8,
    RGB: "111,62,36",
  },
  {
    SRM: 18.9,
    RGB: "109,60,34",
  },
  {
    SRM: 19,
    RGB: "107,58,30",
  },
  {
    SRM: 19.1,
    RGB: "106,57,31",
  },
  {
    SRM: 19.2,
    RGB: "107,56,31",
  },
  {
    SRM: 19.3,
    RGB: "105,56,28",
  },
  {
    SRM: 19.4,
    RGB: "105,56,28",
  },
  {
    SRM: 19.5,
    RGB: "104,52,31",
  },
  {
    SRM: 19.6,
    RGB: "102,53,27",
  },
  {
    SRM: 19.7,
    RGB: "100,53,26",
  },
  {
    SRM: 19.8,
    RGB: "99,52,25",
  },
  {
    SRM: 19.9,
    RGB: "93,53,24",
  },
  {
    SRM: 20,
    RGB: "93,52,26",
  },
  {
    SRM: 20.1,
    RGB: "89,49,20",
  },
  {
    SRM: 20.2,
    RGB: "90,50,21",
  },
  {
    SRM: 20.3,
    RGB: "91,48,20",
  },
  {
    SRM: 20.4,
    RGB: "83,48,15",
  },
  {
    SRM: 20.5,
    RGB: "88,48,17",
  },
  {
    SRM: 20.6,
    RGB: "86,46,17",
  },
  {
    SRM: 20.7,
    RGB: "81,45,15",
  },
  {
    SRM: 20.8,
    RGB: "83,44,15",
  },
  {
    SRM: 20.9,
    RGB: "81,45,15",
  },
  {
    SRM: 21,
    RGB: "78,42,12",
  },
  {
    SRM: 21.1,
    RGB: "77,43,12",
  },
  {
    SRM: 21.2,
    RGB: "75,41,12",
  },
  {
    SRM: 21.3,
    RGB: "74,41,5",
  },
  {
    SRM: 21.4,
    RGB: "78,40,23",
  },
  {
    SRM: 21.5,
    RGB: "83,43,46",
  },
  {
    SRM: 21.6,
    RGB: "78,43,41",
  },
  {
    SRM: 21.7,
    RGB: "78,40,41",
  },
  {
    SRM: 21.8,
    RGB: "76,41,41",
  },
  {
    SRM: 21.9,
    RGB: "74,39,39",
  },
  {
    SRM: 22,
    RGB: "74,39,39",
  },
  {
    SRM: 22.1,
    RGB: "69,39,35",
  },
  {
    SRM: 22.2,
    RGB: "70,37,37",
  },
  {
    SRM: 22.3,
    RGB: "68,38,36",
  },
  {
    SRM: 22.4,
    RGB: "64,35,34",
  },
  {
    SRM: 22.5,
    RGB: "64,35,34",
  },
  {
    SRM: 22.6,
    RGB: "62,33,32",
  },
  {
    SRM: 22.7,
    RGB: "58,33,31",
  },
  {
    SRM: 22.8,
    RGB: "61,33,31",
  },
  {
    SRM: 22.9,
    RGB: "58,33,33",
  },
  {
    SRM: 23,
    RGB: "54,31,27",
  },
  {
    SRM: 23.1,
    RGB: "52,29,28",
  },
  {
    SRM: 23.2,
    RGB: "52,29,28",
  },
  {
    SRM: 23.3,
    RGB: "49,28,27",
  },
  {
    SRM: 23.4,
    RGB: "48,27,26",
  },
  {
    SRM: 23.5,
    RGB: "48,27,26",
  },
  {
    SRM: 23.6,
    RGB: "44,25,25",
  },
  {
    SRM: 23.7,
    RGB: "44,25,23",
  },
  {
    SRM: 23.8,
    RGB: "42,24,26",
  },
  {
    SRM: 23.9,
    RGB: "40,23,22",
  },
  {
    SRM: 24,
    RGB: "38,23,22",
  },
  {
    SRM: 24.1,
    RGB: "38,23,22",
  },
  {
    SRM: 24.2,
    RGB: "38,23,22",
  },
  {
    SRM: 24.3,
    RGB: "38,23,22",
  },
  {
    SRM: 24.4,
    RGB: "38,23,22",
  },
  {
    SRM: 24.5,
    RGB: "38,23,22",
  },
  {
    SRM: 24.6,
    RGB: "38,23,22",
  },
  {
    SRM: 24.7,
    RGB: "38,23,22",
  },
  {
    SRM: 24.8,
    RGB: "38,23,22",
  },
  {
    SRM: 24.9,
    RGB: "38,23,22",
  },
  {
    SRM: 25,
    RGB: "38,23,22",
  },
  {
    SRM: 25.1,
    RGB: "38,23,22",
  },
  {
    SRM: 25.2,
    RGB: "38,23,22",
  },
  {
    SRM: 25.3,
    RGB: "38,23,22",
  },
  {
    SRM: 25.4,
    RGB: "38,23,22",
  },
  {
    SRM: 25.5,
    RGB: "38,23,22",
  },
  {
    SRM: 25.6,
    RGB: "38,23,24",
  },
  {
    SRM: 25.7,
    RGB: "25,16,15",
  },
  {
    SRM: 25.8,
    RGB: "25,16,15",
  },
  {
    SRM: 25.9,
    RGB: "25,16,15",
  },
  {
    SRM: 26,
    RGB: "25,16,15",
  },
  {
    SRM: 26.1,
    RGB: "25,16,15",
  },
  {
    SRM: 26.2,
    RGB: "25,16,15",
  },
  {
    SRM: 26.3,
    RGB: "25,16,15",
  },
  {
    SRM: 26.4,
    RGB: "25,16,15",
  },
  {
    SRM: 26.5,
    RGB: "25,16,15",
  },
  {
    SRM: 26.6,
    RGB: "25,16,15",
  },
  {
    SRM: 26.7,
    RGB: "25,16,15",
  },
  {
    SRM: 26.8,
    RGB: "25,16,15",
  },
  {
    SRM: 26.9,
    RGB: "25,16,15",
  },
  {
    SRM: 27,
    RGB: "25,16,15",
  },
  {
    SRM: 27.1,
    RGB: "25,16,15",
  },
  {
    SRM: 27.2,
    RGB: "25,16,15",
  },
  {
    SRM: 27.3,
    RGB: "18,13,12",
  },
  {
    SRM: 27.4,
    RGB: "18,13,12",
  },
  {
    SRM: 27.5,
    RGB: "18,13,12",
  },
  {
    SRM: 27.6,
    RGB: "18,13,12",
  },
  {
    SRM: 27.7,
    RGB: "18,13,12",
  },
  {
    SRM: 27.8,
    RGB: "18,13,12",
  },
  {
    SRM: 27.9,
    RGB: "18,13,12",
  },
  {
    SRM: 28,
    RGB: "18,13,12",
  },
  {
    SRM: 28.1,
    RGB: "18,13,12",
  },
  {
    SRM: 28.2,
    RGB: "18,13,12",
  },
  {
    SRM: 28.3,
    RGB: "18,13,12",
  },
  {
    SRM: 28.4,
    RGB: "18,13,12",
  },
  {
    SRM: 28.5,
    RGB: "18,13,12",
  },
  {
    SRM: 28.6,
    RGB: "18,13,12",
  },
  {
    SRM: 28.7,
    RGB: "17,13,10",
  },
  {
    SRM: 28.8,
    RGB: "18,13,12",
  },
  {
    SRM: 28.9,
    RGB: "16,11,10",
  },
  {
    SRM: 29,
    RGB: "16,11,10",
  },
  {
    SRM: 29.1,
    RGB: "16,11,10",
  },
  {
    SRM: 29.2,
    RGB: "16,11,10",
  },
  {
    SRM: 29.3,
    RGB: "16,11,10",
  },
  {
    SRM: 29.4,
    RGB: "16,11,10",
  },
  {
    SRM: 29.5,
    RGB: "16,11,10",
  },
  {
    SRM: 29.6,
    RGB: "16,11,10",
  },
  {
    SRM: 29.7,
    RGB: "16,11,10",
  },
  {
    SRM: 29.8,
    RGB: "16,11,10",
  },
  {
    SRM: 29.9,
    RGB: "16,11,10",
  },
  {
    SRM: 30,
    RGB: "16,11,10",
  },
  {
    SRM: 30.1,
    RGB: "16,11,10",
  },
  {
    SRM: 30.2,
    RGB: "16,11,10",
  },
  {
    SRM: 30.3,
    RGB: "16,11,10",
  },
  {
    SRM: 30.4,
    RGB: "16,11,10",
  },
  {
    SRM: 30.5,
    RGB: "14,9,8",
  },
  {
    SRM: 30.6,
    RGB: "15,10,9",
  },
  {
    SRM: 30.7,
    RGB: "14,9,8",
  },
  {
    SRM: 30.8,
    RGB: "14,9,8",
  },
  {
    SRM: 30.9,
    RGB: "14,9,8",
  },
  {
    SRM: 31,
    RGB: "14,9,8",
  },
  {
    SRM: 31.1,
    RGB: "14,9,8",
  },
  {
    SRM: 31.2,
    RGB: "14,9,8",
  },
  {
    SRM: 31.3,
    RGB: "14,9,8",
  },
  {
    SRM: 31.4,
    RGB: "14,9,8",
  },
  {
    SRM: 31.5,
    RGB: "14,9,8",
  },
  {
    SRM: 31.6,
    RGB: "14,9,8",
  },
  {
    SRM: 31.7,
    RGB: "14,9,8",
  },
  {
    SRM: 31.8,
    RGB: "14,9,8",
  },
  {
    SRM: 31.9,
    RGB: "14,9,8",
  },
  {
    SRM: 32,
    RGB: "15,11,8",
  },
  {
    SRM: 32.1,
    RGB: "12,9,7",
  },
  {
    SRM: 32.2,
    RGB: "12,9,7",
  },
  {
    SRM: 32.3,
    RGB: "12,9,7",
  },
  {
    SRM: 32.4,
    RGB: "12,9,7",
  },
  {
    SRM: 32.5,
    RGB: "12,9,7",
  },
  {
    SRM: 32.6,
    RGB: "12,9,7",
  },
  {
    SRM: 32.7,
    RGB: "12,9,7",
  },
  {
    SRM: 32.8,
    RGB: "12,9,7",
  },
  {
    SRM: 32.9,
    RGB: "12,9,7",
  },
  {
    SRM: 33,
    RGB: "12,9,7",
  },
  {
    SRM: 33.1,
    RGB: "12,9,7",
  },
  {
    SRM: 33.2,
    RGB: "12,9,7",
  },
  {
    SRM: 33.3,
    RGB: "12,9,7",
  },
  {
    SRM: 33.4,
    RGB: "12,9,7",
  },
  {
    SRM: 33.5,
    RGB: "12,9,7",
  },
  {
    SRM: 33.6,
    RGB: "12,9,7",
  },
  {
    SRM: 33.7,
    RGB: "10,7,7",
  },
  {
    SRM: 33.8,
    RGB: "10,7,5",
  },
  {
    SRM: 33.9,
    RGB: "8,7,7",
  },
  {
    SRM: 34,
    RGB: "8,7,7",
  },
  {
    SRM: 34.1,
    RGB: "8,7,7",
  },
  {
    SRM: 34.2,
    RGB: "8,7,7",
  },
  {
    SRM: 34.3,
    RGB: "8,7,7",
  },
  {
    SRM: 34.4,
    RGB: "8,7,7",
  },
  {
    SRM: 34.5,
    RGB: "8,7,7",
  },
  {
    SRM: 34.6,
    RGB: "8,7,7",
  },
  {
    SRM: 34.7,
    RGB: "8,7,7",
  },
  {
    SRM: 34.8,
    RGB: "8,7,7",
  },
  {
    SRM: 34.9,
    RGB: "8,7,7",
  },
  {
    SRM: 35,
    RGB: "8,7,7",
  },
  {
    SRM: 35.1,
    RGB: "8,7,7",
  },
  {
    SRM: 35.2,
    RGB: "8,7,7",
  },
  {
    SRM: 35.3,
    RGB: "7,6,6",
  },
  {
    SRM: 35.4,
    RGB: "7,6,6",
  },
  {
    SRM: 35.5,
    RGB: "7,6,6",
  },
  {
    SRM: 35.6,
    RGB: "7,6,6",
  },
  {
    SRM: 35.7,
    RGB: "7,6,6",
  },
  {
    SRM: 35.8,
    RGB: "7,6,6",
  },
  {
    SRM: 35.9,
    RGB: "7,6,6",
  },
  {
    SRM: 36,
    RGB: "7,6,6",
  },
  {
    SRM: 36.1,
    RGB: "7,6,6",
  },
  {
    SRM: 36.2,
    RGB: "7,6,6",
  },
  {
    SRM: 36.3,
    RGB: "7,6,6",
  },
  {
    SRM: 36.4,
    RGB: "7,6,6",
  },
  {
    SRM: 36.5,
    RGB: "7,6,6",
  },
  {
    SRM: 36.6,
    RGB: "7,6,6",
  },
  {
    SRM: 36.7,
    RGB: "7,7,4",
  },
  {
    SRM: 36.8,
    RGB: "6,6,3",
  },
  {
    SRM: 36.9,
    RGB: "6,5,5",
  },
  {
    SRM: 37,
    RGB: "4,5,4",
  },
  {
    SRM: 37.1,
    RGB: "4,5,4",
  },
  {
    SRM: 37.2,
    RGB: "4,5,4",
  },
  {
    SRM: 37.3,
    RGB: "4,5,4",
  },
  {
    SRM: 37.4,
    RGB: "4,5,4",
  },
  {
    SRM: 37.5,
    RGB: "4,5,4",
  },
  {
    SRM: 37.6,
    RGB: "4,5,4",
  },
  {
    SRM: 37.7,
    RGB: "4,5,4",
  },
  {
    SRM: 37.8,
    RGB: "4,5,4",
  },
  {
    SRM: 37.9,
    RGB: "4,5,4",
  },
  {
    SRM: 38,
    RGB: "4,5,4",
  },
  {
    SRM: 38.1,
    RGB: "4,5,4",
  },
  {
    SRM: 38.2,
    RGB: "4,5,4",
  },
  {
    SRM: 38.3,
    RGB: "4,5,4",
  },
  {
    SRM: 38.4,
    RGB: "4,5,4",
  },
  {
    SRM: 38.5,
    RGB: "3,4,3",
  },
  {
    SRM: 38.6,
    RGB: "4,5,4",
  },
  {
    SRM: 38.7,
    RGB: "3,4,3",
  },
  {
    SRM: 38.8,
    RGB: "3,4,3",
  },
  {
    SRM: 38.9,
    RGB: "3,4,3",
  },
  {
    SRM: 39,
    RGB: "3,4,3",
  },
  {
    SRM: 39.1,
    RGB: "3,4,3",
  },
  {
    SRM: 39.2,
    RGB: "3,4,3",
  },
  {
    SRM: 39.3,
    RGB: "3,4,3",
  },
  {
    SRM: 39.4,
    RGB: "3,4,3",
  },
  {
    SRM: 39.5,
    RGB: "3,4,3",
  },
  {
    SRM: 39.6,
    RGB: "3,4,3",
  },
  {
    SRM: 39.7,
    RGB: "3,4,3",
  },
  {
    SRM: 39.8,
    RGB: "3,4,3",
  },
  {
    SRM: 39.9,
    RGB: "3,4,3",
  },
  {
    SRM: 40,
    RGB: "3,4,3",
  },
]
