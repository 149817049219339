<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <slot name="headRow"></slot>
      </tr>
    </thead>
    <tbody>
      <slot name="body"></slot>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Table",
}
</script>
