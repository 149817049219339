<template>
  <template v-if="type == TANK.FERMENTATION">
    <div
      class="brew text-truncate p-1 small"
      role="button"
      @click="handleClick(brew.id)"
      v-for="(brew, index) in tank"
      :key="index"
      :style="{
        zIndex: index,
        width: daysToProcent(brew.recipeCopy.fermentationDays) + '%',

        left: procentFromStart(brew.start.toDate()) + '%',
        backgroundColor: styles[brew.recipeCopy.styleId].color,
      }"
    >
      {{ brew.count }}
      {{ brew.recipeCopy.name }}
    </div>
  </template>
  <template v-if="type == TANK.BUFFER">
    <div
      class="brew text-truncate p-1 small"
      role="button"
      @click="handleClick(brew.id)"
      v-for="(brew, index) in tank"
      :key="index"
      :style="{
        zIndex: index,
        width: daysToProcent(brew.bufferDays) + '%',
        left: procentFromStart(end(brew.id)) + '%',
        backgroundColor: styles[brew.recipeCopy.styleId].color,
      }"
    >
      {{ brew.count }}
      {{ brew.recipeCopy.name }}
    </div>
  </template>
  <div
    class="grid-lines"
    v-for="(line, index) in procentWeekGrid()"
    :key="index"
    :style="{ left: line + '%' }"
  />
  <div
    class="grid-weekend"
    v-for="(line, index) in procentWeekGrid()"
    :key="index"
    :style="{
      left: line - daysToProcent(2) + '%',
      width: daysToProcent(2) + '%',
    }"
  />
  <div class="now" :style="{ left: procentFromNow() + '%', width: daysToProcent(1) + '%' }" />
  <div
    class="form text-truncate p-1 small"
    v-if="formInTank"
    :style="{
      zIndex: 100,
      width: daysToProcent(formFermentationDays) + '%',
      left: procentFromStart(form.start) + '%',
    }"
  >
    {{ form.recipeCopy ? form.recipeCopy.name : "New Brew" }}
  </div>
</template>

<script>
import { differenceInCalendarDays, addDays } from "date-fns"
import { TANK } from "../../services/enums"
import { useStore } from "@/store/store"

export default {
  name: "BrewCell",
  props: ["tank", "startTime", "spreed", "form", "tankId", "type"],
  setup() {
    return {
      TANK,
      brews: useStore().state.collections.brews,
      styles: useStore().state.collections.styles,
    }
  },
  computed: {
    tatalDays() {
      return (this.spreed * 2 + 1) * 7
    },
    formInTank() {
      if (this.form) {
        if (this.tankId == this.form.tankId && this.form.recipeCopy) return true
      }
      return false
    },
    formFermentationDays() {
      return this.form.recipeCopy ? this.form.recipeCopy.fermentationDays : null
    },
  },
  methods: {
    end(brewId) {
      return addDays(
        this.brews[brewId].start.toDate(),
        this.brews[brewId].recipeCopy.fermentationDays
      )
    },
    // fermentationDays(recipeId) {
    //   return this.recipes[recipeId].fermentationDays
    // },
    daysFromStart(brewStart) {
      return differenceInCalendarDays(brewStart, this.startTime)
    },
    daysToProcent(days) {
      if (days < 0) days = 0
      return (days / this.tatalDays) * 100
    },
    brwingTime(start, end) {
      return differenceInCalendarDays(end, start) + 1
    },
    procentFromStart(brewStart) {
      return this.daysToProcent(this.daysFromStart(brewStart)) - 1
    },
    peocentBrewingTime(start, end) {
      return this.daysToProcent(this.brwingTime(start, end))
    },
    procentWeekGrid() {
      let weeks = this.spreed * 2 + 1
      let oneWeek = 1 / weeks
      let grid = []
      for (let i = 0; i < weeks; i++) {
        grid.push(oneWeek * i * 100)
      }
      return grid
    },
    daysFromNow() {
      return differenceInCalendarDays(new Date(), this.startTime) - 1
    },
    procentFromNow() {
      return this.daysToProcent(this.daysFromNow())
    },
    handleClick(id) {
      this.$router.push({ name: "BrewDetail", params: { id: id } })
    },
  },
}
</script>

<style scoped>
.brew {
  position: absolute;
  border: 2px solid white;
}
.form {
  position: absolute;
  border: 1px dashed rgb(197, 29, 29);
  background-color: rgba(255, 255, 255, 0.8);
}
.grid-lines {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  height: 0px;
  position: absolute;
  z-index: -1;
}
.now {
  /* border-left: 5px solid rgba(160, 9, 9, 0.2); */
  background-color: rgba(160, 9, 9, 0.2);
  height: 50px;
  /* width: 10px; */
  position: absolute;
  z-index: 10;
}
.grid-weekend {
  background-color: rgba(0, 0, 0, 0.05);
  height: 10px;
  position: absolute;
  z-index: -2;
}
</style>
