<template>
  <td>
    <template v-if="item.recipeName">
      {{ item.recipeName }}
    </template>
    <template v-else>
      <Button @click="del()">trash</Button>
    </template>
  </td>
  <td>{{ item.quantity }} {{ unitText }}</td>
  <td>{{ item.createdDate }}</td>
</template>

<script>
// import comp from "../components/base"
// import { format } from "date-fns"
import Button from "../../components/base/Button.vue"
import { apiSetIngredient } from "../../services/api"
import { useStore } from "@/store/store"
import { cloneDeep } from "lodash"

export default {
  components: { Button },
  name: "IngredientInventoryItem",
  props: ["item", "index", "popIngredient", "id", "unitText"],
  computed: {
    // tiem() {
    //   return this.item.created ? format(this.item.created.toDate(), "dd/MM/yyyy") : "-"
    // },
  },
  methods: {
    del() {
      let local = cloneDeep(useStore().state.collections.ingredients[this.id])
      local.inventory.splice(this.index, 1)
      apiSetIngredient(local)
    },
  },
}
</script>
