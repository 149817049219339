import { BREW, SUPPLIER, CONTAINER, UNIT, CATEGORY, ORDER, PROFILE, ROLE } from "./enums"
import { generateStyleColor } from "../helper/helper"

import firestore from "firebase/app/"
import { Base, Brew, Container, Ingredient, Recipe, User } from "@/typings/types"

const initBase: Base = {
  id: "",
  created: undefined,
  // createdDate: undefined,
  createdText: "",
  // updated: undefined,
  // updatedDate: undefined,
  updatedText: "",
  userCreated: "",
  userUpdated: "",
}

export const initUser: User = {
  ...initBase,
  name: "",
  lastLogOn: null,
  role: ROLE.BREWER,
}

export const initProfile = {
  ...initBase,
  name: "",
  type: PROFILE.MASHING,
  totalTime: null,
  items: [],
}

export const initOrder = {
  id: "",
  buyerId: null,
  inventory: [],
  state: ORDER.PLANING,
  shipping: null,
  count: 0,
}

export const initBrewery = {
  id: null,
  name: null,
  efficiency: null,
  evaporation: null,
  unit: UNIT.L,
  size: null,
}

export const initTank = {
  name: "",
  id: "",
  size: null,
  short: null,
  unit: UNIT.L,
  // type: TANK.FERMENTATION,
}

export const initContainer: Container = {
  ...initBase,
  name: "",
  quantity: 0,
  state: CONTAINER.PLANING,
  supplierId: "",
  size: 0,
  unit: UNIT.L,
  inventory: [],
  brewIds: [],
}

export const initIngredient: Ingredient = {
  ...initBase,
  name: "",
  ebc: 0,
  aa: 0,
  ppg: 0,
  attenuation: 0,
  category: CATEGORY.MALT,
  unit: UNIT.KG,
  inventory: [],
  supplierId: "",
  brewIds: [],
  recipeIds: [],
}

export const initRecipe: Recipe = {
  ...initBase,
  name: "",
  styleId: "",
  breweryId: "",
  batchSize: 1000,
  mashing: {
    items: [],
    profileId: "",
  },
  kettle: {
    items: [],
    totalTime: 0,
  },
  fermentation: {
    items: [],
    profileId: "",
  },
  increment: 0,
}

export const initBrew: Brew = {
  ...initBase,
  quantity: 0,
  state: BREW.PLANNING,
  start: firestore.firestore.Timestamp.fromDate(new Date()),
  containers: [],
  orderIds: [],
  tankId: "",
  fermentationLog: [],
  count: 0,
  recipeCopy: initRecipe,
  preBoilVolume: {
    temperature: null,
    volume: null,
    done: null,
    time: null,
  },
  postBoilVolume: {
    temperature: null,
    volume: null,
    done: null,
    time: null,
  },
}

export const initStyle = {
  name: "",
  id: "",
  color: generateStyleColor(),
}

export const initSupplier = {
  name: "",
  id: "",
  phone: "",
  state: SUPPLIER.ACTIVE,
  address1: "",
  address2: "",
  city: "",
  zip: "",
  country: "",
}

export const initBuyer = {
  name: "",
  id: "",
  phone: "",
  state: SUPPLIER.ACTIVE,
  address1: "",
  address2: "",
  city: "",
  zip: "",
  country: "",
  increment: 0,
}
