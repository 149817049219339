<template>
  <div
    class="toast align-items-center shadow-sm border-1  show"
    :class="{ 'border-danger': error, 'border-success': !error }"
  >
    <div class="d-flex">
      <div class="toast-body p-2 d-flex align-items-center">
        <div v-if="error" class="text-danger">
          <BIconHandThumbsDown />
        </div>
        <div v-else class="text-success">
          <BIconHandThumbsUp />
        </div>
        <small class="mx-3">
          {{ message.message }}
        </small>
      </div>
      <button type="button" class="btn-close me-2 m-auto" @click="messageRemove(message)"></button>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store/store"
import icons from "@/assets/icons"
import { MESSAGE } from "../../services/enums"
import { ActionTypes } from "@/store/actionTypesEnums"
import { defineComponent, PropType } from "@vue/runtime-core"
import { Message } from "@/typings/types"

export default defineComponent({
  name: "Message",
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
  components: {
    ...icons,
  },
  setup() {
    return {}
  },
  data() {
    return {
      timeOut: 0,
    }
  },
  created() {
    this.timeOut = setTimeout(this.messageRemove, 4000, this.message)
    this.message?.type
  },
  beforeUnmount() {
    this.timeOut = 0
  },
  methods: {
    messageRemove(message: Message) {
      useStore().dispatch(ActionTypes.MessageRemove, message)
    },
  },
  computed: {
    error(): boolean {
      if (this.message) {
        let error =
          this.message.type === MESSAGE.ERROR ||
          this.message.type === MESSAGE.FORM_ERROR ||
          this.message.type === MESSAGE.SET_ERROR ||
          this.message.type === MESSAGE.GET_ERROR
        return error
      }
      return false
    },
  },
})
</script>
