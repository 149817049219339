<template>
  <button
    @click="$emit('update:modelValue', !this.modelValue)"
    class="btn text-dark btn-sm mx-2"
    :class="modelValue ? 'btn-outline-success' : 'btn-outline-secondary'"
    type="button"
  >
    <BIconCheckCircle v-if="modelValue" style="color:green" />
    <BIconCircle v-else />
  </button>
</template>

<script>
import icons from "../../assets/icons"

export default {
  name: "ButtonCheck",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  components: {
    ...icons,
  },
}
</script>
