<template>
  <div class="btn-group btn-group-sm">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ButtonGroup",
}
</script>
