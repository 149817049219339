<template>
  <div class="row">
    <div class="col-lg order-lg-2">
      <slot name="right" />
    </div>
    <div class="col-lg-8 order-lg-1">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageContent",
}
</script>
