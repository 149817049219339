<template>
  <StyleForm v-if="modals.style" :id="modals.styleId" />
  <SupplierForm v-if="modals.supplier" :id="modals.supplierId" />
  <TankForm v-if="modals.tank" :id="modals.tankId" />
  <RecipeForm v-if="modals.recipe" :id="modals.recipeId" />
  <BrewForm v-if="modals.brew" :id="modals.brewId" />
  <BuyerForm v-if="modals.buyer" :id="modals.buyerId" />
  <OrderForm v-if="modals.order" :id="modals.orderId" />
  <ProfileForm v-if="modals.profile" :id="modals.profileId" />
  <BreweryForm v-if="modals.brewery" :id="modals.breweryId" />
  <IngredientForm v-if="modals.ingredient" :id="modals.ingredientId" />
  <IngredientInventoryForm v-if="modals.ingredientInventory" :id="modals.ingredientInventoryId" />
  <ContainerForm v-if="modals.container" :id="modals.containerId" />
  <ContainerInventoryForm v-if="modals.containerInventory" :id="modals.containerInventoryId" />

  <UserForm v-if="modals.user" />

  <Delete v-if="deleteModal.id" :id="deleteModal.id" :type="deleteModal.type" />
</template>

<script>
import StyleForm from "./StyleForm.vue"
import SupplierForm from "./SupplierForm.vue"
import TankForm from "./TankForm.vue"
import RecipeForm from "./RecipeForm.vue"
import BrewForm from "./BrewForm.vue"
import BuyerForm from "./BuyerForm.vue"
import OrderForm from "./OrderForm.vue"
import UserForm from "./UserForm.vue"
import ProfileForm from "./ProfileForm.vue"
import BreweryForm from "./BreweryForm.vue"
import IngredientForm from "./IngredientForm.vue"
import IngredientInventoryForm from "./IngredientInventoryForm.vue"
import ContainerForm from "./ContainerForm.vue"
import ContainerInventoryForm from "./ContainerInventoryForm.vue"
import Delete from "./Delete.vue"

import { mapState } from "vuex"
export default {
  name: "Modals",
  components: {
    StyleForm,
    SupplierForm,
    TankForm,
    RecipeForm,
    BrewForm,
    BuyerForm,
    OrderForm,
    UserForm,
    ProfileForm,
    BreweryForm,
    IngredientForm,
    IngredientInventoryForm,
    ContainerForm,
    ContainerInventoryForm,
    Delete,
  },
  computed: {
    ...mapState(["modals"]),
    ...mapState(["deleteModal"]),
  },
}
</script>
