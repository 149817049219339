<template>
  <div class="row lead my-2">
    <div class="col-2">{{ format(centerDate, "yyyy") }}</div>
    <div class="col">
      <div class="row g-0">
        <div
          role="button"
          class="col text-center"
          v-for="(week, index) in timeArray"
          :key="index"
          @click="centerDate = week"
        >
          <div class="week-row">
            {{ getWeek(week) }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <TankRow v-for="(tank, index) in sortBrewByTank(TANK.FERMENTATION)" :key="index" :tankId="index">
    <BrewCell
      :type="TANK.FERMENTATION"
      :tank="tank"
      :startTime="this.timeArray[0]"
      :spreed="spreed"
      :form="form"
      :tankId="index"
    />
  </TankRow>

  <TankRow v-for="(tank, index) in sortBrewByTank(TANK.BUFFER)" :key="index" :tankId="index">
    <BrewCell
      :type="TANK.BUFFER"
      :tank="tank"
      :startTime="this.timeArray[0]"
      :spreed="spreed"
      :form="form"
      :tankId="index"
    />
  </TankRow>
</template>

<script>
import comp from "../base"
import TankRow from "./TankRow.vue"
import BrewCell from "./BrewCell.vue"
import { TANK } from "../../services/enums"
import { addDays, addWeeks, format, getWeek } from "date-fns"
import { computed } from "@vue/runtime-core"
import { useStore } from "@/store/store"

export default {
  name: "TimeChart",
  props: ["form"],
  components: {
    ...comp,
    TankRow,
    BrewCell,
  },
  data() {
    return {
      centerDate: addDays(new Date(), 0),
      spreed: 5,
      formBrew: null,
    }
  },
  setup() {
    return {
      format,
      getWeek,
      TANK,
      brews: computed(() => useStore().state.collections.brews),
      tanks: computed(() => useStore().state.collections.tanks),
    }
  },
  methods: {
    offset() {
      let range = []
      for (let i = -this.spreed; i <= this.spreed; i++) {
        range.push(i)
      }
      return range
    },
    sortBrewByTank(tankType) {
      let brewByTanks = {}
      let brews = Object.values(this.getBrewInTimeSlot)
      let tankId = this.tanks
        ? Object.values(this.tanks)
            .filter(item => item.type == tankType)
            .map(item => item.id)
        : []

      tankId.forEach(id => (brewByTanks[id] = []))
      brews.forEach(item => {
        if (tankId.includes(item.tankId)) brewByTanks[item.tankId].push(item)
        if (tankId.includes(item.bufferTankId)) brewByTanks[item.bufferTankId].push(item)
      })
      return brewByTanks
    },
  },
  computed: {
    timeArray() {
      let weeks = this.offset(this.spreed).map(item => {
        return addWeeks(this.centerDate, item)
      })
      return weeks
    },
    getBrewInTimeSlot() {
      let startDate = this.timeArray[0]
      let endDate = this.timeArray[this.timeArray.length - 1]

      let allBrews = useStore().getters.popBrews
      let brews = allBrews.filter(item => {
        if (
          (item.startDate > startDate && item.startDate < endDate) |
          (item.end > startDate && item.end < endDate)
        ) {
          return true
        }
      })
      return brews
    },
  },
}
</script>

<style>
.week-row {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
