<template>
  <td>
    <template v-if="item.recipeName">
      {{ item.recipeName }}
    </template>
    <template v-else>
      <Button @click="del()">trash</Button>
    </template>
  </td>

  <td>{{ item.quantity }}</td>
  <td>{{ item.createdDate }}</td>
</template>

<script>
import Button from "../../components/base/Button.vue"
import { apiSetContainer } from "../../services/api"
import cloneDeep from "clone-deep"
import { useStore } from "@/store/store"

export default {
  components: { Button },
  name: "ContainerInventoryItem",
  props: ["item", "index", "container", "id"],
  methods: {
    del() {
      let local = cloneDeep(useStore().state.collections.containers[this.id])
      local.inventory.splice(this.index, 1)
      apiSetContainer(local)
    },
  },
}
</script>
