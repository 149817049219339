<template>
  <input
    class="form-control"
    :class="{
      'is-valid': valid == true && touched,
      'is-invalid': valid == false && touched,
    }"
    :type="type"
    :placeholder="placeholder"
    :value="modelValue"
    @input="emitInput($event.target.value)"
  />
</template>

<script>
import { validStr } from "../../services/validation"
import { store } from "../../store/store"
export default {
  name: "FormInputLable",
  emits: ["update:modelValue"],
  props: {
    modelValue: [Object, Array, String, Number],
    placeholder: String,
    type: {
      type: String,
      default: "text",
    },
    validateion: {
      type: Function,
      default: validStr,
    },
  },
  data() {
    return {
      touched: false,
    }
  },
  computed: {
    valid() {
      return this.validateion(this.modelValue)
    },
  },
  methods: {
    emitInput(value) {
      this.touched = true
      this.$emit("update:modelValue", value)
    },
    validate() {
      this.touched = true
      store.dispatch("pushValid", this.validateion(this.modelValue))
    },
    unTouched() {
      this.touched = false
      this.$emit("update:modelValue", null)
    },
  },
}
</script>
