<template>
  <div class="d-flex justify-content-between mt-3 mb-2 col ">
    <div class="small">
      <slot />
    </div>
    <slot name="right" />
  </div>
</template>

<script>
export default {
  name: "Lable",
}
</script>
