<template>
  <ModalBase :close="closeRecipe">
    <template #header>Recipe Form</template>

    <Row>
      <Lable>name *</Lable>
      <Lable>
        style *
        <template #right>
          <BadgeBtn @click="openStyle()"> + Style</BadgeBtn>
        </template>
      </Lable>
    </Row>

    <Row class="mb-3">
      <BCol>
        <FormInput ref="formName" placeholder="Recipe Name" v-model="recipe.name" />
      </BCol>
      <BCol>
        <FormSelect
          ref="formStyle"
          :items="styles"
          v-model="recipe.styleId"
          :valueCallback="item => item.id"
        />
      </BCol>
    </Row>

    <Row class="my-3">
      <Button lg="true" class="col mx-1" @click="add()">Save</Button>
    </Row>
  </ModalBase>
</template>

<script>
import comp from "../base"
import { initRecipe } from "../../services/objects"
import { mapActions } from "vuex"
import cloneDeep from "clone-deep"
import { apiSetRecipe } from "@/services/api"
import { checkValidity, formError } from "../../services/validation"

import icons from "../../assets/icons"
import ModalBase from "./ModalBase.vue"
import { useStore } from "@/store/store"
import { computed } from "@vue/runtime-core"

export default {
  name: "Recipe",
  props: ["id"],
  components: {
    ...comp,
    ...icons,
    ModalBase,
  },
  setup() {
    return {
      styles: computed(() => useStore().getters.styles),
    }
  },
  data() {
    return {
      recipe: cloneDeep(initRecipe),
    }
  },
  // computed: {
  //   ...mapState(["styles"]),
  // },
  methods: {
    ...mapActions(["openStyle", "closeRecipe"]),
    add() {
      if (checkValidity(this.$refs)) {
        let id = apiSetRecipe(this.recipe).id
        this.$router.push({ name: "RecipeSheet", params: { id: id } })
        this.closeRecipe()
      } else formError()
    },
  },
}
</script>
