<template>
  <div class="d-flex justify-content-between align-items-end">
    <div class="fs-4 ">
      <slot />
    </div>
    <div>
      <slot name="right" />
    </div>
  </div>
  <hr class="my-2 mb-3" />
</template>

<script>
export default {
  Name: "ModalHeader",
}
</script>

<style></style>
