<template>
  <Container>
    <div class=" p-5 modal-con ">
      <slot />
    </div>
  </Container>
</template>

<script>
import comp from "@/components/base"

export default {
  name: "ModalContent",
  components: {
    ...comp,
  },
}
</script>

<style scoped>
.modal-con {
  margin-top: 15%;
  margin-bottom: 5%;
  /* overflow: hidden; */
  /* z-index: 101; */
  background-color: #ffffff;
  /* margin: 15% auto; */
  /* padding: 20px; */
  /* border: 1px solid #888; */
  /* width: 80%; */
}
</style>
