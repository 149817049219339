import {
  Breweries,
  Brews,
  Buyers,
  Containers,
  Ingredients,
  Orders,
  Profiles,
  Recipes,
  Styles,
  Suppliers,
  Tanks,
} from "@/typings/types"
import { ActionTree, MutationTree } from "vuex"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, RootState } from "../store"
import { LoadState } from "./loadState"

export interface CollectionState {
  brews: Brews
  tanks: Tanks
  suppliers: Suppliers
  styles: Styles
  recipes: Recipes
  buyers: Buyers
  orders: Orders
  profiles: Profiles
  breweries: Breweries
  ingredients: Ingredients
  containers: Containers
}

const state: CollectionState = {
  brews: {},
  containers: {},
  ingredients: {},
  orders: {},
  recipes: {},
  breweries: {},
  buyers: {},
  profiles: {},
  tanks: {},
  suppliers: {},
  styles: {},
}

export type CollectionMutations = {
  [MutationType.SetBreweries](state: CollectionState, payload: Breweries): void
  [MutationType.SetOrders](state: CollectionState, payload: Orders): void
  [MutationType.SetBuyers](state: CollectionState, payload: Buyers): void
  [MutationType.SetRecipes](state: CollectionState, payload: Recipes): void
  [MutationType.SetBrews](state: CollectionState, payload: Brews): void
  [MutationType.SetTanks](state: CollectionState, payload: Tanks): void
  [MutationType.SetSuppliers](state: CollectionState, payload: Suppliers): void
  [MutationType.SetStyles](state: CollectionState, payload: Styles): void
  [MutationType.SetProfiles](state: CollectionState, payload: Profiles): void
  [MutationType.SetIngredients](state: CollectionState, payload: Ingredients): void
  [MutationType.SetContainers](state: CollectionState, payload: Containers): void
}

export const mutations: MutationTree<CollectionState> & CollectionMutations = {
  [MutationType.SetBreweries](state, payload) {
    state.breweries = payload
  },
  [MutationType.SetOrders](state, payload) {
    state.orders = payload
  },
  [MutationType.SetBuyers](state, payload) {
    state.buyers = payload
  },
  [MutationType.SetRecipes](state, payload) {
    state.recipes = payload
  },
  [MutationType.SetBrews](state, payload) {
    state.brews = payload
  },
  [MutationType.SetTanks](state, payload) {
    state.tanks = payload
  },
  [MutationType.SetSuppliers](state, payload) {
    state.suppliers = payload
  },
  [MutationType.SetStyles](state, payload) {
    state.styles = payload
  },
  [MutationType.SetProfiles](state, payload) {
    state.profiles = payload
  },
  [MutationType.SetIngredients](state, payload) {
    state.ingredients = payload
  },
  [MutationType.SetContainers](state, payload) {
    state.containers = payload
  },
}

export type CollectionActions = {
  [ActionTypes.LoadBreweries](context: ActionAugments, payload: Breweries): void
  [ActionTypes.LoadBrews](context: ActionAugments, payload: Brews): void
  [ActionTypes.LoadOrders](context: ActionAugments, payload: Orders): void
  [ActionTypes.LoadBuyers](context: ActionAugments, payload: Buyers): void
  [ActionTypes.LoadRecipes](context: ActionAugments, payload: Recipes): void
  [ActionTypes.LoadTanks](context: ActionAugments, payload: Tanks): void
  [ActionTypes.LoadSuppliers](context: ActionAugments, payload: Suppliers): void
  [ActionTypes.LoadStyles](context: ActionAugments, payload: Styles): void
  [ActionTypes.LoadProfiles](context: ActionAugments, payload: Profiles): void
  [ActionTypes.LoadIngredients](context: ActionAugments, payload: Ingredients): void
  [ActionTypes.LoadContainers](context: ActionAugments, payload: Containers): void
}

export const actions: ActionTree<RootState, RootState> & CollectionActions = {
  [ActionTypes.LoadBreweries]({ commit }, payload) {
    commit(MutationType.SetBreweries, payload)
    commit(MutationType.SetBreweriesLoadState, LoadState.LOADED)
  },
  [ActionTypes.LoadBrews]({ commit }, payload) {
    commit(MutationType.SetBrews, payload)
    commit(MutationType.SetBrewsLoadState, LoadState.LOADED)
  },
  [ActionTypes.LoadOrders]({ commit }, payload) {
    commit(MutationType.SetOrders, payload)
    commit(MutationType.SetOrdersLoadState, LoadState.LOADED)
  },
  [ActionTypes.LoadBuyers]({ commit }, payload) {
    commit(MutationType.SetBuyers, payload)
    commit(MutationType.SetBuyersLoadState, LoadState.LOADED)
  },
  [ActionTypes.LoadRecipes]({ commit }, payload) {
    commit(MutationType.SetRecipes, payload)
    commit(MutationType.SetRecipesLoadState, LoadState.LOADED)
  },
  [ActionTypes.LoadTanks]({ commit }, payload) {
    commit(MutationType.SetTanks, payload)
    commit(MutationType.SetTanksLoadState, LoadState.LOADED)
  },
  [ActionTypes.LoadSuppliers]({ commit }, payload) {
    commit(MutationType.SetSuppliers, payload)
    commit(MutationType.SetSuppliersLoadState, LoadState.LOADED)
  },
  [ActionTypes.LoadStyles]({ commit }, payload) {
    commit(MutationType.SetStyles, payload)
    commit(MutationType.SetStylesLoadState, LoadState.LOADED)
  },
  [ActionTypes.LoadProfiles]({ commit }, payload) {
    commit(MutationType.SetProfiles, payload)
    commit(MutationType.SetProfilesLoadState, LoadState.LOADED)
  },
  [ActionTypes.LoadIngredients]({ commit }, payload) {
    commit(MutationType.SetIngredients, payload)
    commit(MutationType.SetIngredientsLoadState, LoadState.LOADED)
  },
  [ActionTypes.LoadContainers]({ commit }, payload) {
    commit(MutationType.SetContainers, payload)
    commit(MutationType.SetContainersLoadState, LoadState.LOADED)
  },
}

const getters = {}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
