import { base } from "@/helper/helper"
import { Brewery, Buyer, Profile, Style, Supplier, Tank, Tanks } from "@/typings/types"
import { cloneDeep } from "lodash"
import { GetterTree } from "vuex"
import { RootState, useStore } from "../store"

//Types
export type UtilitiesGetters = {
  tank(state: RootState): (id: string) => Tank
  tanks(): Tank[]
  supplier(state: RootState): (id: string) => Supplier
  suppliers(): Supplier[]
  style(state: RootState): (id: string) => Style
  styles(): Style[]
  buyer(state: RootState): (id: string) => Buyer
  buyers(): Buyer[]
  profile(state: RootState): (id: string) => Profile
  profiles(): Profile[]
  brewery(state: RootState): (id: string) => Brewery
  breweries(): Brewery[]
}

//Getters
export const utilitiesGetters: GetterTree<RootState, RootState> & UtilitiesGetters = {
  tank: state => id => {
    let tank = cloneDeep(state.collections.tanks[id])
    tank = base(tank)
    return tank
  },
  tanks: () => {
    let tank = Object.values(useStore().state.collections.tanks)
    tank = tank.map(item => useStore().getters.tank(item.id))
    return tank
  },
  supplier: state => id => {
    let supplier = cloneDeep(state.collections.suppliers[id])
    supplier = base(supplier)
    return supplier
  },
  suppliers: () => {
    let supplier = Object.values(useStore().state.collections.suppliers)
    supplier = supplier.map(item => useStore().getters.supplier(item.id))
    return supplier
  },
  style: state => id => {
    let style = cloneDeep(state.collections.styles[id])
    style = base(style)
    return style
  },
  styles: () => {
    let style = Object.values(useStore().state.collections.styles)
    style = style.map(item => useStore().getters.style(item.id))
    return style
  },
  buyer: state => id => {
    let buyer = cloneDeep(state.collections.buyers[id])
    buyer = base(buyer)
    return buyer
  },
  buyers: () => {
    let buyer = Object.values(useStore().state.collections.buyers)
    buyer = buyer.map(item => useStore().getters.buyer(item.id))
    return buyer
  },
  profile: state => id => {
    let profile = cloneDeep(state.collections.profiles[id])
    profile = base(profile)
    return profile
  },
  profiles: () => {
    let profile = Object.values(useStore().state.collections.profiles)
    profile = profile.map(item => useStore().getters.profile(item.id))
    return profile
  },
  brewery: state => id => {
    let brewery = cloneDeep(state.collections.breweries[id])
    brewery = base(brewery)
    return brewery
  },
  breweries: () => {
    let breweries = Object.values(useStore().state.collections.breweries)
    breweries = breweries.map(item => useStore().getters.brewery(item.id))
    return breweries
  },
}
