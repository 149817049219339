<template>
  <div class="lead row mb-4">
    <div class="col-3">
      <b>
        {{ sub }}
      </b>
    </div>
    <div class="col">
      <slot />
    </div>
  </div>
</template>

<script>
import comp from "@/components/base"
export default {
  name: "DetailElement",
  props: ["sub"],
  components: {
    ...comp,
  },
}
</script>
