<template>
  <button
    class="btn btn-outline-secondary text-dark"
    type="button"
    v-bind:class="{ 'btn-sm': !lg }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseButtom",
  props: ["lg"],
}
</script>
