<template>
  <li class="list-group-item">
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: "ListItem",
}
</script>
