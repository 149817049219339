import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"

import Home from "../views/Home.vue"
import Login from "../views/Login.vue"

import { store } from "../store/store"

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/containers",
    name: "Containers",
    component: () => import(/* webpackChunkName: "Selling" */ "../views/Containers.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/selling",
    name: "Selling",
    component: () => import(/* webpackChunkName: "Selling" */ "../views/Selling.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/planning",
    name: "Planning",
    component: () => import(/* webpackChunkName: "Planning" */ "../views/Planning.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/brewing",
    name: "Brewing",
    component: () => import(/* webpackChunkName: "Brewing" */ "../views/Brewing.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/tapping",
    name: "Tapping",
    component: () => import(/* webpackChunkName: "Tapping" */ "../views/Tapping.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/fermenting",
    name: "Fermenting",
    component: () => import(/* webpackChunkName: "Fermenting" */ "../views/Fermenting.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/ingredients",
    name: "Ingredients",
    component: () => import(/* webpackChunkName: "Ingredients" */ "../views/Ingredients.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/recipe/recipe_sheet/:id",
    name: "RecipeSheet",
    component: () => import(/* webpackChunkName: "RecipeDetail" */ "../views/RecipeSheet.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/user_detail",
    name: "UserDetail",
    component: () => import(/* webpackChunkName: "UserDetail" */ "../views/UserDetail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/brewing/brew_sheet/:id",
    name: "BrewSheet",
    component: () =>
      import(/* webpackChunkName: "BrewSheet" */ "../components/brewing/BrewSheet.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/brewing/fermentation_sheet/:id",
    name: "FermentationSheet",
    component: () =>
      import(
        /* webpackChunkName: "FermentationSheet" */ "../components/fermenting/FermentationSheet.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/brewing/selling_sheet/:id",
    name: "SellingSheet",
    component: () =>
      import(/* webpackChunkName: "SellingSheet" */ "../components/selling/SellingSheet.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/brewing/tap_sheet/:id",
    name: "TapSheet",
    component: () =>
      import(/* webpackChunkName: "TapSheet" */ "../components/tapping/TapSheet.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/brews",
    name: "Brews",
    component: () => import(/* webpackChunkName: "Brew" */ "../views/Brews.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/brew/detail/:id",
    name: "BrewDetail",
    component: () => import(/* webpackChunkName: "BrewDetail" */ "../views/BrewDetail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/tanks",
    name: "Tanks",
    component: () => import(/* webpackChunkName: "Tanks" */ "../views/Tanks.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/suppliers",
    name: "Suppliers",
    component: () => import(/* webpackChunkName: "Suppliers" */ "../views/Suppliers.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/styles",
    name: "Styles",
    component: () => import(/* webpackChunkName: "Styles" */ "../views/Styles.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/recipes",
    name: "Recipes",
    component: () => import(/* webpackChunkName: "Recipes" */ "../views/Recipes.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/buyers",
    name: "Buyers",
    component: () => import(/* webpackChunkName: "Buyers" */ "../views/Buyers.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/profiles",
    name: "Profiles",
    component: () => import(/* webpackChunkName: "Profiles" */ "../views/Profiles.vue"),
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user.uid) {
      next({
        name: "Home",
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
