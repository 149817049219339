<template>
  <div class="progress" :style="{ height: hight + 'px' }">
    <div class="progress-bar" role="progressbar" :style="{ width: value + '%' }"></div>
  </div>
</template>

<script>
export default {
  Name: "ProgressBar",
  props: ["value", "hight"],
}
</script>

<style></style>
