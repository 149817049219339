import { Message } from "@/typings/types"
import { ActionTree, GetterTree, Module, MutationTree } from "vuex"
import { MESSAGE } from "../../services/enums"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, RootState } from "../store"

let messageId = 1

export interface MessageState {
  messages: Message[]
}

const state: MessageState = {
  messages: [],
}

export type MessageMutations = {
  [MutationType.PushMessage](state: MessageState, payload: Message): void
  [MutationType.DeleteMessage](state: MessageState, payload: Message): void
}

const mutations: MutationTree<MessageState> & MessageMutations = {
  [MutationType.PushMessage](state, message) {
    state.messages.push(message)
  },
  [MutationType.DeleteMessage](state, messageToDelete) {
    state.messages = state.messages.filter(message => message.id != messageToDelete.id)
  },
}

export type MessageActions = {
  [ActionTypes.MessageSuccess](context: ActionAugments, payload: string): void
  [ActionTypes.MessageSetSuccess](context: ActionAugments, payload: string): void
  [ActionTypes.MessageGetSuccess](context: ActionAugments, payload: string): void
  [ActionTypes.MessageFormSuccess](context: ActionAugments, payload: string): void
  [ActionTypes.MessageError](context: ActionAugments, payload: string): void
  [ActionTypes.MessageSetError](context: ActionAugments, payload: string): void
  [ActionTypes.MessageGetError](context: ActionAugments, payload: string): void
  [ActionTypes.MessageFormError](context: ActionAugments, payload: string): void
  [ActionTypes.MessageRemove](context: ActionAugments, payload: Message): void
}

const actions: ActionTree<RootState, RootState> & MessageActions = {
  [ActionTypes.MessageSuccess]({ commit }, text) {
    const obj: Message = {
      type: MESSAGE.SUCCESS,
      message: text ? text : MESSAGE.TEXT[MESSAGE.SUCCESS],
      id: messageId++,
    }
    commit(MutationType.PushMessage, obj)
  },
  [ActionTypes.MessageSetSuccess]({ commit }, text) {
    const obj: Message = {
      type: MESSAGE.SET_SUCCESS,
      message: text ? text : MESSAGE.TEXT[MESSAGE.SET_SUCCESS],
      id: messageId++,
    }
    commit(MutationType.PushMessage, obj)
  },
  [ActionTypes.MessageGetSuccess]({ commit }, text) {
    const obj: Message = {
      type: MESSAGE.GET_SUCCESS,
      message: text ? text : MESSAGE.TEXT[MESSAGE.GET_SUCCESS],
      id: messageId++,
    }
    commit(MutationType.PushMessage, obj)
  },
  [ActionTypes.MessageFormSuccess]({ commit }: any, text: string) {
    const obj: Message = {
      type: MESSAGE.FORM_SUCCESS,
      message: text ? text : MESSAGE.TEXT[MESSAGE.FORM_SUCCESS],
      id: messageId++,
    }
    commit(MutationType.PushMessage, obj)
  },

  [ActionTypes.MessageError]({ commit }: any, text: string) {
    const obj: Message = {
      type: MESSAGE.ERROR,
      message: text ? text : MESSAGE.TEXT[MESSAGE.ERROR],
      id: messageId++,
    }
    commit(MutationType.PushMessage, obj)
  },

  [ActionTypes.MessageSetError]({ commit }, text) {
    const obj: Message = {
      type: MESSAGE.SET_ERROR,
      message: text ? text : MESSAGE.TEXT[MESSAGE.SET_ERROR],
      id: messageId++,
    }
    commit(MutationType.PushMessage, obj)
  },

  [ActionTypes.MessageGetError]({ commit }, text) {
    const obj: Message = {
      type: MESSAGE.GET_ERROR,
      message: text ? text : MESSAGE.TEXT[MESSAGE.GET_ERROR],
      id: messageId++,
    }
    commit(MutationType.PushMessage, obj)
  },

  [ActionTypes.MessageFormError]({ commit }, text) {
    const obj: Message = {
      type: MESSAGE.FORM_ERROR,
      message: text ? text : MESSAGE.TEXT[MESSAGE.FORM_ERROR],
      id: messageId++,
    }
    commit(MutationType.PushMessage, obj)
  },

  [ActionTypes.MessageRemove]({ commit }, payload: Message) {
    commit(MutationType.DeleteMessage, payload)
  },
}

export type MessageGetters = {}

const getters: GetterTree<MessageState, RootState> = {}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
