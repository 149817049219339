<template>
  <table class="table table-hover localTable">
    <thead>
      <tr>
        <th v-for="(item, index) in header" :key="index">{{ item }}</th>
      </tr>
    </thead>
    <tbody>
      <slot />
    </tbody>
    <tfoot>
      <tr class="fw-bold">
        <template v-if="footer">
          <td v-for="(foot, index) in footer" :key="index">
            {{ foot }}
          </td>
        </template>

        <slot name="footer" />
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: "BTable",
  props: ["header", "footer"],
}
</script>
