<template>
  <ModelContainer @click.self="closeUser()">
    <ModalContent>
      <ModalHeader>
        User Form
        <template #right>
          <Button @click="closeUser()">close</Button>
        </template>
      </ModalHeader>

      <div class="my-3">
        <Row>
          <Lable>User name *</Lable>
        </Row>
        <Row>
          <BCol><FormInput ref="formUserName" type="text" v-model="displayName"/></BCol>
          <BCol>
            <FormSelect
              ref="formRole"
              :items="ROLE.TEXT"
              :strCallback="item => item"
              v-model="role"
            />
          </BCol>
          <Button class="mx-1 col-3" lg="true" @click="setDisplayName()">
            Save display user name
          </Button>
        </Row>
      </div>

      <div class="my-3">
        <Row>
          <Lable>Password 1 *</Lable>
          <Lable>Password 2 *</Lable>
          <BCol class="col-3"></BCol>
        </Row>
        <Row>
          <BCol>
            <FormInput
              :validateion="validPass"
              :ref="'formPassword1'"
              type="password"
              v-model="password1"
            />
          </BCol>
          <BCol>
            <FormInput
              :validateion="validPass"
              ref="formPassword2"
              type="password"
              v-model="password2"
            />
          </BCol>
          <Button class="mx-1 col-3" lg="true" @click="setPassword()"> Save password </Button>
        </Row>
      </div>
    </ModalContent>
  </ModelContainer>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import comp from "../base"
import ModalContent from "./ModalContent.vue"
import ModelContainer from "./ModalContainer.vue"
import ModalHeader from "./ModalHeader.vue"
import { checkValidity, formError, validPass, validNum } from "../../services/validation"
import { initUser } from "../../services/objects"
import { changePassword } from "../../services/auth"
import cloneDeep from "clone-deep"
import { apiSetUser } from "../../services/api"
import { ROLE } from "../../services/enums"
import { computed } from "@vue/runtime-core"
import { useStore } from "@/store/store"

export default {
  name: "UserForm",
  components: {
    ...comp,
    ModalContent,
    ModelContainer,
    ModalHeader,
  },
  data() {
    return {
      password1: null,
      password2: null,
      displayName: null,
      role: null,
    }
  },
  setup() {
    return {
      validPass,
      validNum,
      ROLE,
      users: computed(() => useStore().state.users.users),
    }
  },
  created() {
    if (this.user) {
      this.displayName = this.user.name
      this.role = this.user.role
    }
  },
  computed: {
    ...mapGetters(["uid"]),
    user() {
      return this.users[this.uid] ? cloneDeep(this.users[this.uid]) : null
    },
  },
  methods: {
    ...mapActions(["closeUser"]),
    ...mapActions(["messageError"]),
    setDisplayName() {
      if (checkValidity({ ...this.$refs.formUserName, ...this.$refs.formRole })) {
        let user = this.users[this.uid] ? cloneDeep(this.users[this.uid]) : cloneDeep(initUser)
        user.id = this.uid
        user.name = this.displayName
        user.role = this.role
        apiSetUser(user)
      } else formError()
    },
    setPassword() {
      if (checkValidity(this.$refs.formPassword1)) {
        if (this.password1 == this.password2) {
          changePassword(this.password1)
        } else {
          this.messageError("Passwords do not match")
        }
      } else formError()
    },
  },
}
</script>
