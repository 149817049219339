import { v4 as uuid } from "uuid"
import firebase from "firebase/app"
import "firebase/firestore"
import { store, useStore } from "../store/store"
// import { ACTION } from "./enums"
import {
  brewBeforeSave,
  containerBeforeSave,
  ingredientBeforeSave,
  recipeBeforeSave,
  orderBeforeSave,
  removeRef,
} from "./apiHelper/beforeSave"

import { afterBrewSave } from "./apiHelper/afterSave"
import { getUserUid } from "./auth"
import { ActionTypes } from "@/store/actionTypesEnums"
import {
  Brewery,
  Buyer,
  Container,
  Ingredient,
  Order,
  Profile,
  Recipe,
  Style,
  Supplier,
  Tank,
  User,
} from "@/typings/types"

////////////////////////////////
// FIREBASE SNAPSHOTS //////////
////////////////////////////////

let brewListener: () => void,
  utilityListener: () => void,
  orderListener: () => void,
  recipeListener: () => void,
  ingredientListener: () => void,
  containerListener: () => void

const subscribeSnapshot = function() {
  store.dispatch(ActionTypes.MessageSuccess, "Welcome! You signed in successfully")

  // setup snapshot listeners for alle the collections in the firestore database.
  brewListener = apiGetBrews()
  orderListener = apiGetOrders()
  recipeListener = apiGetRecipes()
  ingredientListener = apiGetContainers()
  containerListener = apiGetIngredients()
  utilityListener = apiGetUtility()
}

const unsubscribeSnapshot = function() {
  //clear the store.
  store.dispatch(ActionTypes.ResetLoad)

  //unsubscribe the firebase snapshot listeners.
  if (brewListener) brewListener()
  if (orderListener) orderListener()
  if (recipeListener) recipeListener()
  if (ingredientListener) ingredientListener()
  if (containerListener) containerListener()
  if (utilityListener) utilityListener()
}

export { subscribeSnapshot, unsubscribeSnapshot }

////////////////////////////////
// HELPER //////////////////////
////////////////////////////////

//convert firebase date to js date. This should be rewritten.
// const objToDate = function(obj: object) {
//   const res = {}
//   function recurse(obj) {
//     for (const key in obj) {
//       if (obj[key] instanceof firebase.firestore.Timestamp) {
//         res[key] = obj[key].toDate()
//       } else {
//         res[key] = obj[key]
//       }
//     }
//   }
//   recurse(obj)
//   return res
// }

////////////////////////////////
// API COLLECTIONS /////////////
////////////////////////////////
type LoadActions =
  | ActionTypes.LoadBrews
  | ActionTypes.LoadContainers
  | ActionTypes.LoadIngredients
  | ActionTypes.LoadOrders
  | ActionTypes.LoadRecipes

type DBCollections = "brews" | "containers" | "ingredients" | "orders" | "recipes" | "utility"
//GET
const apiGet = function(collection: DBCollections, dispatch: LoadActions) {
  return firebase
    .firestore()
    .collection(collection)
    .onSnapshot(
      snapshot => {
        //set firebase date to js date. This should be rewritten!
        // const data = snapshot.docs.reduce(
        //   (acc, doc) => ({ ...acc, [doc.id]: objToDate(doc.data()) }),
        //   {}
        // )
        const data = snapshot.docs.reduce((acc, doc) => ({ ...acc, [doc.id]: doc.data() }), {})

        //to set the use who updated the latest item and the item id
        snapshot.docChanges().forEach(change => {
          if (change.type === "modified") {
            const doc = change.doc.data()
            store.dispatch(ActionTypes.SetUpdateTrackerUid, doc.userUpdated)
            store.dispatch(ActionTypes.SetUpdateTrackerItemId, doc.id)
          }
        })

        // set the date in the store
        store.dispatch(dispatch, data)

        // set the loaded message
        store.dispatch(ActionTypes.MessageGetSuccess, collection + " updated")
      },
      error => {
        store.dispatch(ActionTypes.MessageGetError, error.message)
      }
    )
}

//SET
export const apiSet = function(item: any, collection: string) {
  const db = firebase.firestore()
  if (!item.id) {
    item.id = uuid()
    item.created = new Date()
    item.userCreated = getUserUid()
  } else {
    item.updated = new Date()
    item.userUpdated = getUserUid()
  }

  db.collection(collection)
    .doc(item.id)
    .set({ ...item })
    .then(() => store.dispatch(ActionTypes.MessageSetSuccess, collection + " saved"))
    .catch(error => {
      store.dispatch(ActionTypes.MessageSetError, error.message)
    })
  return item
}

//DELETE
export const apiDel = function(collection: string, id: string) {
  const db = firebase.firestore()
  db.collection(collection)
    .doc(id)
    .delete()
    .then(() => store.dispatch(ActionTypes.MessageSetSuccess, collection + " deleted"))
    .catch(error => store.dispatch(ActionTypes.MessageSetError, error.message))
}

////////////////////////////////
// COLLECTIONS CALL ////////////
////////////////////////////////

//BREW
const apiGetBrews = () => {
  return apiGet("brews", ActionTypes.LoadBrews)
}
// if (_increment) item.count = increment(_increment, typeId)

export const apiSetBrew = function(item: any) {
  const obj = brewBeforeSave(item)
  apiSet(obj.item, "brews")
  afterBrewSave(obj)
}

export const apiDelBrew = function(id: string) {
  const brew = store.state.collections.brews[id]

  //we need to remove container reference before delete
  const containerIds = brew.containers.map(item => item.id)
  removeRef("containers", containerIds, id, "brewIds")

  //we need to remove ingredients reference before delete
  if (brew.recipeCopy) {
    const ingredientsIds = [
      ...brew.recipeCopy.mashing.items,
      ...brew.recipeCopy.kettle.items,
      ...brew.recipeCopy.fermentation.items,
    ].map(item => item.id)
    removeRef("ingredients", ingredientsIds, id, "brewIds")
  }

  //delete item
  apiDel("brews", id)
}

//INGREDIENT
const apiGetIngredients = function() {
  return apiGet("ingredients", ActionTypes.LoadIngredients)
}
export const apiSetIngredient = function(item: Ingredient) {
  item = ingredientBeforeSave(item)
  apiSet(item, "ingredients")
}
export const apiDelIngredient = function(id: string) {
  apiDel("ingredients", id)
}

//CONTAINERS
const apiGetContainers = () => {
  return apiGet("containers", ActionTypes.LoadContainers)
}
export const apiSetContainer = function(item: Container) {
  item = containerBeforeSave(item)
  apiSet(item, "containers")
}
export const apiDelContainer = function(id: string) {
  apiDel("containers", id)
}

//RECIPE
const apiGetRecipes = () => {
  return apiGet("recipes", ActionTypes.LoadRecipes)
}
export const apiSetRecipe = function(item: Recipe) {
  item = recipeBeforeSave(item)
  return apiSet(item, "recipes")
}
export const apiDelRecipe = function(id: string) {
  apiDel("recipes", id)
}

//ORDER
const apiGetOrders = () => {
  return apiGet("orders", ActionTypes.LoadOrders)
}
export const apiSetOrder = function(item: Order) {
  item = orderBeforeSave(item)
  apiSet(item, "orders")
}
export const apiDelOrder = function(id: string) {
  const order = store.state.collections.orders[id]
  const brewIds = order.inventory.map(item => item.brewId)
  removeRef("brews", brewIds, id, "orderIds")
  apiDel("orders", id)
}

////////////////////////////////
// UTILITY API /////////////////
////////////////////////////////

const apiGetUtility = function() {
  const db = firebase.firestore()
  return db.collection("utility").onSnapshot(
    snapshot => {
      //convert all firebase dates to js dates. This should be change.
      // const data: any = snapshot.docs.reduce(
      //   (acc, doc) => ({ ...acc, [doc.id]: objToDate(doc.data()) }),
      //   {}
      // )
      const data = snapshot.docs.reduce((acc, doc) => ({ ...acc, [doc.id]: doc.data() }), {}) as any

      // set all the utils in the store
      store.dispatch(ActionTypes.LoadTanks, data.tanks)
      store.dispatch(ActionTypes.LoadSuppliers, data.suppliers)
      store.dispatch(ActionTypes.LoadStyles, data.styles)
      store.dispatch(ActionTypes.LoadBuyers, data.buyers)
      store.dispatch(ActionTypes.LoadProfiles, data.profiles)
      store.dispatch(ActionTypes.LoadBreweries, data.breweries)
      store.dispatch(ActionTypes.LoadUsers, data.users)

      //to set the use who updated the latest item and the item id
      snapshot.docChanges().forEach(change => {
        if (change.type === "modified") {
          let doc = Object.values(change.doc.data())
          doc = doc.sort((a, b) => Number(b.updated) - Number(a.updated))
          store.dispatch(ActionTypes.SetUpdateTrackerUid, doc[0].userUpdated)
          store.dispatch(ActionTypes.SetUpdateTrackerItemId, doc[0].id)
        }
      })

      //set the messaged loaded
      store.dispatch(ActionTypes.MessageGetSuccess, "Items loaded" + " Saved")
    },
    error => {
      store.dispatch(ActionTypes.MessageGetError, error.message)
    }
  )
}

export const apiSetUtility = function(item: any, doc: string) {
  const db = firebase.firestore()
  if (!item.id) {
    item.id = uuid()
    item.created = new Date()
    item.userCreated = getUserUid()
  } else {
    item.updated = new Date()
    item.userUpdated = getUserUid()
  }
  db.collection("utility")
    .doc(doc)
    .set({ [item.id]: { ...item } }, { merge: true })
    .then(() => store.dispatch(ActionTypes.MessageSetSuccess, doc + " Saved"))
    .catch(error => {
      store.dispatch(ActionTypes.MessageSetError, error.message)
    })
}

export const apiDelUtility = function(doc: string, id: string) {
  const db = firebase.firestore()
  db.collection("utility")
    .doc(doc)
    .update({ [id]: firebase.firestore.FieldValue.delete() })
    .then(() => store.dispatch(ActionTypes.MessageSetSuccess, doc + " Deleted"))
    .catch(error => {
      store.dispatch(ActionTypes.MessageSetError, error.message)
    })
}

////////////////////////////////
// UTIL CALL////////////////////
////////////////////////////////

//SUPPLIER
export const apiSetSupplier = function(item: Supplier) {
  apiSetUtility(item, "suppliers")
}
export const apiDelSupplier = function(id: Supplier["id"]) {
  apiDelUtility("suppliers", id)
}

//STYLE
export const apiSetStyle = function(item: Style) {
  apiSetUtility(item, "styles")
}
export const apiDelStyle = function(id: Style["id"]) {
  apiDelUtility("styles", id)
}

//TANK
export const apiSetTank = function(item: Tank) {
  apiSetUtility(item, "tanks")
}
export const apiDelTank = function(id: Tank["id"]) {
  apiDelUtility("tanks", id)
}

//BUYER
export const apiSetBuyer = function(item: Buyer) {
  apiSetUtility(item, "buyers")
}
export const apiDelBuyer = function(id: Buyer["id"]) {
  apiDelUtility("buyers", id)
}

//PROFILE
export const apiSetProfile = function(item: Profile) {
  apiSetUtility(item, "profiles")
}
export const apiDelProfile = function(id: Profile["id"]) {
  apiDelUtility("profiles", id)
}

//BREWERY
export const apiSetBrewery = function(item: Brewery) {
  apiSetUtility(item, "breweries")
}
export const apiDelBrewery = function(id: Brewery["id"]) {
  apiDelUtility("breweries", id)
}

//BREWERY
export const apiSetUser = function(item: User) {
  apiSetUtility(item, "users")
}
export const apiDelUser = function(id: User["id"]) {
  apiDelUtility("users", id)
}
