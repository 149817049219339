<template>
  <div class="b-modal" :style="{ zIndex: getZIndex() }">
    <slot />
  </div>
</template>

<script>
// import { onUnmounted } from "vue";
import { startOfWeek } from "date-fns"
import { mapState } from "vuex"
// import cloneDeep from "clone-deep";

export default {
  name: "ModelContainer",
  created() {
    document.documentElement.style.overflow = "hidden"
  },
  unmounted() {
    document.documentElement.style.overflow = "auto"
  },
  computed: {
    ...mapState(["modals"]),
  },
  methods: {
    getZIndex() {
      let notTooLongAgo = startOfWeek(new Date()).valueOf()
      return new Date().valueOf() - notTooLongAgo
    },
  },
}
</script>

<style scoped>
.b-modal {
  /* display: block; */
  position: fixed;
  /* z-index: 100; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  overflow: auto;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  /* filter: blur(10px); */
}
</style>
