import { ActionTree, GetterTree, MutationTree } from "vuex"
import { ActionTypes } from "../actionTypesEnums"
import { MutationType } from "../mutationTypesEnums"
import { ActionAugments, RootState } from "../store"

export interface UserState {
  uid: string
}

const state: UserState = {
  uid: "",
}

export type UserMutations = {
  [MutationType.SetUid](state: UserState, payload: string): void
}

const mutations: MutationTree<UserState> & UserMutations = {
  [MutationType.SetUid](state, payload) {
    state.uid = payload
  },
}

export type UserActions = {
  [ActionTypes.SetUid](context: ActionAugments, payload: string): void
}
const actions: ActionTree<RootState, RootState> & UserActions = {
  [ActionTypes.SetUid]({ commit }, payload) {
    commit(MutationType.SetUid, payload)
  },
}

export type UserGetters = {
  uid(state: UserState): string
}
const getters: GetterTree<UserState, RootState> & UserGetters = {
  uid: state => {
    return state.uid
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
