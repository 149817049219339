import { TIME_FORMAT } from "@/services/enums"
import { Base, Timestamp } from "@/typings/types"
import { format } from "date-fns"
import { cloneDeep } from "lodash"
import uniqolor from "uniqolor"

export const formatCount = (counter: number) => {
  if (counter) {
    return "#" + String(counter).padStart(3, "0")
  }
  return ""
}

export const generateStyleColor = () => {
  return uniqolor.random({
    saturation: 40,
    lightness: [85, 90],
  }).color
}

export const handleDate = (date: Date | Timestamp) => {
  return date instanceof Date ? date : date.toDate()
}

export const base = <T extends Base>(object: T) => {
  const copy = cloneDeep(object)
  copy.createdDate = copy.created?.toDate()
  copy.createdText = copy.createdDate ? format(copy.createdDate, TIME_FORMAT.TIME) : ""
  copy.updatedDate = copy.updated?.toDate()
  copy.updatedText = copy.updatedDate ? format(copy.updatedDate, TIME_FORMAT.TIME) : ""
  return copy
}
