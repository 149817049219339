const companies = {
  show: {
    name: "Show",
    config: {
      apiKey: "AIzaSyDfH4yQ0BOiQXVhWXh83nRexgOMc3gojTU",
      authDomain: "brew-be7db.firebaseapp.com",
      projectId: "brew-be7db",
      storageBucket: "brew-be7db.appspot.com",
      messagingSenderId: "115468801645",
      appId: "1:115468801645:web:dd8a74dd61cc797e50d164",
    },
  },
  flying: {
    name: "Flying Couch",
    config: {
      apiKey: "AIzaSyDz4B6Jn-FsYYeM27ZvKX0hYrbc4ccY4dc",
      authDomain: "brew-company-01.firebaseapp.com",
      projectId: "brew-company-01",
      storageBucket: "brew-company-01.appspot.com",
      messagingSenderId: "79007327555",
      appId: "1:79007327555:web:15c9ef5119e184c685fbf6",
    },
  },
}

export { companies }
