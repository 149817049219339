import App from "./App.vue"
import { store } from "./store/store"
import { createApp } from "vue"
import router from "./router/index"

import "./main.css"

createApp(App)
  .use(router)
  .use(store)
  .mount("#app")
