<template>
  <div class="mt-4 mb-4">
    <div class="d-flex justify-content-between align-items-end">
      <span class="large-text">
        <slot />
      </span>
      <span class="h5">
        <slot name="right" />
      </span>
    </div>
    <hr class="line-black mb-2" />
  </div>
</template>

<script>
export default {
  name: "Header",
}
</script>
