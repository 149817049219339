<template>
  <div
    class="
      row row-cols-1
      row-cols-sm-1
      row-cols-md-2
      row-cols-lg-3
      row-cols-xl-4
      row-cols-xxl-5
      g-4"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ColumnCardGrid",
}
</script>
